import { gql } from "@apollo/client";

export interface IGetUserVacationInput {
  userRequesterId?: string;
  overrideDate?: Date;
}

const GET_USER_VACATIONS = gql`
  query GetUserVacation($getUserVacationInput: GetUserVacationInput!) {
    getUserVacation(getUserVacationInput: $getUserVacationInput) {
      id
      user
      legalDays
      totalDaysWithBenefit
      totalDaysUsed
      totalDaysLeft
    }
  }
`;

export interface IGetVacationRequestInput {
  startDate?: Date;
  endDate?: Date;
  userRequesterId?: string;
  limit?: number;
  offset?: number;
}

const GET_VACATION_REQUESTS = gql`
  query userVacationRequests(
    $getUserVacationRequestInput: GetUserVacationRequestInput!
  ) {
    getAllUserVacationRequest(
      getUserVacationRequestInput: $getUserVacationRequestInput
    ) {
      requests {
        id
        user {
          id
          name
          lastname
          profileImage
          email
          adExternalId
          adManager {
            id
            name
            lastname
            email
            adExternalId
          }
        }
        totalDays
        status
        startDate
        endDate
        comment
        statusComment
        leaderApprovalCheck
        leaderApprover
        leaderApprovalDate
        hrApprovalCheck
        hrApprover
        hrApprovalDate
        assignedReplacement
        assignedReplacementId {
          id
          name
          lastname
          email
          adExternalId
        }
        remindedAt
        receiptUrl
      }
      total
    }
  }
`;

export interface IGetSingleVacationRequestInput {
  id: string;
}

const GET_SINGLE_VACATION_REQUEST = gql`
  query GetSingleUserVacationRequest(
    $getSingleUserVacationRequestInput: GetSingleUserVacationRequestInput!
  ) {
    getSingleUserVacationRequest(
      getSingleUserVacationRequestInput: $getSingleUserVacationRequestInput
    ) {
      id
      user {
        id
        name
        lastname
        email
        profileImage
        adExternalId
        adManager {
          id
          name
          lastname
          email
          adExternalId
        }
      }
      status
      startDate
      endDate
      comment
      statusComment
      leaderApprovalCheck
      leaderApprover
      leaderApprovalDate
      hrApprovalCheck
      hrApprover
      hrApprovalDate
      assignedReplacement
      assignedReplacementId {
        id
        name
        lastname
        email
        adExternalId
      }
      remindedAt
    }
  }
`;

export interface IGetBusinessDaysInput {
  startDate: Date;
  endDate: Date;
}

const GET_BUSINESS_DAYS = gql`
  query GetTotalDays($getUserVacationRequestInput: GetBusinesssDaysInput!) {
    getTotalDays(getUserVacationRequestInput: $getUserVacationRequestInput) {
      totalDays
    }
  }
`;

const GET_VACATION_CONFIGS = gql`
  query GetAllVacationConfig {
    getAllVacationConfig {
      id
      country
      benefitPercentage
      legalDays
      overrideDays
      createdAt
      updatedAt
      contractType
    }
  }
`;

const CHECK_CAN_APPROVE = gql`
  query Query($userRequestId: String!) {
    checkCanApproveRequest(userRequestId: $userRequestId)
  }
`;

export default {
  GET_USER_VACATIONS,
  GET_VACATION_REQUESTS,
  GET_SINGLE_VACATION_REQUEST,
  GET_BUSINESS_DAYS,
  GET_VACATION_CONFIGS,
  CHECK_CAN_APPROVE,
};
