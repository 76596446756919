import { gql } from "@apollo/client";
import { IUser } from "types/User.types";

export interface IPaginationInput {
  limit?: number;
  offset: number;
}

export interface IUsersRequiredForProjectInput {
  skillsRequired: string[];
  skillsOptional: string[];
}

export interface IGetUsersRequiredForProjectResponse {
  users: IUser[];
  total: number;
}

export const USERS_REQUIRED_FOR_PROJECT_PAGINATED = gql`
  query SearchUsersRequiredForProject(
    $searchUsersRequiredForProjectInput: SearchUsersRequiredForProjectInput!
    $paginateInput: PaginateInput
  ) {
    searchUsersRequiredForProject(
      searchUsersRequiredForProjectInput: $searchUsersRequiredForProjectInput
      paginateInput: $paginateInput
    ) {
      users {
        # TODO: check what is happening with this info
        # assignments {
        #   externalProjectId
        #   asignationPercentage
        #   endDate
        #   startDate
        # }
        id
        createdAt
        name
        country
        lastname
        profileImage
        email
        dailyQuestionTasks
        englishLevel
        seniority
        aiCVInfo {
          ofuscatedName
          education {
            dateEnd
            dateStart
            description
            title
          }
          languages {
            level
            name
          }
          tagLine
          techSkills
          workExperience {
            date
            description
            title
            company
          }
        }
        activeCareer {
          id
          name
        }
        role {
          id
          name
        }
        area {
          id
          name
          avatar
        }
        userFeedbacks
        systemRole
        active
        assigned
        assignable
        skills {
          id
          name
        }
      }
      total
    }
  }
`;

export default {
  USERS_REQUIRED_FOR_PROJECT_PAGINATED,
};
