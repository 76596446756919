import React, { FC, useContext, useMemo, useState } from "react";
import Quickie from "./Quickie";
import { IQuickieList, QuickieContext } from "./quickie.context";
import { TOnSubmitQuickie } from "./quickie.types";

export interface ISelectedQuickieItems {
  [item: string]: string;
}

interface IQuickieProvider extends React.PropsWithChildren<{}> {
  // Define any other props specific to QuickieProvider if needed
}

const QuickieProvider: FC<IQuickieProvider> = ({ children }) => {
  const [isBubbleVisible, setIsBubbleVisible] = useState<boolean>(false);
  const [onSubmit, setOnSubmitFunc] = useState<TOnSubmitQuickie>();
  const [list, setList] = useState<IQuickieList>();
  const [openQuickie, setOpenQuickie] = useState<() => void>();
  const [closeQuickie, setCloseQuickie] = useState<() => void>();

  const showBubble = () => {
    setIsBubbleVisible(true);
  };

  const hideBubble = () => {
    setIsBubbleVisible(false);
  };

  const createSelected = () => {};

  const value = useMemo(
    () => ({
      isBubbleVisible,
      showBubble,
      createSelected,
      hideBubble,
      setList,
      setOnSubmit: (newOnSubmit: TOnSubmitQuickie) => {
        setOnSubmitFunc(() => newOnSubmit);
      },
      openQuickie,
      closeQuickie,
    }),
    [openQuickie, closeQuickie, isBubbleVisible]
  );

  return (
    <QuickieContext.Provider {...{ value }}>
      <Quickie
        {...{
          isBubbleVisible,
          setIsBubbleVisible,
          setOpenQuickie,
          createSelected,
          list,
          onSubmit,
          setCloseQuickie,
        }}
      />

      {children}
    </QuickieContext.Provider>
  );
};

export const useQuickie = () => useContext(QuickieContext);

export default QuickieProvider;
