import { gql } from "@apollo/client";
import {
  EComplianceTaskTypeEnum,
  ETaskSubType,
  ETaskType,
} from "types/Task.type";

export interface ICreateTaskInput {
  name: string;
  type: ETaskType;
  subType?: ETaskSubType;
  currentValue?: number;
  endValue?: number;
  value?: number;
  minValue?: number;
  refIds?: string[];
  goal: string;
  description?: string;
  currency?: string;
  links?: string[];
  complianceType?: EComplianceTaskTypeEnum;
}

const CREATE_TASK = gql`
  mutation createTask($createTaskInput: CreateTaskInput!) {
    createTask(createTaskInput: $createTaskInput) {
      id
    }
  }
`;

export interface IUpdateTaskInput {
  id: string;
  name?: string;
  value?: number;
  minValue?: number;
  description?: string;
  currency?: string;
  refIds?: string[];
  currentValue?: number;
  completed?: boolean;
  links?: string[];
  complianceType?: EComplianceTaskTypeEnum;
}

const UPDATE_TASK = gql`
  mutation updateTask($updateTaskInput: UpdateTaskInput!) {
    updateTask(updateTaskInput: $updateTaskInput) {
      id
    }
  }
`;

export interface IDeleteTaskInput {
  id: string;
}

const DELETE_TASK = gql`
  mutation deleteTask($deleteTaskInput: DeleteTaskInput!) {
    deleteTask(deleteTaskInput: $deleteTaskInput) {
      id
    }
  }
`;

export interface IUpdateTaskPriorityItemsInput {
  id: string;
  priority: number;
}

export interface IUpdateTaskPriorityInput {
  items: IUpdateTaskPriorityItemsInput[];
}

const UPDATE_TASKS_PRIORITY = gql`
  mutation updateTaskPriority(
    $updateTaskPriorityInput: UpdateTaskPriorityInput!
  ) {
    updateTaskPriority(updateTaskPriorityInput: $updateTaskPriorityInput)
  }
`;

export default { CREATE_TASK, UPDATE_TASKS_PRIORITY, UPDATE_TASK, DELETE_TASK };
