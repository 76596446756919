import { gql } from "@apollo/client";
import { EQuestionStatus } from "types/Question/enums/question-type.enum";
import IQuestion from "../../types/Question/IQuestion";

export interface ICreateQuestionInputOption {
  id?: string;
  name: string;
  isCorrect: boolean;
}

export interface ICreateQuestionInput {
  id: string;
  name: string;
  correctOptions: number;
  visibleOptions: number;
  questionOptions: ICreateQuestionInputOption[];
  dimension: string;
  maturityId: string;
  tagsNames: string[];
  copy?: boolean;
}

export interface IUpdateQuestionInputOption {
  id: string;
  name: string;
  isCorrect: boolean;
}
export interface IUpdateQuestionInput {
  id: string;
  name: string;
  correctOptions: number;
  visibleOptions: number;
  dimension: string;
  questionOptions: IUpdateQuestionInputOption[];
  tagsNames: string[];
  status?: EQuestionStatus;
}

const CREATE_QUESTION = gql`
  mutation CreateQuestion($createQuestionInput: CreateQuestionInput!) {
    createQuestion(createQuestionInput: $createQuestionInput) {
      id
    }
  }
`;

const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($updateQuestionInput: UpdateQuestionInput!) {
    updateQuestion(updateQuestionInput: $updateQuestionInput) {
      id
      name
    }
  }
`;

const DELETE_QUESTION = gql`
  mutation DeleteQuestion($questionId: String!) {
    deleteQuestion(questionId: $questionId) {
      id
    }
  }
`;

export interface IValidatedQuestion {
  question: string;
  correctOption: string[];
  isCorrect: boolean;
}
export interface IValidatePracticeQuestionsRes {
  validateDailyQuestions: IValidatedQuestion[];
}
export interface IValidatePracticeQuestionsInput {
  validateDailyQuestionsInput: {
    questions: {
      question: string;
      options: string[];
    }[];
  };
}
const VALIDATE_PRACTICE_QUESTIONS = gql`
  mutation ValidateDailyQuestions(
    $validateDailyQuestionsInput: ValidateDailyQuestionsInput!
  ) {
    validateDailyQuestions(
      validateDailyQuestionsInput: $validateDailyQuestionsInput
    ) {
      question
      correctOption
      isCorrect
    }
  }
`;

export interface IValidateChallengeQuestionsInput {
  validateChallengeQuestionsInput: {
    startedChallenge: string;
    questions: {
      question: string;
      options: string[];
    }[];
  };
}
const VALIDATE_CHALLENGE_QUESTIONS = gql`
  mutation validateChallengeQuestions(
    $validateChallengeQuestionsInput: ValidateChallengeQuestionsInput!
  ) {
    validateChallengeQuestions(
      validateChallengeQuestionsInput: $validateChallengeQuestionsInput
    ) {
      question
      correctOption
      isCorrect
    }
  }
`;

export interface IRateQuestionRes {
  rateQuestion: IQuestion;
}
export interface IRateQuestionInput {
  rateQuestionInput: {
    id: string;
    difficultyRating: number;
  };
}
const RATE_QUESTION = gql`
  mutation rateQuestion($rateQuestionInput: RateQuestionInput!) {
    rateQuestion(rateQuestionInput: $rateQuestionInput) {
      id
    }
  }
`;

export interface IStartUserDailyQuestionInput {
  user: string;
}

const START_DAILY_QUESTION_PRACTICE = gql`
  mutation StartUserDailyQuestion(
    $startUserDailyQuestionInput: StartUserDailyQuestionInput!
  ) {
    startUserDailyQuestion(
      startUserDailyQuestionInput: $startUserDailyQuestionInput
    )
  }
`;

export interface IFeedbackQuestionInput {
  feedbackQuestionInput: {
    id: string;
    reason: string;
  };
}

export interface IFeedbackRes {
  feedbackQuestion: IQuestion;
}

const FEEDBACK_QUESTION = gql`
  mutation FeedbackQuestion($feedbackQuestionInput: FeedbackQuestionInput!) {
    feedbackQuestion(feedbackQuestionInput: $feedbackQuestionInput) {
      id
      name
      feedback {
        reason
        user {
          id
          name
        }
      }
    }
  }
`;

const CREATE_QUESTION_WITH_AI = gql`
  mutation CreateQuestionsWithAI(
    $createQuestionWithAiInput: CreateQuestionWithAIInput!
  ) {
    createQuestionsWithAI(
      createQuestionWithAIInput: $createQuestionWithAiInput
    ) {
      id
      name
      options {
        id
        updatedAt
        createdAt
        deletedAt
        question
        name
        isCorrect
        createdBy
      }
    }
  }
`;

const ADD_AI_QUESTION_TO_APPRECIATION = gql`
  mutation AddAIQuestionToAppreciation(
    $aiQuestionId: String!
    $appreciationId: String!
  ) {
    addAIQuestionToAppreciation(
      aiQuestionId: $aiQuestionId
      appreciationId: $appreciationId
    ) {
      id
    }
  }
`;

const CANCEL_DAILY_QUESTION_PRACTICE = gql`
  mutation CancelUserDailyQuestion(
    $startUserDailyQuestionInput: StartUserDailyQuestionInput!
  ) {
    cancelUserDailyQuestion(
      startUserDailyQuestionInput: $startUserDailyQuestionInput
    )
  }
`;

export default {
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  VALIDATE_PRACTICE_QUESTIONS,
  RATE_QUESTION,
  START_DAILY_QUESTION_PRACTICE,
  FEEDBACK_QUESTION,
  VALIDATE_CHALLENGE_QUESTIONS,
  CREATE_QUESTION_WITH_AI,
  ADD_AI_QUESTION_TO_APPRECIATION,
  CANCEL_DAILY_QUESTION_PRACTICE,
};
