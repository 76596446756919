import { FC, ReactNode } from "react";

interface CleanLayoutProps {
  children?: ReactNode; // ReactNode permite cualquier cosa que se pueda renderizar en React
}

const CleanLayout: FC<CleanLayoutProps> = ({ children }) => (
  <div className="h-screen w-full p-6 dark:bg-black md:p-3">
    <div className="animate__animated h-screen flex-1" id="layoutMainContent">
      <div className="container mx-auto h-full min-h-full">{children}</div>
    </div>
  </div>
);

export default CleanLayout;
