import { gql } from "@apollo/client";
import {
  ICareer,
  IHierarchy,
  IItemPath,
  IItemPathMaturityForm,
  IUserItemPathQuestion,
} from "types/Career.type";

export interface ICreateCareerInput {
  name: string;
  description?: string;
  roles?: string[];
  items?: IHierarchy[];
}

const CREATE_CAREER = gql`
  mutation createCareer($createCareerInput: CreateCareerInput!) {
    createCareer(createCareerInput: $createCareerInput) {
      id
      name
      description
    }
  }
`;

export interface ICreateItemPathInput {
  name: string;
  maturity: IItemPathMaturityForm[];
  description?: string;
  color?: string;
  subItems?: string[];
  multimedias: string[];
  dimensions?: string[];
  shortDescription?: string;
  roles?: string[];
}

export interface ICreateItemPathResponse {
  createItemPath: IItemPath;
}

const CREATE_ITEM_PATH = gql`
  mutation createItemPath($createItemPathInput: CreateItemPathInput!) {
    createItemPath(createItemPathInput: $createItemPathInput) {
      id
    }
  }
`;

export interface IUpdateCareerInput {
  id: string;
  name?: string;
  description?: string;
  roles?: string[];
  items?: IHierarchy[];
}

const UPDATE_CAREER = gql`
  mutation updateCareer($updateCareerInput: UpdateCareerInput!) {
    updateCareer(updateCareerInput: $updateCareerInput) {
      id
      name
      description
    }
  }
`;
export interface IUpdateItemPathInput {
  id: string;
  name?: string;
  maturity?: IItemPathMaturityForm[];
  color?: string;
  description?: string;
  subItems?: string[];
  multimedias: string[];
  dimensions?: string[];
  compliancePercent?: number;
  shortDescription?: string;
  appreciation?: string;
}

export interface IUpdateItemPathResponse {
  updateItemPath: IItemPath;
}

const UPDATE_ITEM_PATH = gql`
  mutation updateItemPath($updateItemPathInput: UpdateItemPathInput!) {
    updateItemPath(updateItemPathInput: $updateItemPathInput) {
      id
      name
      description
      shortDescription
      createdBy {
        id
      }
      vendorId
      deleted
      createdAt
      updatedAt
      itemPathQuestions
      associatedCareers
      compliancePercent
      color
      dimensions {
        id
        name
      }
      multimedias {
        id
        fileUrl
        thumbnailUrl
        name
      }
      maturity {
        role {
          id
          name
        }
        maturity {
          id
          name
        }
      }
    }
  }
`;

export interface ICreateItemPathQuestionInputOption {
  id?: string;
  name: string;
  isCorrect: boolean;
}
export interface ICreateItemPathQuestionInput {
  itemPath: string;
  name: string;
  correctOptions: number;
  visibleOptions: number;
  itemPathQuestionOptions: ICreateItemPathQuestionInputOption[];
  dimension: string;
  maturityId: string;
  tagsNames: string[];
}

const CREATE_ITEM_PATH_QUESTION = gql`
  mutation createItemPathQuestion(
    $createItemPathQuestionInput: CreateItemPathQuestionInput!
  ) {
    createItemPathQuestion(
      createItemPathQuestionInput: $createItemPathQuestionInput
    ) {
      id
    }
  }
`;

export interface IUpdateItemPathQuestionInputOption {
  id: string;
  name: string;
  isCorrect: boolean;
}
export interface IUpdateItemPathQuestionInput {
  itemPath: string;
  name: string;
  correctOptions: number;
  visibleOptions: number;
  dimension: string;
  itemPathQuestionOptions: IUpdateItemPathQuestionInputOption[];
  tagsNames: string[];
}

const UPDATE_ITEM_PATH_QUESTION = gql`
  mutation updateItemPathQuestion(
    $updateItemPathQuestionInput: UpdateItemPathQuestionInput!
  ) {
    updateItemPathQuestion(
      updateItemPathQuestionInput: $updateItemPathQuestionInput
    ) {
      id
    }
  }
`;

export interface IDeleteItemPathQuestionInput {
  id: string;
}

const DELETE_ITEM_PATH_QUESTION = gql`
  mutation deleteItemPathQuestion(
    $deleteItemPathQuestionInput: DeleteItemPathQuestionInput!
  ) {
    deleteItemPathQuestion(
      deleteItemPathQuestionInput: $deleteItemPathQuestionInput
    ) {
      id
      name
    }
  }
`;

export interface IDeleteCareerResponse {
  deleteCareer: ICareer;
}

export interface IDeleteCareerInput {
  id: string;
}

const DELETE_CAREER = gql`
  mutation deleteCareer($deleteCareerInput: DeleteCareerInput!) {
    deleteCareer(deleteCareerInput: $deleteCareerInput) {
      id
      name
      deleted
    }
  }
`;

export interface IAssignCareerToUserInput {
  userId: string;
  careerId: string;
}

const ASIGN_CAREER = gql`
  mutation assignCareer($assignCareerToUserInput: AssignCareerToUserInput!) {
    assignCareer(assignCareerToUserInput: $assignCareerToUserInput) {
      id
      createdAt
    }
  }
`;

export interface IDeleteItemPathInput {
  id: string;
}

export interface IDeleteItemPathResponse {
  deleteItemPath: IItemPath;
}

const DELETE_ITEM_PATH = gql`
  mutation deleteItemPath($deleteItemPathInput: DeleteItemPathInput!) {
    deleteItemPath(deleteItemPathInput: $deleteItemPathInput) {
      id
      name
      deleted
    }
  }
`;

export interface IValidateUserItemPathTestInput {
  questions: IUserItemPathQuestion[];
  userItemPathId: string;
}

export interface IValidateUserItemPath {
  percent: number;
  approved: boolean;
}

const VALIDATE_USER_ITEM_PATH_TEST = gql`
  mutation validateUserItemPathTest(
    $validateUserItemPathTestInput: ValidateUserItemPathTestInput!
  ) {
    validateUserItemPathTest(
      validateUserItemPathTestInput: $validateUserItemPathTestInput
    ) {
      percent
      approved
    }
  }
`;

export interface IDeleteUserCareerInput {
  userId: string;
  userCareerId: string;
}

export interface IDeleteUserCareerResponse {
  deleteUserCareer: ICareer;
}

const DELETE_USER_CAREER = gql`
  mutation deleteUserCareer($deleteUserCarrerInput: DeleteUserCareerInput!) {
    deleteUserCareer(deleteUserCarrerInput: $deleteUserCarrerInput) {
      id
      name
    }
  }
`;

export default {
  CREATE_CAREER,
  UPDATE_CAREER,
  CREATE_ITEM_PATH,
  UPDATE_ITEM_PATH,
  DELETE_ITEM_PATH_QUESTION,
  UPDATE_ITEM_PATH_QUESTION,
  CREATE_ITEM_PATH_QUESTION,
  DELETE_CAREER,
  ASIGN_CAREER,
  DELETE_USER_CAREER,
  DELETE_ITEM_PATH,
  VALIDATE_USER_ITEM_PATH_TEST,
};
