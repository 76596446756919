import { gql } from "@apollo/client";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { IAppreciation } from "types/Appreciation/Appreciation.types";
import { IAppreciationDistributeQuestionOption } from "types/Appreciation/AppreciationDistributeQuestionOption.type";
import { EAppreciationType } from "types/Appreciation/enums/appreciation-type.enum";

export interface ICreateAppreciationInput {
  name: string;
  description: string;
  level: string;
  dimensions: string[];
  vendor: string;
  type: EAppreciationType;
  availability: DateRange;
  periodicity: number;
  tagsNames: string[];
  team?: string;
  enableForPreselection?: boolean;
  timeToAnswer?: number;
  hidden?: boolean;
  thumbnail?: string;
}

const CREATE_APPRECIATION = gql`
  mutation CreateAppreciation(
    $createAppreciationInput: CreateAppreciationInput!
  ) {
    createAppreciation(createAppreciationInput: $createAppreciationInput) {
      id
    }
  }
`;

const DELETE_APPRECIATION = gql`
  mutation deleteAppreciation($id: String!) {
    deleteAppreciation(id: $id) {
      id
      name
    }
  }
`;

export interface ICreateAppreciationQuestionInputOption {
  id?: string;
  name: string;
  isCorrect: boolean;
}

export interface ICreateAppreciationQuestionInput {
  appreciationId: string;
  name: string;
  correctOptions: number;
  visibleOptions: number;
  appreciationQuestionOptions: ICreateAppreciationQuestionInputOption[];
  dimension: string;
  maturityId: string;
  tagsNames: string[];
  copy?: boolean;
}

const CREATE_APPRECIATION_QUESTION = gql`
  mutation createAppreciationQuestion(
    $createAppreciationQuestionInput: CreateAppreciationQuestionInput!
  ) {
    createAppreciationQuestion(
      createAppreciationQuestionInput: $createAppreciationQuestionInput
    ) {
      id
    }
  }
`;

export interface IUpdateAppreciationQuestionInputOption {
  id: string;
  name: string;
  isCorrect: boolean;
}
export interface IUpdateAppreciationQuestionInput {
  id: string;
  name: string;
  correctOptions: number;
  visibleOptions: number;
  dimension: string;
  AppreciationQuestionOptions: IUpdateAppreciationQuestionInputOption[];
  tagsNames: string[];
}

const UPDATE_APPRECIATION_QUESTION = gql`
  mutation updateAppreciationQuestion(
    $updateAppreciationQuestionInput: UpdateAppreciationQuestionInput!
  ) {
    updateAppreciationQuestion(
      updateAppreciationQuestionInput: $updateAppreciationQuestionInput
    ) {
      id
    }
  }
`;

export interface IDeleteAppreciationQuestionInput {
  id: string;
}

const DELETE_APPRECIATION_QUESTION = gql`
  mutation deleteAppreciationQuestion(
    $deleteAppreciationQuestionInput: DeleteAppreciationQuestionInput!
  ) {
    deleteAppreciationQuestion(
      deleteAppreciationQuestionInput: $deleteAppreciationQuestionInput
    ) {
      id
      name
    }
  }
`;

export interface IUpdateAppreciationInput {
  id: string;
  name: string;
  description: string;
  level: string;
  dimensions: string[];
  vendor: string;
  type: EAppreciationType;
  availability: DateRange;
  periodicity: number;
  tagsNames: string[];
  team?: string;
  timeToAnswer?: number;
  enableForPreselection?: boolean;
  hidden?: boolean;
  thumbnail?: string;
}

const UPDATE_APPRECIATION = gql`
  mutation updateAppreciation(
    $updateAppreciationInput: UpdateAppreciationInput!
  ) {
    updateAppreciation(updateAppreciationInput: $updateAppreciationInput) {
      id
    }
  }
`;

export interface IDistributeAppreciationInput {
  id: string;
  affectedUsers: string[];
  startDate?: Date;
  endDate?: Date;
  periodicity?: number;
  team?: string;
  maxQuestions?: number;
  random?: boolean;
  timeToAnswer?: number;
  overrideDistributeId?: string;
}

const DISTRIBUTE_APPRECIATION = gql`
  mutation distributeAppreciation(
    $distributeAppreciationInput: DistributeAppreciationInput!
  ) {
    distributeAppreciation(
      distributeAppreciationInput: $distributeAppreciationInput
    )
  }
`;

const START_APPRECIATION_DISTRIBUTE = gql`
  mutation startAppreciationDistribute($id: String!) {
    startAppreciationDistribute(id: $id) {
      id
      started
    }
  }
`;

export interface ISaveAppreciationDistributeQuestionType {
  id: string;
  name: string;
  options: IAppreciationDistributeQuestionOption[];
}
export interface ISaveAppreciationDistributeInput {
  id: string;
  question: ISaveAppreciationDistributeQuestionType;
  activeQuestionIndex: number | null;
  userMarkedAsDone?: boolean;
  newQuestionMode?: boolean;
}

const SAVE_APPRECIATION_DISTRIBUTE = gql`
  mutation saveAppreciationDistribute(
    $saveAppreciationDistributeInput: SaveAppreciationDistributeInput!
  ) {
    saveAppreciationDistribute(
      saveAppreciationDistributeInput: $saveAppreciationDistributeInput
    ) {
      id
      completed
      questions {
        name
        id
        options {
          checked
        }
      }
    }
  }
`;

const SET_APPRECIATION_PERIODICITY_COACH_USER = gql`
  mutation setAppreciationPeriodicityCoachUser(
    $setAppreciationPeriodicityCoachUserInput: SetAppreciationPeriodicityCoachUserInput!
  ) {
    setAppreciationPeriodicityCoachUser(
      setAppreciationPeriodicityCoachUserInput: $setAppreciationPeriodicityCoachUserInput
    ) {
      id
    }
  }
`;

export interface ICloneAppreciationInput {
  appreciation: string;
  name: string;
}

export interface ICloneAppreciationRes {
  cloneAppreciation: IAppreciation;
}

const CLONE_APPRECIATION = gql`
  mutation cloneAppreciation($cloneAppreciationInput: CloneAppreciationInput!) {
    cloneAppreciation(cloneAppreciationInput: $cloneAppreciationInput) {
      id
    }
  }
`;

const FRIENDLY_REMINDER = gql`
  mutation FriendlyReminder($appreciationId: String!) {
    friendlyReminder(appreciationId: $appreciationId)
  }
`;

const CHANGE_APPRECIATION_DISTRIBUTE_ID = gql`
  mutation ChangeAppreciationDistributeId(
    $changeAppreciationDistributeInput: ChangeAppreciationDistributeInput!
  ) {
    changeAppreciationDistributeId(
      changeAppreciationDistributeInput: $changeAppreciationDistributeInput
    ) {
      id
    }
  }
`;

const CREATE_OR_UPDATE_APPRECIATION_DETAILS = gql`
  mutation CreateOrUpdateAppreciationDetails(
    $createOrUpdateAppreciationDetails: CreateOrUpdateAppreciationDetailsInput!
  ) {
    createOrUpdateAppreciationDetails(
      createOrUpdateAppreciationDetails: $createOrUpdateAppreciationDetails
    ) {
      id
    }
  }
`;

const DELETE_APPRECIATION_DISTRIBUTE = gql`
  mutation DeleteAppreciationDistribute(
    $deleteAppreciationDistributeId: String!
  ) {
    deleteAppreciationDistribute(id: $deleteAppreciationDistributeId)
  }
`;

const MASS_DELETE_APPRECIATION = gql`
  mutation RemoveAppreciationDistributeByDistributeId($distributeId: String!) {
    removeAppreciationDistributeByDistributeId(distributeId: $distributeId)
  }
`;

export default {
  CREATE_APPRECIATION,
  DELETE_APPRECIATION,
  CREATE_APPRECIATION_QUESTION,
  DELETE_APPRECIATION_QUESTION,
  UPDATE_APPRECIATION,
  UPDATE_APPRECIATION_QUESTION,
  DISTRIBUTE_APPRECIATION,
  START_APPRECIATION_DISTRIBUTE,
  SAVE_APPRECIATION_DISTRIBUTE,
  SET_APPRECIATION_PERIODICITY_COACH_USER,
  CLONE_APPRECIATION,
  FRIENDLY_REMINDER,
  CHANGE_APPRECIATION_DISTRIBUTE_ID,
  CREATE_OR_UPDATE_APPRECIATION_DETAILS,
  DELETE_APPRECIATION_DISTRIBUTE,
  MASS_DELETE_APPRECIATION,
};
