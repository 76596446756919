import { LOGO_DARK, LOGO_LIGHT } from "@constants/global";
import SVG from "components/SVG";
import { FC, ReactNode } from "react";

interface CleanLayoutProps {
  children?: ReactNode; // ReactNode permite cualquier cosa que se pueda renderizar en React
}

const CertificateLayout: FC<CleanLayoutProps> = ({ children }) => {
  return (
    <div className="h-full w-full dark:bg-black">
      <div className="min-h-20 w-full border-b dark:border-gray-800">
        <div className="flex items-center justify-center bg-white py-4 dark:bg-black">
          <SVG
            src={LOGO_LIGHT}
            alt="Groowcity"
            className="logo-light h-10 w-auto"
          />
          <SVG
            src={LOGO_DARK}
            alt="Groowcity"
            className="logo-dark h-10 w-auto"
          />
        </div>
      </div>
      <div
        className="animate__animated flex-1 p-6 md:p-3"
        id="layoutMainContent"
      >
        <div className="container mx-auto h-full">{children}</div>
      </div>
      <div className="min-h-4 w-full border-t dark:border-gray-800">
        <div className="flex items-center justify-center bg-white p-4 dark:bg-black">
          <span className="text-sm text-muted-foreground">
            Groowcity © {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CertificateLayout;
