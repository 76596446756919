import { DocumentNode } from "graphql";
import { createContext } from "react";
import { TOnSubmitQuickie, TQuickiesTypes } from "./quickie.types";

export interface IQuickieList {
  header: string;
  description: string;
  type: TQuickiesTypes;
  refetchQueries?: DocumentNode[];
  isSingleChoice?: boolean;
}

interface IQuickieMethods {
  isBubbleVisible: boolean;
  showBubble: () => void;
  hideBubble: () => void;
  createSelected: (refetchQueries: string[]) => void;
  setList: (params: IQuickieList) => void;
  setOnSubmit: (onSubmit: TOnSubmitQuickie) => void;
  openQuickie: () => void;
  closeQuickie: () => void;
}
const quickieMethods = {
  isBubbleVisible: false,
  showBubble: () => null,
  hideBubble: () => null,
  createSelected: () => null,
  setList: () => null,
  setOnSubmit: () => null,
  openQuickie: () => null,
  closeQuickie: () => null,
};

export const QuickieContext = createContext<IQuickieMethods>(quickieMethods);
