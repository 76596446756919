import { useIsScreenMd } from "hooks/useIsScreenMd";
import { useEffect, useState } from "react";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import { useMobileSidebarStore } from "stores/sidebar/useMobileSidebarStore";
import SidebarBody from "./components/body/SidebarBody";
import SidebarFooter from "./components/footer/SidebarFooter";
import SidebarHeader from "./components/header/SidebarHeader";

interface SidebarProps {
  readonly render?: boolean;
}

export default function Sidebar(props: SidebarProps) {
  const { isExpanded } = useDesktopSidebarStore();
  const { isOpenBurger } = useMobileSidebarStore();
  const isScreenMd = useIsScreenMd();
  const [showOverlay, setShowOverlay] = useState(false);
  const { render = true } = props;

  const isExpandedMobile = !isScreenMd && isOpenBurger;
  const isCollapsedMobile = !isScreenMd && !isOpenBurger;
  const isExpandedDesktop = isScreenMd && isExpanded;
  const isCollapsedDesktop = isScreenMd && !isExpanded;

  useEffect(() => {
    if (isExpandedMobile) {
      setShowOverlay(true);
    }
    if (isCollapsedMobile) {
      // Start showing the overlay after the duration of the animation
      setTimeout(() => {
        setShowOverlay(false);
      }, 50); // The same duration as your CSS transition (300ms)
    }
  }, [isScreenMd, isExpanded, isOpenBurger]);

  if (!render) {
    return null;
  }

  return (
    <>
      <aside
        className={`transition-width absolute grid h-full w-full grid-rows-[auto,1fr,auto] duration-300 md:relative ${
          isExpandedDesktop ? "w-sidebar-expanded" : ""
        } ${isCollapsedDesktop ? "w-sidebar-collapsed" : ""} ${
          isExpandedMobile ? "z-8 w-sidebar-expanded" : ""
        } ${isCollapsedMobile ? "-translate-x-full" : ""}`}
      >
        <SidebarHeader />
        <SidebarBody />
        <SidebarFooter />
      </aside>
      {showOverlay && (
        <div className="absolute top-0 z-7 h-full w-screen bg-portal" />
      )}
    </>
  );
}
