import { gql } from "@apollo/client";
import { ICorporateValues } from "types/CorporateValue.type";

export interface ICreateCorporateValueRes {
  createCorporateValue: ICorporateValues;
}
export interface ICreateCorporateValueInput {
  title: string;
  description: string;
  avatar: string;
}

const CREATE_CORPORATE_VALUE = gql`
  mutation CreateCorporateValue($corporateValue: CreateCorporateValueInput!) {
    createCorporateValue(corporateValue: $corporateValue) {
      description
      avatar
      title
      vendorId
    }
  }
`;

export interface IUpdateCorporateValueInput
  extends ICreateCorporateValueInput {}

const UPDATE_CORPORATE_VALUE = gql`
  mutation UpdateCorporateValue($corporateValue: UpdateCorporateValueInput!) {
    updateCorporateValue(corporateValue: $corporateValue) {
      description
      id
      title
      description
      avatar
    }
  }
`;

const DELETE_CORPORATE_VALUE_BY_ID = gql`
  mutation DeleteCorporateValue($id: String!) {
    deleteCorporateValue(id: $id) {
      id
      title
      description
    }
  }
`;

export interface CreateRecognitionInput {
  corporateValue: string;
  message: string;
  receivingUser: string;
}

export interface SaveRecognitionResponse {
  saveRecognition: {
    corporateValue: string;
    message: string;
    receivingUser: string;
  };
}

export const SAVE_RECOGNITION = gql`
  mutation saveRecognition($recognition: CreateRecognitionInput!) {
    saveRecognition(recognition: $recognition) {
      corporateValue
      receivingUser
      message
    }
  }
`;

export default {
  CREATE_CORPORATE_VALUE,
  UPDATE_CORPORATE_VALUE,
  DELETE_CORPORATE_VALUE_BY_ID,
};
