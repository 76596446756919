import SidebarContent from "./content/SidebarContent";

interface SidebarBodyProps {
  readonly render?: boolean;
}

export default function SidebarBody(props: SidebarBodyProps) {
  const { render = true } = props;

  if (!render) {
    return null;
  }

  return (
    <nav className="overflow-y-auto bg-sidebar-body dark:bg-gray-900">
      <SidebarContent />
    </nav>
  );
}
