import { gql } from "@apollo/client";
import { IRole } from "types/Role.type";

export interface IRolesResponse {
  roles: IRole[];
}

const GET_ROLES = gql`
  query roles {
    roles {
      id
      name
      avatar
      isQuickie
      dimensions {
        id
        name
      }
      maturities {
        id
        name
      }
      areas {
        id
        name
        avatar
      }
    }
  }
`;

export interface IVendorRolesResponse {
  vendorRoles: IRole[];
}

const GET_VENDOR_ROLES = gql`
  query vendorRoles {
    vendorRoles {
      id
      name
      avatar
      isQuickie
      dimensions {
        id
        name
      }
      maturities {
        id
        name
      }
      areas {
        id
        name
        avatar
      }
    }
  }
`;

const GET_ALL_VENDOR_ROLES_LIGHT = gql`
  query roles {
    roles {
      id
      name
      avatar
    }
  }
`;

const GET_VENDOR_ROLES_LIGHT = gql`
  query vendorRoles {
    vendorRoles {
      id
      name
      avatar
    }
  }
`;

export interface IGetRolesByDimensionInput {
  dimensions: string[];
}

const GET_ROLES_BY_DIMENSION = gql`
  query rolesByDimension {
    rolesByDimension {
      id
      name
    }
  }
`;

export default {
  GET_ROLES,
  GET_ROLES_BY_DIMENSION,
  GET_VENDOR_ROLES,
  GET_VENDOR_ROLES_LIGHT,
  GET_ALL_VENDOR_ROLES_LIGHT,
};
