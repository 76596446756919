import { gql } from "@apollo/client";
import { INotificationPaginated } from "types/Notifcation.type";

export interface IGetAllNotificationsInput {
  getAllNotificationInput: {
    onlyUnread?: boolean;
    limit?: number;
    offset?: number;
  };
}

export interface IGetAllNotificationsResponse {
  notificationsPaginated: INotificationPaginated;
}

const NOTIFICATIONS = gql`
  query Notifications($getAllNotificationInput: GetAllNotificationsInput!) {
    notificationsPaginated(getAllNotificationInput: $getAllNotificationInput) {
      notifications {
        id
        message
        createdAt
        type
        read
        entityId
      }
      total
      totalUnread
    }
  }
`;

const NOTIFICATION_COUNT = gql`
  query getNotificationCount {
    getNotificationCount
  }
`;

export default { NOTIFICATIONS, NOTIFICATION_COUNT };
