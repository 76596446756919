import { gql } from "@apollo/client";
import { TQuickiesTypes } from "context/quickie/quickie.types";
import { EUserSeniority } from "types/User.types";
import {
  ECompanySegment,
  EEntity,
  IVendor,
  IVendorAddress,
  IVendorBanner,
} from "types/Vendor.types";

export interface IUpdateVendorInput {
  id: string;
  name: string;
  phone?: string;
  email?: string;
  logo?: string;
  banners?: IVendorBanner[];
  slogan?: string;
  description?: string;
  address?: IVendorAddress;
  jobyfineHiringManagers?: string[];
  jobyfineNotificationEmails?: string[];
  jobyfinePortalSlogan?: string;
  jobyfinePortalDescription?: string;
  jobyfinePortalTitle?: string;
  jobyfineResumeBanner?: string;
  corporateValuesMaxRecognitions?: number;
  domains?: string[];
  jobyfinePortalUrl?: string;
  clientId?: string;
  tenantId?: string;
  clientSecret?: string;
  emailDomains?: string[];
  onlyAdLogin?: boolean;
  identifier?: string;
  happyBirthdayPrompt?: string;
  happyBirthdayPromptText?: string;
  anniversaryPrompt?: string;
  anniversaryPromptText?: string;
  newUsersPrompt?: string;
  newUsersPromptText?: string;
  usersWithoutRecognitionRestrictions?: string[];
  loginBackgroundImage?: string;
  isFullAccessEnabled?: boolean;
  enableCertificates?: boolean;
}

export interface IUpdateVendorResponse {
  updateVendor: IVendor;
}

export const UPDATE_VENDOR = gql`
  mutation updateVendor($updateVendorInput: UpdateVendorInput!) {
    updateVendor(updateVendorInput: $updateVendorInput) {
      id
    }
  }
`;

export interface ICreateVendorSaasInput {
  name: string;
  phone?: string;
  email?: string;
  employeeCount?: number;
  companySegment?: ECompanySegment;
  reCaptcha: string;
}

export const CREATE_VENDOR_SASS = gql`
  mutation createVendorSaas($createVendorSaasInput: CreateVendorSaasInput!) {
    createVendorSaas(createVendorSaasInput: $createVendorSaasInput) {
      id
      domains
    }
  }
`;

export interface IToggleIsQuickieInput {
  type: TQuickiesTypes;
  id: string;
  isQuickie: boolean;
}

export const TOGGLE_IS_QUICKIE = gql`
  mutation toggleIsQuickie($toggleIsQuickieInput: ToggleIsQuickieInput!) {
    toggleIsQuickie(toggleIsQuickieInput: $toggleIsQuickieInput)
  }
`;

export interface IInsertQuickiesInput {
  type: TQuickiesTypes;
  ids: string[];
}

export const INSERT_QUICKIES = gql`
  mutation insertQuickies($insertQuickiesInput: InsertQuickiesInput!) {
    insertQuickies(insertQuickiesInput: $insertQuickiesInput)
  }
`;

export interface INotifyVendorUpdateSeniorityInput {
  seniority: EUserSeniority;
}

export const NOTIFY_UPDATE_SENIORITY = gql`
  mutation notifyVendorUpdateSeniority(
    $notifyVendorUpdateSeniorityInput: NotifyVendorUpdateSeniorityInput!
  ) {
    notifyVendorUpdateSeniority(
      notifyVendorUpdateSeniorityInput: $notifyVendorUpdateSeniorityInput
    )
  }
`;

export interface ICreateApiKeyInput {
  name: string;
  permissions: EEntity[];
}

export interface ICreateApiKeyResponse {
  createApiKey: IVendor;
}

const CREATE_APIKEY = gql`
  mutation CreateApiKey($createApiKeyInput: CreateApiKeyInput!) {
    createApiKey(createApiKeyInput: $createApiKeyInput) {
      apiKeys {
        deleted
        key
        name
      }
    }
  }
`;

const DELETE_APIKEY = gql`
  mutation DeleteApiKey($apiKey: String!) {
    deleteApiKey(apiKey: $apiKey) {
      apiKeys {
        deleted
        key
        name
      }
    }
  }
`;

const UPDATE_VENDOR_MODULE = gql`
  mutation UpdateVendorSaasModules(
    $updateVendorSaasModulesInput: UpdateVendorSaasModulesInput!
  ) {
    updateVendorSaasModules(
      updateVendorSaasModulesInput: $updateVendorSaasModulesInput
    ) {
      id
    }
  }
`;

const PREVISUALIZE_PROMPT = gql`
  mutation PrevisualizePrompt($openAiInput: OpenAIInput!) {
    previsualizePrompt(openAIInput: $openAiInput) {
      result
      context
    }
  }
`;

const CREATE_VENDOR_LINK = gql`
  mutation CreateVendorLink($createVendorLinksInput: CreateVendorLinksInput!) {
    createVendorLink(createVendorLinksInput: $createVendorLinksInput) {
      id
      order
      icon
      name
      url
    }
  }
`;

const DELETE_VENDOR_LINK = gql`
  mutation DeleteVendorLink($deleteVendorLinkId: String!) {
    deleteVendorLink(id: $deleteVendorLinkId) {
      id
    }
  }
`;

export const UPDATE_VENDOR_LINK = gql`
  mutation UpdateVendorLink($updateVendorLinkInput: UpdateVendorLinkInput!) {
    updateVendorLink(updateVendorLinkInput: $updateVendorLinkInput) {
      id
      order
      icon
      name
      url
    }
  }
`;

export default {
  UPDATE_VENDOR,
  CREATE_VENDOR_SASS,
  TOGGLE_IS_QUICKIE,
  INSERT_QUICKIES,
  NOTIFY_UPDATE_SENIORITY,
  DELETE_APIKEY,
  CREATE_APIKEY,
  UPDATE_VENDOR_MODULE,
  PREVISUALIZE_PROMPT,
  CREATE_VENDOR_LINK,
  UPDATE_VENDOR_LINK,
  DELETE_VENDOR_LINK,
};
