import { gql } from "@apollo/client";
import { IReaction } from "types/Reaction";

export interface IGetAllReactionInput {
  foreignId: string;
}

export interface IGetAllReactionResponse {
  reactions: IReaction[];
}

const GET_REACTIONS = gql`
  query reactions($getAllReactionInput: GetAllReactionInput!) {
    reactions(getAllReactionInput: $getAllReactionInput) {
      id
      type
      reaction
      user {
        name
        lastname
      }
    }
  }
`;

export default { GET_REACTIONS };
