import { gql } from "@apollo/client";
import { EQuestionStatus } from "types/Question/enums/question-type.enum";
import IQuestion from "types/Question/IQuestion";

const GET_BY_TAG = gql`
  query QuestionsByTag($tagsSearchInput: TagsSearchInput!) {
    questionsByTag(tagsSearchInput: $tagsSearchInput) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      visibleOptions
      correctOptions
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
      maturityId
      options {
        id
        updatedAt
        createdAt
        deletedAt
        question
        name
        isCorrect
        createdBy
      }
      dimension {
        id
        updatedAt
        createdAt
        name
        deletedAt
        roles {
          id
          updatedAt
          createdAt
          deletedAt
          name
          active
          vendor
          avatar
        }
        active
        vendor
        tags
      }
      tags {
        id
        name
      }
      active
    }
  }
`;

const QUESTION_PAGINATED = gql`
  query Questions($tagsSearchInput: TagsSearchInput!) {
    questions(tagsSearchInput: $tagsSearchInput) {
      questions {
        id
        name
        updatedAt
        createdAt
        deletedAt
        visibleOptions
        correctOptions
        wilsonScore
        status
        feedback {
          reason
          user {
            id
            name
            lastname
            profileImage
            email
          }
        }
        createdBy {
          id
          name
          email
          lastname
          profileImage
          linkedin
          facebook
          twitter
          instagram
          github
          coverImage
          shortBio
        }
        maturityId
        options {
          id
          updatedAt
          createdAt
          deletedAt
          question
          name
          isCorrect
          createdBy
        }
        dimension {
          id
          updatedAt
          createdAt
          originalId
          name
          deletedAt

          active
          vendor
          tags {
            id
            name
          }
        }
        tags {
          id
          name
        }
        active
      }
      total
    }
  }
`;

const GET_QUESTION = gql`
  query Question($questionId: String!) {
    question(id: $questionId) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      visibleOptions
      correctOptions
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
      maturityId
      options {
        id
        updatedAt
        createdAt
        deletedAt
        question
        name
        isCorrect
        createdBy
      }
      dimension {
        id
        updatedAt
        createdAt
        originalId
        name
        deletedAt
        roles {
          id
          updatedAt
          createdAt
          deletedAt
          name
          active
          vendor
          avatar
        }
        active
        vendor
        tags {
          id
          name
        }
      }
      tags {
        id
        name
      }
      active
    }
  }
`;

export interface ITagsSearchInput {
  tags?: string[];
  names?: string[];
  dimensions?: string[];
  appreciation?: string;
  limit?: number;
  offset?: number;
  onlyWithFeedback?: boolean;
  creator?: string;
  status?: EQuestionStatus;
}

export interface IQuestionPaginated {
  questions: IQuestion[];
  total: number;
}

export interface IGetPracticeQuestionsRes {
  dailyQuestions: IQuestion[];
}
const GET_PRATICE_QUESTIONS = gql`
  query Question {
    dailyQuestions {
      id
      name
      correctOptions
      dimension {
        id
        name
      }
      tags {
        id
        name
      }
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
      options {
        id
        name
      }
    }
  }
`;

export interface IGetChallengeQuestionsInput {
  startedChallenge: string;
}
const GET_CHALLENGE_QUESTIONS = gql`
  query challengeQuestions(
    $getChallengeQuestionsInput: GetChallengeQuestionsInput!
  ) {
    challengeQuestions(
      getChallengeQuestionsInput: $getChallengeQuestionsInput
    ) {
      id
      name
      correctOptions
      dimension {
        id
        name
      }
      tags {
        id
        name
      }
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
      options {
        id
        name
      }
    }
  }
`;

const DAILY_QUESTION_STATS = gql`
  query dailyQuestionStats {
    dailyQuestionStats {
      # roleParticipation {
      #   avatar
      #   name
      #   value
      # }
      # userParticipation {
      #   id
      #   profileImage
      #   name
      #   lastname
      #   email
      #   value
      #   totalSended
      #   shortBio
      #   linkedin
      #   instagram
      #   twitter
      #   facebook
      #   github
      #   coverImage
      # }
      # frequentlyQuestion {
      #   name
      #   value
      # }
      monthlyChart {
        sent
        answered
        label
      }
      userRankByRole {
        role {
          id
          name
          avatar
        }
        users {
          id
          profileImage
          name
          rank
          wilsonScore
          lastActivity
        }
      }
    }
  }
`;

export default {
  GET_BY_TAG,
  QUESTION_PAGINATED,
  GET_QUESTION,
  GET_PRATICE_QUESTIONS,
  DAILY_QUESTION_STATS,
  GET_CHALLENGE_QUESTIONS,
};
