import { gql } from "@apollo/client";

export interface ISendCertificateInput {
  users: string[];
  certificate: string;
}

const SEND_CERTIFICATE_BY_EMAIL = gql`
  mutation AssignCertificates(
    $assignCertificateInput: AssignCertificateInput!
  ) {
    assignCertificates(assignCertificateInput: $assignCertificateInput)
  }
`;

export interface ICreateCertificateSendByTeamsInput {
  certificateId: string;
  teamIds: string[];
}

const SEND_CERTIFICATE_BY_TEAM = gql`
  mutation AssignCertificateToTeam($assignCertificateInput: AssignTeamInput!) {
    assignCertificateToTeam(assignCertificateInput: $assignCertificateInput)
  }
`;

export interface IUpdateCertificateStateByUserInput {
  certificateId: string;
  state: boolean;
}

const UPDATE_CERTIFICATE_STATE_BY_USER = gql`
  mutation UpdateCertificateStateByUser($userCertificateId: String!) {
    updateCertificateStateByUser(userCertificateId: $userCertificateId)
  }
`;

export default {
  SEND_CERTIFICATE_BY_EMAIL,
  SEND_CERTIFICATE_BY_TEAM,
  UPDATE_CERTIFICATE_STATE_BY_USER,
};
