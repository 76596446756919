import { gql } from "@apollo/client";
import { IProduct } from "types/Product.type";

const CREATE_PRODUCT_CATEGORY = gql`
  mutation createProductCategory(
    $createProductCategoryInput: CreateProductCategoryInput!
  ) {
    createProductCategory(
      createProductCategoryInput: $createProductCategoryInput
    ) {
      id
      name
    }
  }
`;

const UPDATE_PRODUCT_CATEGORY = gql`
  mutation updateProductCategory(
    $updateProductCategoryInput: UpdateProductCategoryInput!
  ) {
    updateProductCategory(
      updateProductCategoryInput: $updateProductCategoryInput
    ) {
      id
      name
    }
  }
`;

const DELETE_PRODUCT_CATEGORY = gql`
  mutation DeleteProductCategory($deleteProductCategoryId: String!) {
    deleteProductCategory(id: $deleteProductCategoryId) {
      id
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($createProductInput: CreateProductInput!) {
    createProduct(createProductInput: $createProductInput) {
      id
      name
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($updateProductInput: UpdateProductInput!) {
    updateProduct(updateProductInput: $updateProductInput) {
      id
      name
    }
  }
`;

const TOGGLE_PRODUCT = gql`
  mutation ToggleProduct($toggleProductInput: ToggleProductInput!) {
    toggleProduct(toggleProductInput: $toggleProductInput) {
      id
    }
  }
`;

const CREATE_COIN_CATEGORIE = gql`
  mutation createCoinCategorie(
    $createCoinCategorieInput: CreateCoinCategorieInput!
  ) {
    createCoinCategorie(createCoinCategorieInput: $createCoinCategorieInput) {
      id
      name
    }
  }
`;

const CREATE_COIN = gql`
  mutation createCoin($createCoinInput: CreateCoinInput!) {
    createCoin(createCoinInput: $createCoinInput) {
      id
    }
  }
`;

const UPDATE_COIN_CATEGORIE = gql`
  mutation updateCoinCategorie(
    $updateCoinCategorieInput: UpdateCoinCategorieInput!
  ) {
    updateCoinCategorie(updateCoinCategorieInput: $updateCoinCategorieInput) {
      id
      name
    }
  }
`;

const UPDATE_COIN_TRANSACTION_REQUEST = gql`
  mutation updateCoinTransactionRequest(
    $updateCoinTransactionRequestInput: UpdateCoinTransactionRequestInput!
  ) {
    updateCoinTransactionRequest(
      updateCoinTransactionRequestInput: $updateCoinTransactionRequestInput
    ) {
      id
      state
    }
  }
`;

const DELETE_COIN_CATEGORY = gql`
  mutation DeleteCoinCategory($deleteCoinCategoryId: String!) {
    deleteCoinCategory(id: $deleteCoinCategoryId) {
      id
    }
  }
`;

const TOGGLE_DEFAULT_COIN_CATEGORY = gql`
  mutation toggleDefaultCoinCategorie($toggleDefaultCoinCategorieId: String!) {
    toggleDefaultCoinCategorie(id: $toggleDefaultCoinCategorieId) {
      id
    }
  }
`;

export interface IAddToBasketInput {
  productId: string;
  productVariant?: string;
}

export interface IAddToBasketResponse {
  addToBasket: IProduct[];
}

const ADD_TO_BASKET = gql`
  mutation AddToBasket($addToBasketInput: AddToBasketInput!) {
    addToBasket(addToBasketInput: $addToBasketInput) {
      id
    }
  }
`;

export interface IRemoveFromBasketInput {
  productId: string;
  removeAll?: boolean;
  productVariant?: string;
}

export interface IRemoveFromBasketResponse {
  removeFromBasket: IProduct[];
}

const REMOVE_FROM_BASKET = gql`
  mutation RemoveFromBasket($removeFromBasketInput: RemoveFromBasketInput!) {
    removeFromBasket(removeFromBasketInput: $removeFromBasketInput) {
      id
    }
  }
`;

const CREATE_COIN_TRANSACTION_REQUEST = gql`
  mutation CreateCoinTransactionRequest(
    $createCoinTransactionRequestInput: CreateCoinTransactionRequestInput!
  ) {
    createCoinTransactionRequest(
      createCoinTransactionRequestInput: $createCoinTransactionRequestInput
    ) {
      id
      totalPrice
    }
  }
`;

const UPDATE_COIN_TRANSACTION_REQUEST_STATE = gql`
  mutation UpdateCoinTransactionRequest(
    $updateCoinTransactionRequestInput: UpdateCoinTransactionRequestInput!
  ) {
    updateCoinTransactionRequest(
      updateCoinTransactionRequestInput: $updateCoinTransactionRequestInput
    ) {
      id
      state
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteProduct($deleteProductInput: DeleteProductInput!) {
    deleteProduct(deleteProductInput: $deleteProductInput) {
      id
    }
  }
`;

export default {
  CREATE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  TOGGLE_PRODUCT,
  DELETE_PRODUCT,
  CREATE_COIN_CATEGORIE,
  UPDATE_COIN_CATEGORIE,
  UPDATE_COIN_TRANSACTION_REQUEST,
  DELETE_COIN_CATEGORY,
  CREATE_COIN,
  TOGGLE_DEFAULT_COIN_CATEGORY,
  REMOVE_FROM_BASKET,
  ADD_TO_BASKET,
  CREATE_COIN_TRANSACTION_REQUEST,
  UPDATE_COIN_TRANSACTION_REQUEST_STATE,
};
