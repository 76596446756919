import { gql } from "@apollo/client";

export interface ICreateDimensionInput {
  id: string;
  name: string;
  tags: string[];
}

const CREATE_DIMENSION = gql`
  mutation createDimension($createDimensionInput: CreateDimensionInput!) {
    createDimension(createDimensionInput: $createDimensionInput) {
      id
    }
  }
`;

export interface IUpdateDimensionInput extends ICreateDimensionInput {}

const UPDATE_DIMENSION = gql`
  mutation updateDimension($updateDimensionInput: UpdateDimensionInput!) {
    updateDimension(updateDimensionInput: $updateDimensionInput) {
      id
      name
      tags {
        id
        name
      }
    }
  }
`;

const DELETE_DIMENSION_BY_ID = gql`
  mutation deleteDimension($id: String!) {
    deleteDimension(id: $id) {
      id
      name
    }
  }
`;

export default { CREATE_DIMENSION, UPDATE_DIMENSION, DELETE_DIMENSION_BY_ID };
