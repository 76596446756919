import Icon from "components/Icon";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import { Button } from "rsuite";
import useSound from "use-sound";

const ToggleTheme = () => {
  const [isToggleThemeEnable, setIsToggleThemeEnable] = useState<boolean>(true);
  const { theme, setTheme } = useTheme();
  const [playComplete] = useSound("/sounds/switch-on.mp3", { volume: 0.25 });

  useEffect(() => {
    setTimeout(() => {
      setIsToggleThemeEnable(true);
    }, 1000);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    playComplete();
  };

  return (
    <Button
      appearance="subtle"
      aria-label="Toggle theme"
      className="flex items-center justify-center bg-transparent hover:bg-gray-100 focus:bg-transparent dark:bg-transparent dark:hover:bg-gray-800 dark:focus:bg-transparent"
      disabled={!isToggleThemeEnable}
      onClick={toggleTheme}
      size="md"
      style={{
        width: 40,
        height: 40,
      }}
    >
      {theme === "dark" && (
        <Icon
          icon={["fal", "moon-cloud"]}
          className="animate__animated animate__fadeIn text-gray-700 dark:text-gray-200"
          size="lg"
        />
      )}
      {theme === "light" && (
        <Icon
          icon={["fal", "sun-cloud"]}
          className="animate__animated animate__fadeIn text-gray-700 dark:text-gray-200"
          size="lg"
        />
      )}
    </Button>
  );
};
export default ToggleTheme;
