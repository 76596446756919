import { gql } from "@apollo/client";
import { UserFeedbackEnum } from "types/UserFeedback.type";

export interface ICreateUserFeedbackInput {
  id: string;
  userId: string;
  feedbackSentBy: string;
  projectOrCustomer: string;
  publicationDate: Date;
  feedback: string;
  type: UserFeedbackEnum;
  emailToLeaders?: boolean;
}

const CREATE_USER_FEEDBACK = gql`
  mutation CreateUserFeedback(
    $createUserFeedbackInput: CreateUserFeedbackInput!
  ) {
    createUserFeedback(createUserFeedbackInput: $createUserFeedbackInput) {
      id
      feedback
      feedbackSentBy
      projectOrCustomer
      type
    }
  }
`;

const DELETE_USER_FEEDBACK = gql`
  mutation deleteUserFeedback($deleteUserFeedbackId: String!) {
    deleteUserFeedback(id: $deleteUserFeedbackId)
  }
`;

export default {
  CREATE_USER_FEEDBACK,
  DELETE_USER_FEEDBACK,
};
