import { gql } from "@apollo/client";

export interface ICreateMaturityInput {
  id: string;
  name: string;
  priority: number;
  avatar?: string;
  role: string;
}

const CREATE_MATURITY = gql`
  mutation createMaturity($createMaturityInput: CreateMaturityInput!) {
    createMaturity(createMaturityInput: $createMaturityInput) {
      id
    }
  }
`;

export interface IUpdateMaturityInput extends ICreateMaturityInput {}

const UPDATE_MATURITY = gql`
  mutation updateMaturity($updateMaturityInput: UpdateMaturityInput!) {
    updateMaturity(updateMaturityInput: $updateMaturityInput) {
      id
    }
  }
`;

const DELETE_MATURITY_BY_ID = gql`
  mutation deleteMaturity($id: String!) {
    deleteMaturity(id: $id) {
      id
      name
    }
  }
`;

export interface IUpdateMaturityPriorityInput {
  items: IUpdateMaturityPriorityItemInput[];
}

export interface IUpdateMaturityPriorityItemInput {
  id: string;
  priority: number;
}

const UPDATE_MATURITY_PRIORITY = gql`
  mutation updateMaturityPriority(
    $updateMaturityPriorityInput: UpdateMaturityPriorityInput!
  ) {
    updateMaturityPriority(
      updateMaturityPriorityInput: $updateMaturityPriorityInput
    )
  }
`;

export default {
  CREATE_MATURITY,
  UPDATE_MATURITY,
  DELETE_MATURITY_BY_ID,
  UPDATE_MATURITY_PRIORITY,
};
