import PublicNavBar from "components/NavBar/PublicNavBar";
import { FC, ReactNode } from "react";

interface PublicWithHeaderProps {
  children: ReactNode;
}

const PublicWithHeader: FC<PublicWithHeaderProps> = ({ children }) => (
  <div className="grid h-full min-h-dvh content-start overflow-y-auto">
    <PublicNavBar />
    {children}
  </div>
);

export default PublicWithHeader;
