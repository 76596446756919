import { gql } from "@apollo/client";
import { EUserActivityType, IUserActivity } from "types/UserActivity.type";

export interface IGetUserActivityInput {
  userId?: string;
  type?: EUserActivityType;
}

export interface IGetUserActivityResponse {
  userActivities: IUserActivity[];
}

const GET_USER_ACTIVITIES = gql`
  query userActivities($getUserActivitiesInput: GetUserActivityInput!) {
    userActivities(getUserActivitiesInput: $getUserActivitiesInput) {
      type
      id
      createdAt
      message
    }
  }
`;

export default { GET_USER_ACTIVITIES };
