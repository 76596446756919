import { gql } from "@apollo/client";
import { IActivity } from "types/Activitites.type";

export interface IGetAllActivityInput {
  tags?: string[];
  searchText?: string;
  startDate?: Date;
  endDate?: Date;
  all?: boolean;
  sortByStartDate?: boolean;
}

export interface IGetAllActivityResponse {
  activities: IActivity[];
}

const GET_ACTIVITIES = gql`
  query activities($getAllActivityInput: GetAllActivityInput!) {
    activities(getAllActivityInput: $getAllActivityInput) {
      id
      name
      description
      createdAt
      startDate
      endDate
      tags
      eventUrl
      isPublic
      coins
      redeemCode
      images
      type
      slug
      activityAddress {
        address
        lat
        lng
      }
      iMayGo {
        email
        reason
      }
      iCantGo {
        email
        reason
      }
      iWillGo {
        email
        reason
      }
      invitedUsers {
        id
        name
        email
        profileImage
      }
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
    }
  }
`;

const GET_ACTIVITIES_LIGHT = gql`
  query activities($getAllActivityInput: GetAllActivityInput!) {
    activities(getAllActivityInput: $getAllActivityInput) {
      id
      name
      slug
      description
      createdAt
      startDate
      endDate
      invitedUsers {
        id
        name
        email
        profileImage
      }
      isPublic
      images
    }
  }
`;

export interface IGetActivityInput {
  id?: string;
  slug?: string;
}

export interface IGetActivityResponse {
  activity: IActivity;
}

const GET_ACTIVITY = gql`
  query activity($getActivityInput: GetActivityInput!) {
    activity(getActivityInput: $getActivityInput) {
      id
      name
      coins
      description
      createdAt
      startDate
      endDate
      tags
      eventUrl
      isPublic
      images
      activityAddress {
        address
        lat
        lng
      }
      vendorId {
        name
      }
      type
      iMayGo {
        email
        reason
      }
      iCantGo {
        email
        reason
      }
      iWillGo {
        email
        reason
      }
      invitedUsers {
        name
        email
        id
        profileImage
      }
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
    }
  }
`;

const GET_SEO_ACTIVITY = gql`
  query seoActivity($getSeoActivityInput: GetActivityInput!) {
    seoActivity(getSeoActivityInput: $getSeoActivityInput) {
      id
      name
      description
      coins
      eventUrl
      images
      vendorId {
        id
      }
    }
  }
`;

export interface IGetPublicActivityInput {
  id: string;
  email: string;
  sessionId?: string;
  verificationCode?: string;
}
const GET_PUBLIC_ACTIVITY = gql`
  query publicActivity($getPublicActivityInput: GetPublicActivityInput!) {
    publicActivity(getPublicActivityInput: $getPublicActivityInput) {
      id
      name
      description
      createdAt
      startDate
      endDate
      tags
      eventUrl
      isPublic
      images
      coins
      userResponse
      activityAddress {
        address
        lat
        lng
      }
      vendorId {
        name
      }
      type
      iCantGo {
        email
        reason
        name
        user {
          name
          lastname
          profileImage
        }
      }
      iWillGo {
        email
        reason
        name
        user {
          name
          lastname
          profileImage
        }
      }
      iMayGo {
        email
        reason
        name
        user {
          name
          lastname
          profileImage
        }
      }
      invitedUsers {
        name
        email
        id
        profileImage
      }
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
    }
  }
`;

const ACTIVITY_REPORT = gql`
  query Query {
    activityReport
  }
`;

export default {
  GET_ACTIVITIES,
  GET_ACTIVITY,
  GET_SEO_ACTIVITY,
  GET_PUBLIC_ACTIVITY,
  ACTIVITY_REPORT,
  GET_ACTIVITIES_LIGHT,
};
