import { useEffect, useState } from "react";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import LogoGroow from "./ui/LogoGroow";
import ToggleCompresSidebar from "./ui/ToggleCompressSidebar";

interface SidebarHeaderProps {
  readonly render?: boolean;
}

export default function SidebarHeader(props: SidebarHeaderProps) {
  const { isExpanded } = useDesktopSidebarStore();
  const [isDesktopLogo, setIsDesktopLogo] = useState(true);

  useEffect(() => {
    if (!isExpanded) {
      setTimeout(() => {
        setIsDesktopLogo(false);
      }, 200);
    }
    if (isExpanded) {
      setIsDesktopLogo(true);
    }
  }, [isExpanded]);

  const { render = true } = props;

  if (!render) {
    return null;
  }
  return (
    <div className="relative flex h-sidebar-header-mobile w-full items-center border-b-[1px] border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-900 md:h-sidebar-header-desktop">
      <LogoGroow isImagotipo={isDesktopLogo} />
      <ToggleCompresSidebar
        isDesktopLogo={isDesktopLogo}
        setIsDesktopLogo={setIsDesktopLogo}
      />
    </div>
  );
}
