import { gql } from "@apollo/client";

export interface IGetUserFeedbackByUserIdInput {
  userId: string;
}

const GET_USER_FEEDBACKS_BY_USER_ID = gql`
  query UserFeedbacksByUserId($userId: String!) {
    userFeedbacksByUserId(userId: $userId) {
      id
      createdAt
      publicationDate
      feedbackSentBy
      feedback
      type
      projectOrCustomer
      reason
      sentimentType
      userReceivingFeedback {
        id
        name
        lastname
      }
      createdBy {
        profileImage
        name
        lastname
      }
    }
  }
`;

export default { GET_USER_FEEDBACKS_BY_USER_ID };
