import { useLazyQuery } from "@apollo/client";
import { HAS_USER_OPENED_QUICKIES } from "@constants/global";
import useTranslation from "next-translate/useTranslation";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import { Button, Input } from "rsuite";
import { forceRefetchQueries } from "settings/apollo";
import useSound from "use-sound";
import lottieSuccess from "utils/lottie/success.json";
import thinkingAi from "utils/lottie/thinking.json";
import Icon from "../../components/Icon";
import { gqlUser, gqlVendor } from "../../gql";
import {
  IQuickiesInput,
  IQuickiesRes,
  VendorQuickie,
} from "../../gql/Vendor/queries";
import { IQuickieList } from "./quickie.context";
import { ISelectedQuickieItems } from "./quickie.provider";
import { insertQuickies } from "./quickie.utils";

type TThunderAnim = "" | "vibrating";
type TListAnim = "" | "step-in" | "step-out";

interface IQuickie {
  isBubbleVisible: boolean;
  list: IQuickieList;
  setOpenQuickie: Dispatch<SetStateAction<() => void>>;
  setCloseQuickie: Dispatch<SetStateAction<() => void>>;
  setIsBubbleVisible: (isBubbleVisible: boolean) => void;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: lottieSuccess,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultThinkingOptions = {
  loop: true,
  autoplay: true,
  animationData: thinkingAi,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Quickie = (props: IQuickie) => {
  const { t } = useTranslation("common");
  const [items, setItems] = useState<VendorQuickie[]>([]);
  const [search, setSearch] = useState<string>("");

  const [playComplete] = useSound("/sounds/feedback-sound.mp3", {
    volume: 0.25,
  });

  const {
    isBubbleVisible,
    list,
    setOpenQuickie,
    setCloseQuickie,
    setIsBubbleVisible,
  } = props;

  const [getQuickies, { loading: quickiesLoading, data }] = useLazyQuery<
    IQuickiesRes,
    IQuickiesInput
  >(gqlVendor.queries.GET_QUICKIES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ quickies }) => {
      setItems(quickies || []);
      if (!quickies.length) {
        setIsBubbleVisible(false);
      }
    },
  });

  const [showWelcome, setShowWelcome] = useState<boolean>(false);
  const [thunderAnim, setThunderAnim] = useState<TThunderAnim>("");
  const [listAnim, setListAnim] = useState<TListAnim>("");
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<ISelectedQuickieItems>({});
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>(false);

  useEffect(() => {
    // * Make the thunder dance if the user's never opened it
    if (!localStorage.getItem(HAS_USER_OPENED_QUICKIES)) {
      setThunderAnim("vibrating");
    }

    // * Set context's openQuickie
    setOpenQuickie(() => showList);
    setCloseQuickie(() => hideList);
  }, []);

  useEffect(() => {
    if (!list?.type) return;

    setItems([]);
    setIsListVisible(false);
    getQuickies({
      variables: {
        getQuickiesInput: {
          type: list.type,
        },
      },
    });
  }, [list]);

  const showList = () => {
    if (!localStorage.getItem(HAS_USER_OPENED_QUICKIES)) {
      setShowWelcome(true);
    }
    setIsListVisible(true);
    setListAnim("step-in");
  };

  const hideList = () => {
    setIsListVisible(false);
    setListAnim("step-out");
  };

  const onWelcomeClose = () => {
    setShowWelcome(false);
  };

  const onClose = () => {
    setListAnim("step-out");
    setSearch("");

    setTimeout(() => {
      setIsListVisible(false);
    }, 650);
  };

  const onSelectItem = (item: string) => {
    const newSelectedItems = list.isSingleChoice ? {} : { ...selectedItems };

    if (selectedItems[item]) {
      delete newSelectedItems[item];
    } else {
      newSelectedItems[item] = item;
    }

    setSelectedItems(newSelectedItems);
  };

  const handleCreate = async () => {
    try {
      setIsCreating(true);
      await insertQuickies(list.type, Object.keys(selectedItems));
      setIsCreated(true);
      await forceRefetchQueries([
        ...(list.refetchQueries || []),
        gqlVendor.queries.GET_QUICKIES,
        gqlUser.queries.USER_ONBOARDING,
      ]);

      localStorage.setItem(HAS_USER_OPENED_QUICKIES, "true");

      playComplete();

      setTimeout(() => {
        setSelectedItems({});
        setIsCreated(false);
        setIsCreating(false);
      }, 4000);
    } catch (e) {
      toast.error(e.message);
      setIsCreating(false);
    }
  };

  const handleSearch = (text: string) => {
    setSearch(text);

    if (!text) {
      setItems(data?.quickies || []);
      return;
    }

    const filteredItems = data?.quickies.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );

    setItems(filteredItems || []);
  };

  return (
    <>
      {isBubbleVisible && (
        <div
          id="quickie-bubble"
          className="fixed bottom-5 right-5 flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-white shadow-md dark:bg-gray-800"
          onClick={showList}
          style={{
            zIndex: 6,
          }}
        >
          <span role="img" className={`text-3xl ${thunderAnim}`}>
            ⚡
          </span>
        </div>
      )}

      {isListVisible && (
        <div
          className={`fixed bottom-5 right-5 z-50 flex w-5/6 flex-col overflow-hidden rounded-md bg-white p-2 text-black dark:bg-gray-900 md:w-1/4 ${listAnim} shadow-lg`}
          style={{
            height: "50vh",
          }}
        >
          <div className="absolute right-2 top-2 flex justify-end p-3">
            <Icon
              icon={["far", "x"]}
              onClick={onClose}
              className="text-md cursor-pointer text-gray-500 dark:text-white"
            />
          </div>

          {showWelcome ? (
            <div className="flex h-full flex-col justify-between p-3">
              <span className="text-center text-xl font-bold dark:text-white">
                <Icon icon={["far", "bolt"]} className="mr-2 text-yellow-500" />
                {t("Conoce los quickies")}
              </span>

              <p className="my-10 overflow-auto text-lg dark:text-white">
                {t("Genera tus elementos de la manera")}{" "}
                <b>{t("más rápida")}</b>{" "}
                {t("a partir de plantillas que tenemos diseñadas para ti")}{" "}
                <br />
                <br />
                {t(
                  "Solo debes elegir de la lista lo que necesitas y nosotros lo creamos a la "
                )}
                <i>{t("velocidad de la luz")}</i>
              </p>

              <Button appearance="primary" size="lg" onClick={onWelcomeClose}>
                {t("¡Entendido!")}
              </Button>
            </div>
          ) : (
            <>
              {!isCreating && (
                <div className="flex flex-col p-3">
                  <span className="text-2xl font-bold text-black dark:text-white">
                    {list?.header}
                  </span>
                  <span className="text-md text-gray-600 dark:text-gray-200">
                    {list?.description}
                  </span>
                  {(data?.quickies?.length || 0) > 10 && (
                    <Input
                      size="xs"
                      className="mt-2"
                      value={search}
                      onChange={handleSearch}
                      placeholder={`${t(
                        "Buscar"
                      )} ${list?.header?.toLowerCase()}`}
                      style={{
                        height: 40,
                      }}
                    />
                  )}
                </div>
              )}

              {!isCreating && (
                <div className="flex flex-1 flex-col overflow-auto border-b border-gray-200 text-black dark:border-gray-600">
                  {items?.map((item) => (
                    <div
                      key={item.id}
                      className="flex cursor-pointer gap-2 p-3 hover:bg-gray-100 dark:hover:bg-gray-800"
                      onClick={() => onSelectItem(item.id)}
                    >
                      {selectedItems[item.id] ? (
                        <Icon
                          icon={["far", "check-circle"]}
                          className="mr-3 text-xl text-green-500"
                        />
                      ) : (
                        <Icon
                          icon={["far", "circle"]}
                          className="mr-3 text-xl text-gray-500"
                        />
                      )}
                      <span className="dark:text-white">{item.name}</span>
                    </div>
                  ))}

                  {items?.length === 0 && (
                    <div className="flex flex-1 flex-col items-center justify-center">
                      <span className="text-gray-700 dark:text-white">
                        {t("No se encontraron resultados")}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {isCreating &&
                (!isCreated ? (
                  <div className="flex flex-1 flex-col items-center justify-center">
                    <Lottie
                      options={defaultThinkingOptions}
                      height={160}
                      width={160}
                    />
                    <span className="max-w-1/2 text-xs text-gray-500 dark:text-white">
                      {t("WAIT_A_MOMENT")}
                    </span>
                    <span className="max-w-1/2 text-gray-700 dark:text-white">
                      {t("CREATING_ITEMS")}
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-1 flex-col items-center justify-center">
                    <Lottie options={defaultOptions} height={160} width={160} />
                    <span className="max-w-1/2 text-xs text-gray-500 dark:text-white">
                      {t("GOOD_NEWS")}
                    </span>
                    <p className="max-w-1/2 px-4 text-center text-gray-700 dark:text-white">
                      {t("UPDATING_INFO")}
                    </p>
                  </div>
                ))}

              <div className="flex justify-end p-3">
                <Button
                  appearance="primary"
                  size="lg"
                  onClick={handleCreate}
                  disabled={
                    quickiesLoading ||
                    isCreating ||
                    !Object.keys(selectedItems).length
                  }
                >
                  {t("Crear ahora")}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Quickie;
