import useTranslation from "next-translate/useTranslation";
import { Button, Col } from "rsuite";

interface IModalAndDrawerButtonsProps {
  loading: boolean;
  closeAction: () => void;
  buttonOneText?: string;
  buttonTwoText?: string;
  buttonTwoAction?: () => void;
  buttonTwoDisabled?: boolean;
  buttonOneAction?: () => void;
  buttonTwoStyle?: string;
}

const ModalAndDrawerButtons = ({
  closeAction,
  loading,
  buttonOneText,
  buttonTwoText,
  buttonTwoAction,
  buttonTwoDisabled = false,
  buttonOneAction = closeAction,
  buttonTwoStyle = "",
}: IModalAndDrawerButtonsProps) => {
  const { t } = useTranslation("vacation");

  return (
    <Col md={24} xs={24} className="mb-3 mt-6">
      <div className="flex w-full flex-col items-center gap-2 sm:flex-row sm:justify-end">
        <Button
          appearance="ghost"
          size="lg"
          onClick={buttonOneAction}
          className="w-full dark:border dark:border-gray-200 dark:text-white sm:w-40"
        >
          {buttonOneText || t("Cancelar")}
        </Button>
        <Button
          className={`w-full sm:w-40 ${buttonTwoStyle}`}
          appearance="primary"
          type="submit"
          size="lg"
          loading={loading}
          disabled={loading || buttonTwoDisabled}
          onClick={buttonTwoAction || undefined}
        >
          {buttonTwoText || t("Guardar")}
        </Button>
      </div>
    </Col>
  );
};

export default ModalAndDrawerButtons;
