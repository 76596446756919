import { gql } from "@apollo/client";
import { EFeedbackType } from "types/Feedback.type";

export interface ICreateFeedbackInput {
  comment?: string;
  pathname?: string;
  type: EFeedbackType;
  screenshot?: string;
  terms?: boolean;
}

const CREATE_FEEDBACK = gql`
  mutation CreateFeedback($createFeedbackInput: CreateFeedbackInput!) {
    createFeedback(createFeedbackInput: $createFeedbackInput) {
      user {
        name
      }
    }
  }
`;

export default {
  CREATE_FEEDBACK,
};
