import { gql } from "@apollo/client";

export interface IGetChallengesInput {
  vendor?: string;
}

const GET_CHALLENGES = gql`
  query challenges($getChallengesInput: GetChallengesInput!) {
    challenges(getChallengesInput: $getChallengesInput) {
      id
      isRunning
      name
      image
      description
      tags {
        name
      }
    }
  }
`;

export interface IGetRunningChallengesInput {
  vendor?: string;
}

const GET_RUNNING_CHALLENGES = gql`
  query runningChallenges(
    $getRunningChallengesInput: GetRunningChallengesInput!
  ) {
    runningChallenges(getRunningChallengesInput: $getRunningChallengesInput) {
      id
      challenge {
        name
        image
        dimension {
          id
          name
        }
        tags {
          name
        }
      }
      dateIni
      dateEnd
      participants {
        name
        lastname
        profileImage
      }
      vendor
      finished
    }
  }
`;

const GET_USER_AVAILABLE_CHALLENGES = gql`
  query userAvailableChallenges {
    userAvailableChallenges {
      id
      challenge {
        name
        description
        image
        tags {
          id
          name
        }
      }
      dateIni
      dateEnd
      participants {
        name
        lastname
        profileImage
      }
      vendor
      finished
    }
  }
`;

const GET_QUESTIONS_INDEX = gql`
  query questionsIndex {
    questionsIndex {
      title
      description
      type
      id
      isAvailable
      lastAnswer
      image
    }
  }
`;

export interface IGetStartedChallengeRankingInput {
  startedChallenge: string;
  type: "challenge" | "daily";
}
const GET_STARTED_CHALLENGE_RANKING = gql`
  query startedChallengeRanking(
    $getStartedChallengeRankingInput: GetStartedChallengeRankingInput!
  ) {
    startedChallengeRanking(
      getStartedChallengeRankingInput: $getStartedChallengeRankingInput
    ) {
      ranking {
        user {
          name
          lastname
          profileImage
        }
        ranking
      }
      updatedAt
    }
  }
`;

export default {
  GET_CHALLENGES,
  GET_RUNNING_CHALLENGES,
  GET_USER_AVAILABLE_CHALLENGES,
  GET_QUESTIONS_INDEX,
  GET_STARTED_CHALLENGE_RANKING,
};
