import FooterVersion from "./ui/FooterVersion";
import TagVendor from "./ui/TagVendor";
import WhatIsNew from "./ui/WhatIsNew";

interface SidebarFooterProps {
  readonly render?: boolean;
}

export default function SidebarFooter(props: SidebarFooterProps) {
  const { render = true } = props;

  if (!render) {
    return null;
  }

  return (
    <div className="grid gap-4 border-t bg-sidebar-footer px-4 shadow-custom-top dark:border-gray-600 dark:bg-gray-900">
      {/* Section Vendor */}
      <TagVendor />

      {/* Section What is the New on GroowCity */}
      <WhatIsNew />

      {/* Section GroowCity Version */}
      <FooterVersion />
    </div>
  );
}
