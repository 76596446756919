import { gql } from "@apollo/client";

const ACTION_PLAN_MESSAGE_ADDED = gql`
  subscription actionPlanMessageAdded($actionPlanId: String!) {
    actionPlanMessageAdded(actionPlanId: $actionPlanId) {
      id
      message
      deleted
      createdAt
      user {
        id
        name
        lastname
        profileImage
      }
    }
  }
`;

export default {
  ACTION_PLAN_MESSAGE_ADDED,
};
