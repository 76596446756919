import { WIDTH_MD } from "@constants/global";
import { useWindowWidth } from "@react-hook/window-size";
import Icon from "components/Icon";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import { useMobileSidebarStore } from "stores/sidebar/useMobileSidebarStore";

export default function WhatIsNew() {
  const { isExpanded } = useDesktopSidebarStore();
  const { isOpenBurger } = useMobileSidebarStore();

  const { t } = useTranslation("common");

  const wWidth = useWindowWidth();
  const isMd = wWidth >= WIDTH_MD;
  const [renderText, setRenderText] = useState<boolean>(false);

  useEffect(() => {
    if (isExpanded || isOpenBurger) {
      // SetRenderText to true if isExpanded or isOpenBurger with timeout
      setTimeout(() => {
        setRenderText(true);
      }, 200);
    } else {
      setRenderText(false);
    }
  }, [isExpanded, isOpenBurger, isMd]);

  const handleBeamer = () => {
    // * open beamer .beamerTrigger
    // * Your custom button handler to toggle Beamer
    if (window["Beamer"]) {
      window["Beamer"].toggle();
    }
  };

  return (
    <div
      className="pointer flex cursor-pointer flex-col items-center justify-center border-gray-400 text-gray-800 hover:text-blue-600 dark:border-gray-700 dark:text-gray-400 dark:hover:text-green-600"
      onClick={handleBeamer}
    >
      <div className="flex items-center justify-center align-middle">
        <Icon icon={["far", "history"]} />
        {renderText && (
          <span className="ml-1 text-xs font-semibold">
            {t("Qué hay de nuevo en Groow?")}
          </span>
        )}
      </div>
    </div>
  );
}
