import { useLazyQuery, useMutation } from "@apollo/client";
import { ALLOWED_THUMBNAIL_EXTENSIONS, THEME } from "@constants/global";
import ModalAndDrawerButtons from "components/Button/ModalAndDrawerButtons";
import { useDrawer } from "context/drawer/drawer.provider";
import { gqlFeedback } from "gql";
import { ICreateFeedbackInput } from "gql/Feedback/mutations";
import { useTheme } from "next-themes";
import useTranslation from "next-translate/useTranslation";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Checkbox, Input, Uploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader/Uploader";
import { EFeedbackType, IFeedBack } from "types/Feedback.type";
import useSound from "use-sound";

const ModalFeedback = () => {
  const { asPath } = useRouter();
  const { closeDrawer } = useDrawer();
  const [type, setType] = useState<EFeedbackType>(EFeedbackType.BUG);
  const [playFeedback] = useSound("/sounds/feedback-sound.mp3", {
    volume: 0.25,
  });
  const [terms, setTerms] = useState<boolean>(false);
  const [playError] = useSound("/sounds/denied-sound.mp3", { volume: 0.25 });
  const { t } = useTranslation("common");
  const { theme } = useTheme();
  const [screnshoot, setScrenshoot] = useState<FileType | null>(null);
  const [composeLoading, setComposeLoading] = useState(false);

  const {
    handleSubmit: handleCreateFeedback,
    control: feedbackControl,
    watch,
    formState: { errors },
  } = useForm<ICreateFeedbackInput>();
  const [createFeedback, { loading: mutationLoading }] = useMutation<
    { createFeedback: IFeedBack },
    { createFeedbackInput: ICreateFeedbackInput }
  >(gqlFeedback.mutations.CREATE_FEEDBACK, {
    onCompleted(response) {
      const {
        createFeedback: {
          user: { name },
        },
      } = response;
      playFeedback();
      setComposeLoading(false);
      toast.success(`${t("newFeedback.success")} ${name}`);
      closeDrawer();
    },
    onError(e) {
      toast.error(e.message);
      playError();
    },
  });

  const [signedUrl] = useLazyQuery(gqlFeedback.queries.GENERATE_SIGNED_URL, {
    fetchPolicy: "network-only",
  });

  const handleCreate = async (data: ICreateFeedbackInput) => {
    setComposeLoading(true);
    try {
      let mdUrl = "";

      if (screnshoot) {
        const {
          data: { getSignedUrl: getSignedUrlMd },
        } = await signedUrl({
          variables: {
            key: screnshoot.name,
          },
        });

        const mdUploadResponse = await fetch(getSignedUrlMd, {
          method: "PUT",
          headers: new Headers({
            "Content-Type": screnshoot?.blobFile?.type || "",
          }),
          body: screnshoot?.blobFile,
        });

        if (mdUploadResponse.ok) {
          mdUrl = mdUploadResponse?.url.split("?")[0];
        }
      }

      await createFeedback({
        variables: {
          createFeedbackInput: {
            comment: data.comment,
            pathname: asPath,
            screenshot: mdUrl,
            type,
            terms,
          },
        },
      });
    } catch (error) {
      setComposeLoading(false);
      toast.error(t("newFeedback.error"));
    }
  };

  return (
    <>
      <div className="w-full p-4">
        <p className="mt-1 text-left text-lg font-semibold">
          {t("newFeedback.title")}
        </p>
        <form onSubmit={handleCreateFeedback(handleCreate)} className="pt-4">
          <span className="text-sm font-semibold text-gray-800 dark:text-gray-200">
            {t("newFeedback.selectType")}
          </span>
          <div className="flex justify-between gap-4 pt-1">
            <div
              className={`flex w-1/3 cursor-pointer flex-col rounded-2xl border border-gray-200 p-4 dark:border-gray-600 ${
                type === EFeedbackType.BUG &&
                "border-blue-600 bg-purple-200 dark:border-blue-200 dark:bg-current-500"
              }`}
              onClick={() => setType(EFeedbackType.BUG)}
            >
              <div>
                <Image
                  alt=""
                  src={
                    theme === THEME.dark
                      ? "/svg/illustrations/feedback-problem.svg"
                      : "/svg/illustrations/feedback-problem-light.svg"
                  }
                  height={60}
                  width={60}
                />
              </div>
              <span className="text-xs font-semibold dark:text-gray-100">
                {t("newFeedback.problem")}
              </span>
              <span className="text-xs dark:text-gray-200">
                {t("newFeedback.problemShortDescription")}
              </span>
            </div>

            <div
              className={`flex w-1/3 cursor-pointer flex-col rounded-2xl border border-gray-200 p-4 dark:border-gray-600 ${
                type === EFeedbackType.IDEA &&
                "border-blue-600 bg-purple-200 dark:border-blue-200 dark:bg-current-500"
              }`}
              onClick={() => setType(EFeedbackType.IDEA)}
            >
              <div>
                <Image
                  alt=""
                  src={
                    theme === THEME.dark
                      ? "/svg/illustrations/feedback-idea.svg"
                      : "/svg/illustrations/feedback-idea-light.svg"
                  }
                  height={60}
                  width={60}
                />
              </div>
              <span className="text-xs font-semibold dark:text-gray-100">
                {t("newFeedback.idea")}
              </span>
              <span className="text-xs dark:text-gray-200">
                {t("newFeedback.ideaShortDescription")}
              </span>
            </div>
            <div
              className={`flex w-1/3 cursor-pointer flex-col rounded-2xl border border-gray-200 p-4 dark:border-gray-600 ${
                type === EFeedbackType.OTHER &&
                "border-blue-600 bg-purple-200 dark:border-blue-200 dark:bg-current-500"
              }`}
              onClick={() => setType(EFeedbackType.OTHER)}
            >
              <div>
                <Image
                  alt=""
                  src={
                    theme === THEME.dark
                      ? "/svg/illustrations/feedback-other.svg"
                      : "/svg/illustrations/feedback-other-light.svg"
                  }
                  height={60}
                  width={60}
                />
              </div>
              <span className="text-xs font-semibold dark:text-gray-100">
                {t("newFeedback.other")}
              </span>
              <span className="text-xs dark:text-gray-200">
                {t("newFeedback.otherShortDescription")}
              </span>
            </div>
          </div>

          <Controller
            name="comment"
            control={feedbackControl}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <div className="pt-4">
                <label className="text-left text-sm font-semibold text-gray-800 dark:text-gray-200">
                  {t("newFeedback.question")}
                </label>

                <Input
                  {...field}
                  className="rounded-2xl py-3"
                  placeholder={t("newFeedback.placeholder")}
                  rows={5}
                  as="textarea"
                />

                <small className="text-sm text-gray-600 dark:text-gray-300">
                  {t("newFeedback.questionWarning")}
                </small>

                {errors.comment && (
                  <p className="text-xs italic text-red-500">
                    {t("newFeedback.mandatory")}
                  </p>
                )}
              </div>
            )}
          />

          <div className="pt-6">
            <label className="text-left text-sm font-semibold text-gray-800 dark:text-gray-200">
              {t("newFeedback.screenshootLabel")}
            </label>

            <Uploader
              accept={ALLOWED_THUMBNAIL_EXTENSIONS}
              fileListVisible
              action=""
              draggable
              autoUpload={false}
              fileList={screnshoot ? [screnshoot] : []}
              onChange={(fileList: Array<FileType>) => {
                if (fileList.length) {
                  // * we only store one file per type
                  const file = fileList[fileList.length - 1];
                  setScrenshoot(file);
                } else {
                  setScrenshoot(null);
                }
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "180px",
                  border:
                    theme === THEME.dark
                      ? "1px solid #3c3f43"
                      : "1px solid #e5e5e5",
                  backgroundColor: theme === THEME.dark ? "#121325" : "#fff",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  flexDirection: "column",
                  borderRadius: "20px",
                }}
              >
                <div>
                  <Image
                    alt=""
                    src={
                      theme === THEME.dark
                        ? "/svg/illustrations/upload-icon.svg"
                        : "/svg/illustrations/upload-icon-light.svg"
                    }
                    height={60}
                    width={60}
                  />
                </div>
                <div
                  className="text-center"
                  style={{
                    width: "52%",
                  }}
                >
                  <span className="dark:text-gray-200">
                    <span className="font-semibold text-purple-800">
                      {t("newFeedback.screenshootDescription1")}
                    </span>{" "}
                    {t("newFeedback.screenshootDescription2")}
                  </span>
                </div>
              </div>
            </Uploader>
          </div>

          <div className="flex items-center align-middle">
            <Checkbox checked={terms} onChange={() => setTerms(!terms)}>
              {" "}
              <span className="font-semibold text-gray-800 dark:text-gray-200">
                {t("newFeedback.terms")}
              </span>
            </Checkbox>
          </div>

          <div className="mt-6">
            <ModalAndDrawerButtons
              closeAction={closeDrawer}
              loading={mutationLoading || composeLoading}
              buttonTwoText={t("newFeedback.submit")}
              buttonTwoDisabled={watch("comment") === ""}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default ModalFeedback;
