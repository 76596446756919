import { gql } from "@apollo/client";

const GET_ALL_DIVISIONS = gql`
  query getAllDivision {
    getAllDivision {
      id
      name
      areas {
        id
        name
        avatar
      }
      description
      createdAt
      avatar
      leader {
        id
        name
        lastname
        email
        profileImage
      }
    }
  }
`;

export default { GET_ALL_DIVISIONS };
