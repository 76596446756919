/* eslint-disable no-useless-escape */
import { useRouter } from "next/router";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

const i18nConfig = require("../../../i18n.js"); // Ruta correcta hacia tu archivo i18n.js

interface PreviousPathContextProps {
  previousPath: string;
}

const PreviousPathContext = createContext<PreviousPathContextProps>({
  previousPath: "/",
});

export const usePreviousPath = () => useContext(PreviousPathContext);

export const PreviousPathProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const router = useRouter();
  const intitialPath = router.asPath;
  const [previousPath, setPreviousPath] = useState<string>(intitialPath);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // Remover parámetros de la URL si están presentes
      const cleanURL = url.split("?")[0]; // Obtiene la parte de la URL antes del '?'

      // Si la ruta contiene el idioma, elimina el idioma de la ruta antes de establecerla como ruta anterior
      const languagePaths = i18nConfig.locales; // Aquí obtienes los idiomas definidos
      const regex = new RegExp(`^\/(${languagePaths.join("|")})\/`); // Expresión regular para identificar idiomas en la ruta
      const cleanPath = cleanURL.replace(regex, "/"); // Elimina el idioma de la ruta si está presente

      setPreviousPath(cleanPath);
      // console.log("previousPath = ", previousPath);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // console.log("previousPath = ", previousPath);
  return (
    <PreviousPathContext.Provider value={{ previousPath }}>
      {children}
    </PreviousPathContext.Provider>
  );
};
