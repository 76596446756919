import { gql } from "@apollo/client";
import { IUser } from "types/User.types";

export interface ICreateVacationRequestInput {
  startDate: Date;
  endDate: Date;
  comment?: string;
  assignedReplacement: boolean;
  assignedReplacementId?: string;
}

const CREATE_VACATION_REQUEST = gql`
  mutation createVacationRequest(
    $createUserVacationRequestInput: CreateUserVacationRequestInput!
  ) {
    createUserVacationRequest(
      createUserVacationRequestInput: $createUserVacationRequestInput
    ) {
      id
      startDate
      endDate
      comment
      assignedReplacement
      assignedReplacementId {
        name
        lastname
        email
      }
      status
    }
  }
`;

export interface IUpdateVacationRequestInput
  extends ICreateVacationRequestInput {
  id: string;
  replacement?: IUser;
}

const UPDATE_VACATION_REQUEST = gql`
  mutation UpdateUserVacationRequest(
    $createUserVacationRequestInput: UpdateUserVacationRequestInput!
  ) {
    updateUserVacationRequest(
      createUserVacationRequestInput: $createUserVacationRequestInput
    ) {
      id
      startDate
      endDate
      comment
      assignedReplacement
      assignedReplacementId {
        name
        lastname
        email
      }
      status
    }
  }
`;

export interface IDeleteVacationRequestInput {
  id: string;
}

const DELETE_VACATION_REQUEST = gql`
  mutation DeleteUserVacationRequest($deleteUserVacationRequestId: String!) {
    deleteUserVacationRequest(id: $deleteUserVacationRequestId) {
      id
    }
  }
`;

export interface IChangeStatusVacationRequestInput {
  userVacationRequestId: string;
  leaderApprovalCheck?: boolean;
  hrApprovalCheck?: boolean;
  statusComment?: string;
  assignedReplacement?: boolean;
  assignedReplacementId?: string;
}

const CHANGE_STATUS_VACATION_REQUEST = gql`
  mutation ChangeStatusUserVacationRequest(
    $changeStatusInput: ChangeStatusInput!
  ) {
    changeStatusUserVacationRequest(changeStatusInput: $changeStatusInput) {
      id
      user {
        name
        lastname
        email
      }
      startDate
      endDate
      comment
      assignedReplacement
      assignedReplacementId {
        name
        lastname
        email
      }
      status
      statusComment
    }
  }
`;

const REMIND_VACATION_REQUEST = gql`
  mutation RemindUserVacationRequest($remindUserVacationRequestId: String!) {
    remindUserVacationRequest(id: $remindUserVacationRequestId) {
      id
      user {
        name
        lastname
        email
        adManager {
          name
          lastname
          email
        }
      }
      startDate
      endDate
      remindedAt
    }
  }
`;

export const CREATE_VACATION_CONFIG = gql`
  mutation CreateVacationConfig($createVacationInput: CreateVacationInput!) {
    createVacationConfig(createVacationInput: $createVacationInput) {
      id
    }
  }
`;

export const UPDATE_VACATION_CONFIG = gql`
  mutation UpdateVacationConfig(
    $updateVacationInput: UpdateVacationConfigInput!
  ) {
    updateVacationConfig(updateVacationInput: $updateVacationInput) {
      id
    }
  }
`;

export const DELETE_VACATION_CONFIG = gql`
  mutation DeleteVacationConfig($deleteVacationConfigId: String!) {
    deleteVacationConfig(id: $deleteVacationConfigId) {
      id
    }
  }
`;

export default {
  CREATE_VACATION_REQUEST,
  UPDATE_VACATION_REQUEST,
  DELETE_VACATION_REQUEST,
  CHANGE_STATUS_VACATION_REQUEST,
  REMIND_VACATION_REQUEST,
  CREATE_VACATION_CONFIG,
  UPDATE_VACATION_CONFIG,
  DELETE_VACATION_CONFIG,
};
