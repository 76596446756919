import { gql } from "@apollo/client";

const GET_PLANS = gql`
  query plans {
    plans {
      id
      name
      description
      maxUsers
      pricePerUsers
      currency
      billingType
      trialDays
      active
      features
      visible
      starter
    }
  }
`;

export default { GET_PLANS };
