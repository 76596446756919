import { gql } from "@apollo/client";

const CREATE_DIVISION = gql`
  mutation CreateDivision($createDivisionInput: CreateDivisionInput!) {
    createDivision(createDivisionInput: $createDivisionInput) {
      id
    }
  }
`;

const UPDATE_DIVISION = gql`
  mutation UpdateDivision($updateDivisionInput: UpdateDivisionInput!) {
    updateDivision(updateDivisionInput: $updateDivisionInput) {
      id
    }
  }
`;

const DELETE_DIVISION = gql`
  mutation DeleteDivision($deleteDivisionId: String!) {
    deleteDivision(id: $deleteDivisionId) {
      id
      name
    }
  }
`;

export default { CREATE_DIVISION, UPDATE_DIVISION, DELETE_DIVISION };
