import { IRoute } from "../../../routes/types";

export const LAYOUT_STORED_MENU = "LAYOUT_STORED_MENU";
export const LAYOUT_STORED_SUBMENU = "LAYOUT_STORED_SUBMENU";

export interface IMenuListItem {
  icon: string;
  name: string;
  route?: IRoute;
}

export interface IMenuList {
  header: string;
  items: IMenuListItem[];
}

export interface ISubMenuLists {
  [mainMenuListName: string]: IMenuList[];
}

export const menuItemByPath = (
  lists: IMenuList[],
  path: string
): IMenuListItem | null => {
  let item: IMenuListItem | null = null;

  lists.some((list) =>
    list.items.some((i) => {
      const found = path.includes(i.route?.path);
      if (found) {
        item = i;
      }
      return found;
    })
  );

  return item;
};
