import { gql } from "@apollo/client";
import { IGoal } from "types/Goal.type";

export interface ICreateGoalInput {
  name: string;
  description?: string;
  startDate: Date;
  endDate: Date;
  responsible: string;
}

export interface IDeleteGoalInput {
  id: string;
}

const DELETE_GOAL = gql`
  mutation deleteGoal($deleteGoalInput: DeleteGoalInput!) {
    deleteGoal(deleteGoalInput: $deleteGoalInput)
  }
`;

export interface ICreateGoalRes {
  createGoal: IGoal;
}

const CREATE_GOAL = gql`
  mutation createGoal($createGoalInput: CreateGoalInput!) {
    createGoal(createGoalInput: $createGoalInput) {
      id
      name
    }
  }
`;

export interface IUpdateGoalInput extends ICreateGoalInput {
  id: string;
  completed?: boolean;
}

export interface IUpdateGoalRes {
  updateGoal: IGoal;
}

const UPDATE_GOAL = gql`
  mutation updateGoal($updateGoalInput: UpdateGoalInput!) {
    updateGoal(updateGoalInput: $updateGoalInput) {
      id
    }
  }
`;

export default { CREATE_GOAL, UPDATE_GOAL, DELETE_GOAL };
