import { gql } from "@apollo/client";
import { IProduct } from "types/Product.type";

const GET_PRODUT_CATEGORIES = gql`
  query productCategories {
    productCategories {
      id
      name
      users
    }
  }
`;

const GET_COIN_CATEGORIES = gql`
  query coinCategories {
    coinCategories {
      id
      name
      coins
      default
    }
  }
`;

const GET_COIN_CATEGORIE = gql`
  query coinCategorie($getCoinCategorieInput: GetCoinCategorieInput!) {
    coinCategorie(getCoinCategorieInput: $getCoinCategorieInput) {
      id
      name
    }
  }
`;

const GET_USER_COIN_TRANSACTION = gql`
  query userCoinTransaction($userId: String!) {
    userCoinTransaction(userId: $userId) {
      currentCoins
      coinsLog {
        id
        amount
        date
        transaction
        comment
        coinCategory {
          id
          name
        }
        createdBy {
          name
          lastname
        }
      }
    }
  }
`;

const USER_BASKET = gql`
  query Basket {
    basket {
      id
      user
      basketMemory {
        product {
          id
          name
          price
          category {
            id
            name
          }
          epic
          featured
          newProduct
          imgUrl
          description
          quantity
        }
        quantity
        productVariant
      }
    }
  }
`;

const GET_VENDOR_COINS_TRANSACTION = gql`
  query coinTransactionRequest {
    coinTransactionRequest {
      id
      totalPrice
      createdAt
      state
      basketMemory {
        product {
          id
          name
          imgUrl
          price
          description
          productVariants {
            variant
            price
            quantity
          }
        }
        quantity
        productVariant
      }
      user {
        name
        lastname
        profileImage
        email
        coins
      }
    }
  }
`;

const MARKETPLACE_PRODUCTS = gql`
  query products {
    products {
      id
      name
      imgUrl
      category {
        name
        id
      }
      price
      deleted
      active
      description
      quantity
      newProduct
      featured
      epic
    }
  }
`;

const GET_ADMIN_PRODUCTS = gql`
  query adminProducts {
    adminProducts {
      id
      name
      imgUrl
      category {
        name
        id
      }
      price
      deleted
      active
      description
      quantity
      newProduct
      featured
      epic
    }
  }
`;

const TOP_USERS = gql`
  query TopUsers {
    topUsers {
      id
      name
      profileImage
      lastname
      coins
    }
  }
`;

export interface IGetProductsPaginatedResponse {
  products: IProduct[];
  totalPages: number;
  total: number;
}
export interface IGetProductsInput {
  name?: string;
  limit: number;
  offset: number;
  category?: string[];
  featured?: boolean;
  newProduct?: boolean;
  epic?: boolean;
}

const GET_PRODUCTS_PAGINATED = gql`
  query GetProductsPaginated(
    $getProductsPaginatedInput: GetProductsPaginatedInput!
  ) {
    getProductsPaginated(
      getProductsPaginatedInput: $getProductsPaginatedInput
    ) {
      products {
        id
        name
        epic
        featured
        newProduct
        imgUrl
        active
        category {
          id
          name
        }
        quantity
        price
        description
        productVariants {
          variant
          price
          quantity
        }
      }
      total
      totalPages
    }
  }
`;

const GET_ADMIN_PRODUCTS_PAGINATED = gql`
  query GetAdminProductsPaginated(
    $getAdminProductsPaginatedInput: GetProductsPaginatedInput!
  ) {
    getAdminProductsPaginated(
      getAdminProductsPaginatedInput: $getAdminProductsPaginatedInput
    ) {
      products {
        id
        name
        epic
        featured
        newProduct
        imgUrl
        active
        category {
          id
          name
        }
        quantity
        price
        description
        productVariants {
          variant
          price
          quantity
        }
      }
      total
      totalPages
    }
  }
`;

export default {
  GET_ADMIN_PRODUCTS,
  GET_PRODUT_CATEGORIES,
  GET_COIN_CATEGORIES,
  GET_COIN_CATEGORIE,
  GET_USER_COIN_TRANSACTION,
  GET_VENDOR_COINS_TRANSACTION,
  USER_BASKET,
  MARKETPLACE_PRODUCTS,
  TOP_USERS,
  GET_PRODUCTS_PAGINATED,
  GET_ADMIN_PRODUCTS_PAGINATED,
};
