import dynamic from "next/dynamic";
import { FC, ReactNode } from "react";

const SimpleNavBar = dynamic(
  () => import("components/SimpleNavBar/SimpleNavBar"),
  {
    ssr: false,
  }
);

interface LandingLayoutProps {
  children?: ReactNode;
}

const LandingLayout: FC<LandingLayoutProps> = ({ children }) => (
  <div className="flex h-full w-full flex-col">
    <SimpleNavBar />
    <div className="container mx-auto flex-1">{children}</div>
  </div>
);

export default LandingLayout;
