"use client";

import { WIDTH_MD } from "@constants/global";
import { useEffect, useState } from "react";

export function useIsScreenMd() {
  const pixelBreakpoint = WIDTH_MD;
  const [isScreenMd, setIsScreenMd] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (!hasMounted) return; // Asegura que el código solo se ejecuta en el cliente

    const mediaQuery = window.matchMedia(`(min-width: ${pixelBreakpoint}px)`);
    setIsScreenMd(mediaQuery.matches);

    const handleResize = () => setIsScreenMd(mediaQuery.matches);

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, [hasMounted]);

  return isScreenMd;
}
