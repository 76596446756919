import { create } from "zustand";
import { persist } from "zustand/middleware";

interface DesktopSidebarState {
  isExpanded: boolean;
  toggleSidebar: () => void;
  setIsExpanded: (isExpanded: boolean) => void;
}

export const useDesktopSidebarStore = create<DesktopSidebarState>()(
  persist(
    (set) => ({
      isExpanded: true,
      toggleSidebar: () => set((state) => ({ isExpanded: !state.isExpanded })),
      setIsExpanded: (isExpanded) => set({ isExpanded }),
    }),
    {
      name: "desktop-sidebar-storage", // nombre del almacenamiento en localStorage
    }
  )
);
