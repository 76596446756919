import { gql } from "@apollo/client";
import { ISkill } from "types/Skills.type";
import {
  ECertificationType,
  ETypeCertification,
  EUserSeniority,
  IUser,
  IUserCertification,
} from "types/User.types";

const SIGNUP = gql`
  mutation Signup($signUpInput: CreateUserInput!) {
    signup(signUpInput: $signUpInput) {
      accessToken
      refreshToken
    }
  }
`;

export interface IUpdateUserSeniorityInput {
  user: string;
  seniority: EUserSeniority;
}

const UPDATE_USER_SENIORITY = gql`
  mutation updateUserSeniority(
    $updateUserSeniorityInput: UpdateUserSeniorityInput!
  ) {
    updateUserSeniority(updateUserSeniorityInput: $updateUserSeniorityInput) {
      id
      seniority
    }
  }
`;

export interface IUpdateUserNotificationTokenInput {
  token: string;
}

const UPDATE_USER_NOTIFICATION_TOKEN = gql`
  mutation updateUserNotificationToken(
    $updateUserNotificationTokenInput: UpdateUserNotificationTokenInput!
  ) {
    updateUserNotificationToken(
      updateUserNotificationTokenInput: $updateUserNotificationTokenInput
    )
  }
`;

export interface IUpdateUserScreenTime {
  screenTime: number;
}

const UPDATE_USER_SCREEN_TIME = gql`
  mutation updateUserScreenTime(
    $updateUserScreenTimeInput: UpdateUserScreenTimeInput!
  ) {
    updateUserScreenTime(
      updateUserScreenTimeInput: $updateUserScreenTimeInput
    ) {
      id
    }
  }
`;

export interface IChangePasswordRequestInput {
  email: string;
}

const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $changePasswordRequestInput: ChangePasswordRequestInput!
  ) {
    changePassword(changePasswordRequestInput: $changePasswordRequestInput) {
      id
      name
      email
    }
  }
`;

export interface IChangePasswordInput {
  passwordRecoveryToken: string;
  userId: string;
  password: string;
}

const DO_RESET_PASSWORD = gql`
  mutation DoResetPassword($changePasswordInput: ChangePasswordInput!) {
    doResetPassword(changePasswordInput: $changePasswordInput) {
      id
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation updateProfile($updateProfileInput: UpdateProfileInput!) {
    updateProfile(updateProfileInput: $updateProfileInput) {
      id
      name
      lastname
      linkedin
      instagram
      coverImage
      username
      email
      shortBio
      lang
      birthday
      skills {
        id
        name
      }
      aiCVInfo {
        education {
          dateEnd
          dateStart
          description
          title
        }
        languages {
          level
          name
        }
        tagLine
        workExperience {
          date
          description
          title
          company
        }
        extraWorkExperience {
          date
          description
          title
          company
        }
      }
    }
  }
`;

const UPDATE_USER_PROFILE_AVERAGE_SKILL = gql`
  mutation UpdateProfileWithAverageSkill(
    $updateProfileInput: UpdateProfileInput!
  ) {
    updateProfileWithAverageSkill(updateProfileInput: $updateProfileInput) {
      id
      name
      lastname
      linkedin
      instagram
      email
      shortBio
      lang
      skills {
        id
        name
      }
    }
  }
`;

export interface IUpdateProfileSkillsInput {
  skills: ISkill[];
}

const UPDATE_PROFILE_SKILLS = gql`
  mutation updateProfileSkills(
    $updateProfileSkillsInput: UpdateProfileSkillsInput!
  ) {
    updateProfileSkills(updateProfileSkillsInput: $updateProfileSkillsInput) {
      skills {
        id
      }
    }
  }
`;

export interface IUserNotifyInputInput {
  name: string;
  email: string;
}
export interface IUserNotifyInput {
  text: string;
  to?: string;
  from: string;
  type: string;
}

const USER_NOTIFY = gql`
  mutation userNotify($userNotifyInput: UserNotifyInput!) {
    userNotify(userNotifyInput: $userNotifyInput)
  }
`;

export interface IUpdateUserSelectedVendorInput {}

const UPDATE_USER_SELECTED_VENDOR = gql`
  mutation updateUserSelectedVendor(
    $updateUserSelectedVendorInput: UpdateUserSelectedVendorInput!
  ) {
    updateUserSelectedVendor(
      updateUserSelectedVendorInput: $updateUserSelectedVendorInput
    ) {
      id
    }
  }
`;

const REFRESH_ACCESS_TOKEN = gql`
  mutation refreshAccessToken(
    $refreshAccessTokenInput: RefreshAccessTokenInput!
  ) {
    refreshAccessToken(refreshAccessTokenInput: $refreshAccessTokenInput) {
      accessToken
    }
  }
`;

export interface IAddRoleVendorToUserInput {
  systemRole: string;
  role: string;
  email: string[];
}

const ASSING_ROLE_AND_VENDOR_TO_USER = gql`
  mutation assingRoleAndVendorToUser(
    $addRoleAndVendorToUserInput: AddRoleAndVendorToUserInput!
  ) {
    assingRoleAndVendorToUser(
      addRoleAndVendorToUserInput: $addRoleAndVendorToUserInput
    )
  }
`;

const UPDATE_VENDOR_USER = gql`
  mutation updateVendorUser($updateVendorUserInput: UpdateVendorUserInput!) {
    updateVendorUser(updateVendorUserInput: $updateVendorUserInput) {
      id
    }
  }
`;

const TOGGLE_USER_LEADER = gql`
  mutation toggleUserLeader($userId: String!) {
    toggleUserLeader(userId: $userId) {
      id
    }
  }
`;

export interface ISocialNetworkLogInInput {
  accessToken: string;
}

export interface ISocialNetworkLogInRes {
  accessToken: string;
  refreshToken: string;
}

export const LOGIN_WITH_LINKEDIN = gql`
  mutation loginWithLinkedIn($linkedinLogInInput: SocialNetworkLogInInput!) {
    loginWithLinkedIn(linkedinLogInInput: $linkedinLogInInput) {
      accessToken
    }
  }
`;

export interface IRemoveUserFromVendorInput {
  user: string;
}

export interface IRemoveUserFromVendorRes {
  removeUserFromVendor: IUser;
}

export const REMOVE_USER_FROM_VENDOR = gql`
  mutation removeUserFromVendor(
    $removeUserFromVendorInput: RemoveUserFromVendorInput!
  ) {
    removeUserFromVendor(
      removeUserFromVendorInput: $removeUserFromVendorInput
    ) {
      id
    }
  }
`;

export interface IAddUserCertificationInput {
  name: string;
  expiracyDate?: Date;
  base64Certification?: string;
  certificationUrl?: string;
  type?: ECertificationType;
  certificationType?: ETypeCertification;
  emitDate?: Date;
}

export interface IAddUserCertificationRes {
  addUserCertification: IUserCertification;
}

export const ADD_USER_CERTIFICATION = gql`
  mutation AddUserCertification(
    $addUserCertificationInput: AddUserCertificationInput!
  ) {
    addUserCertification(
      addUserCertificationInput: $addUserCertificationInput
    ) {
      id
    }
  }
`;

export interface IDeleteUserCertificationInput {
  id: string;
}

export interface IDeleteUserCertificationRes {
  deleteUserCertification: IUserCertification;
}

export const DELETE_USER_CERTIFICATION = gql`
  mutation DeleteUserCertification(
    $deleteUserCertificationInput: DeleteUserCertificationInput!
  ) {
    deleteUserCertification(
      deleteUserCertificationInput: $deleteUserCertificationInput
    ) {
      id
    }
  }
`;

export interface IUpdateUserCertificationInput
  extends IAddUserCertificationInput {
  id: string;
}

export interface IUpdateUserCertificationRes {
  updateUserCertification: IUserCertification;
}

export const UPDATE_USER_CERTIFICATION = gql`
  mutation UpdateUserCertification(
    $updateUserCertificationInput: UpdateUserCertificationInput!
  ) {
    updateUserCertification(
      updateUserCertificationInput: $updateUserCertificationInput
    ) {
      id
    }
  }
`;

export interface IMicrosoftLoginResponse {
  accessToken: string;
  refreshToken: string;
}

const LOGIN_WITH_MICROSOFT = gql`
  mutation loginWithMicrosoft($microsoftLogInInput: SocialNetworkLogInInput!) {
    loginWithMicrosoft(microsoftLogInInput: $microsoftLogInInput) {
      accessToken
      refreshToken
    }
  }
`;

const UPDATE_EXTRA_INFO = gql`
  mutation UpdateExtraDataSilently($silentUpdateInput: SilentUpdateInput!) {
    updateExtraDataSilently(silentUpdateInput: $silentUpdateInput)
  }
`;

const CREATE_CV_WITH_AI = gql`
  mutation CreateCVWithAI($createCvWithAiInput: CreateCVWithAIInput!) {
    createCVWithAI(createCVWithAIInput: $createCvWithAiInput) {
      cvUrl
    }
  }
`;

const DEACTIVE_INVITATION = gql`
  mutation DeactivateUserInvitation($invitationId: String!) {
    deactivateUserInvitation(invitationId: $invitationId) {
      id
    }
  }
`;

const RESEND_USER_INVIATION = gql`
  mutation ResendUserInvitation($invitationId: String!) {
    resendUserInvitation(invitationId: $invitationId) {
      id
    }
  }
`;
const UPLOAD_USERS_BY_FILE = gql`
  mutation UploadUsersByFile($uploadUserFile: String!) {
    uploadUsersByFile(uploadUserFile: $uploadUserFile)
  }
`;

export default {
  SIGNUP,
  CHANGE_PASSWORD,
  DO_RESET_PASSWORD,
  UPDATE_USER_PROFILE,
  UPDATE_USER_SELECTED_VENDOR,
  REFRESH_ACCESS_TOKEN,
  ASSING_ROLE_AND_VENDOR_TO_USER,
  TOGGLE_USER_LEADER,
  UPDATE_VENDOR_USER,
  LOGIN_WITH_LINKEDIN,
  UPDATE_USER_SCREEN_TIME,
  REMOVE_USER_FROM_VENDOR,
  ADD_USER_CERTIFICATION,
  UPDATE_USER_CERTIFICATION,
  DELETE_USER_CERTIFICATION,
  UPDATE_USER_NOTIFICATION_TOKEN,
  UPDATE_USER_PROFILE_AVERAGE_SKILL,
  UPDATE_PROFILE_SKILLS,
  UPDATE_USER_SENIORITY,
  USER_NOTIFY,
  LOGIN_WITH_MICROSOFT,
  UPDATE_EXTRA_INFO,
  CREATE_CV_WITH_AI,
  DEACTIVE_INVITATION,
  RESEND_USER_INVIATION,
  UPLOAD_USERS_BY_FILE,
};
