import { ESystemRoles } from "@constants/SystemRoles";

export const guardCheckUserRole = (
  roleGuards: ESystemRoles[],
  userRole: ESystemRoles,
  isi?: boolean
) => {
  if (!roleGuards || roleGuards.length === 0 || isi) return true;

  return roleGuards.length && roleGuards.includes(userRole);
};
