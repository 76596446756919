import { gql } from "@apollo/client";
import { IDimension } from "types/Dimension.type";

export interface IDimensionResponse {
  dimension: IDimension;
}

export interface IDimensionInput {
  dimensionInput: {
    id?: string;
    roles?: string[];
    name?: string;
  };
}

const GET_DIMENSION = gql`
  query dimension($dimensionInput: DimensionInput!) {
    dimension(dimensionInput: $dimensionInput) {
      id
      name
      originalId
      roles {
        id
        name
      }
    }
  }
`;

export interface IDimensionsResponse {
  dimensions: IDimension[];
}

export interface IDimensionsInput {
  roles?: string[];
  ids?: string[];
}

const GET_DIMENSIONS = gql`
  query dimensions($getDimensionsInput: GetDimensionsInput!) {
    dimensions(getDimensionsInput: $getDimensionsInput) {
      id
      isQuickie
      name
      originalId
      associatedRoles {
        id
        name
        maturities {
          id
          name
        }
      }
      roles {
        id
        name
        maturities {
          id
          name
        }
      }
    }
  }
`;

const GET_DIMENSIONS_LIGHT = gql`
  query dimensions($getDimensionsInput: GetDimensionsInput!) {
    dimensions(getDimensionsInput: $getDimensionsInput) {
      id
      name
      originalId
      roles {
        id
        name
      }
    }
  }
`;

export interface IGetDimensionsPaginatedInput {
  offset: number;
  limit: number;
  name?: string;
  roles?: string[];
}

export interface IGetDimensionsPaginatedRes {
  total: number;
  dimensions: IDimension[];
}

const GET_DIMENSIONS_PAGINATED = gql`
  query dimensionsPaginated(
    $getDimensionsPaginatedInput: GetDimensionsPaginatedInput!
  ) {
    dimensionsPaginated(
      getDimensionsPaginatedInput: $getDimensionsPaginatedInput
    ) {
      dimensions {
        id
        isQuickie
        originalId
        name
        tags {
          id
          name
        }
        associatedRoles {
          id
          name
        }
      }
      total
    }
  }
`;

const GET_DIMENSION_PUBLIC = gql`
  query GetDimensionPublic($vendorId: String!) {
    getDimensionPublic(vendorId: $vendorId) {
      id
      name
    }
  }
`;

export default {
  GET_DIMENSION,
  GET_DIMENSIONS,
  GET_DIMENSIONS_PAGINATED,
  GET_DIMENSION_PUBLIC,
  GET_DIMENSIONS_LIGHT,
};
