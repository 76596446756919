import { gqlVendor } from "gql";
import {
  IInsertQuickiesInput,
  IToggleIsQuickieInput,
} from "gql/Vendor/mutations";
import client from "../../settings/apollo";
import { TQuickiesTypes } from "./quickie.types";

export const toggleIsQuickie = async (
  type: TQuickiesTypes,
  id: string,
  isQuickie: boolean
) => {
  const response = await client.mutate<
    { toggleIsQuickie: boolean },
    { toggleIsQuickieInput: IToggleIsQuickieInput }
  >({
    mutation: gqlVendor.mutations.TOGGLE_IS_QUICKIE,
    variables: {
      toggleIsQuickieInput: {
        type,
        id,
        isQuickie,
      },
    },
  });

  return response.data?.toggleIsQuickie;
};

export const insertQuickies = async (type: TQuickiesTypes, ids: string[]) => {
  const response = await client.mutate<
    { insertQuickies: boolean },
    { insertQuickiesInput: IInsertQuickiesInput }
  >({
    mutation: gqlVendor.mutations.INSERT_QUICKIES,
    variables: {
      insertQuickiesInput: {
        type,
        ids,
      },
    },
  });

  return response.data?.insertQuickies;
};
