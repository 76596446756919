import { FC, PropsWithChildren, useContext, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { DrawerContext, IOpenDrawer } from "./drawer.context";

const DrawerProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);
  const [isOpen3, setIsOpen3] = useState<boolean>(false);
  const [isOpen4, setIsOpen4] = useState<boolean>(false);
  const [firstWidth, setFirstWidth] = useState<number>(75);

  const [drawer, setDrawer] = useState<JSX.Element | null>();
  const [drawer2, setDrawer2] = useState<JSX.Element | null>();
  const [drawer3, setDrawer3] = useState<JSX.Element | null>();
  const [drawer4, setDrawer4] = useState<JSX.Element | null>();

  const closeDrawer = () => {
    if (isOpen4) {
      setIsOpen4(false);
      setDrawer4(null);
    } else if (isOpen3) {
      setIsOpen3(false);
      setDrawer3(null);
    } else if (isOpen2) {
      setIsOpen2(false);
      setDrawer2(null);
    } else {
      setIsOpen(false);
      setDrawer(null);
    }

    setFirstWidth(75);
  };

  const setDrawerComponent = (newDrawerComponent: JSX.Element) => {
    setDrawer(newDrawerComponent);
  };

  const setDrawerFirstWidth = (width: number) => {
    setFirstWidth(width);
  };

  const value = useMemo(
    () => ({
      openDrawer: (params: IOpenDrawer) => {
        const { drawerComponent, firstWidth } = params;

        if (!isOpen) {
          setIsOpen(true);
          setDrawer(drawerComponent);
        } else if (isOpen && !isOpen2) {
          setIsOpen2(true);
          setDrawer2(drawerComponent);
        } else if (isOpen2 && !isOpen3) {
          setIsOpen3(true);
          setDrawer3(drawerComponent);
        } else if (isOpen3 && !isOpen4) {
          setIsOpen4(true);
          setDrawer4(drawerComponent);
        } else {
          toast.error("You can only open 4 drawers at a time");
        }

        if (firstWidth) setFirstWidth(firstWidth);
      },
      setDrawerComponent,
      isOpen,
      isOpen2,
      isOpen3,
      isOpen4,
      closeDrawer,
      drawer,
      drawer2,
      drawer3,
      drawer4,
      firstWidth,
      setDrawerFirstWidth,
    }),
    [isOpen, isOpen2, isOpen3, isOpen4, firstWidth]
  );

  return (
    <DrawerContext.Provider {...{ value }}>{children}</DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);

export default DrawerProvider;
