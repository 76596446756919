import { useModal } from "context/modal/modal.provider";
import Image from "next/legacy/image";
import NewFeedback from "./NewFeedback";

const ButtonFeedback = () => {
  const { openModal } = useModal();
  const onModalOpen = () => {
    openModal({
      modalComponent: <NewFeedback />,
      size: "md",
    });
  };

  return (
    <div className="cursor-pointer">
      <Image
        src="/svg/feedback.svg"
        onClick={onModalOpen}
        className="cursor-pointer"
        width={45}
        height={45}
        alt="Feedback"
      />
    </div>
  );
};

export default ButtonFeedback;
