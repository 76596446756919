"use client";

import { isBrowser } from "@constants/global";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { getToken, Messaging, onMessage } from "firebase/messaging";

import { getMessaging } from "firebase/messaging/sw";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDBbk0HSwnVeN6E4JEtvKlI9BReCr0Qmjk",
  authDomain: "nodrize-dev-318416.firebaseapp.com",
  projectId: "nodrize-dev-318416",
  storageBucket: "nodrize-dev-318416.appspot.com",
  messagingSenderId: "149146676650",
  appId: "1:149146676650:web:97f0477bd1605f340e8fd3",
};

// eslint-disable-next-line
const app: FirebaseApp | undefined = isBrowser
  ? initializeApp(firebaseConfig)
  : undefined;
const messaging: Messaging | undefined = isBrowser
  ? getMessaging(app)
  : undefined;

export const requestForToken = () => {
  if (!messaging) {
    console.error("Messaging is not available.");
    return;
  }

  return getToken(messaging, {
    vapidKey:
      "BEZTA3tfBUBznms7wLXVxAkfGZwjpYQeECDI4vWR0rRtdjOT5Km-y_OTksSDg3OGc9EeJ7x1-QFaYCCdEuuAdU8",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      }
      console.error(
        "No registration token available. Request permission to generate one."
      );
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if (!messaging) {
      reject(new Error("Messaging is not available."));
      return;
    }
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
