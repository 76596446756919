import { gql } from "@apollo/client";
import { IRole } from "../../types/Role.type";

export interface ICreateRoleRes {
  createRole: IRole;
}
export interface ICreateRoleInput {
  id: string;
  name: string;
  dimensions?: string[];
  avatar?: string;
  areas?: string[];
}

const CREATE_ROLE = gql`
  mutation createRole($createRoleInput: CreateRoleInput!) {
    createRole(createRoleInput: $createRoleInput) {
      id
      name
      dimensions {
        id
        name
      }
    }
  }
`;

export interface IUpdateRoleInput extends ICreateRoleInput {}

const UPDATE_ROLE = gql`
  mutation updateRole($updateRoleInput: UpdateRoleInput!) {
    updateRole(updateRoleInput: $updateRoleInput) {
      id
      name
      dimensions {
        id
        name
      }
    }
  }
`;

const DELETE_ROLE_BY_ID = gql`
  mutation deleteRole($id: String!) {
    deleteRole(id: $id) {
      id
      name
    }
  }
`;

export default { CREATE_ROLE, UPDATE_ROLE, DELETE_ROLE_BY_ID };
