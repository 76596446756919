import { gql } from "@apollo/client";
import { IArea } from "types/Area.type";

export interface IAreasResponse {
  areas: IArea[];
}

const GET_AREAS = gql`
  query areas {
    areas {
      id
      name
      description
      avatar
      divisions {
        id
        name
        avatar
      }
      leader {
        id
        name
        lastname
        email
        profileImage
      }
      roles {
        id
        name
      }
      users {
        id
      }
      users {
        id
        name
        lastname
        assignable
        assigned
        email
        profileImage
        role {
          id
          name
        }
      }
    }
  }
`;

export default { GET_AREAS };
