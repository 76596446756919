import { gql } from "@apollo/client";
import { IMaturity } from "types/Maturity.type";

export interface IMaturitiesResponse {
  maturities: IMaturity[];
}

export interface IGetMaturitiesInput {
  role: string;
}
const GET_MATURITIES = gql`
  query maturities($getMaturitiesInput: GetMaturitiesInput!) {
    maturities(getMaturitiesInput: $getMaturitiesInput) {
      id
      name
      priority
      avatar
      role
    }
  }
`;

export default { GET_MATURITIES };
