import { LOGO_DARK, LOGO_LIGHT } from "@constants/global";
import ToggleTheme from "components/ToggleTheme/ToggleTheme";
import { useTheme } from "next-themes";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";

const PublicNavBar = () => {
  const { theme } = useTheme();
  const { t } = useTranslation("common");

  return (
    <>
      <div
        className="grid h-min w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white"
        style={{ height: 4, minHeight: 4 }}
      />
      <nav className="sticky top-0 w-full items-center justify-between border-b-[1px] bg-gray-50 pr-4 dark:border-gray-600 dark:bg-gray-900">
        <div className="flex content-center items-center justify-items-stretch">
          <div
            className="-mt-1 grid h-full w-full items-center pl-4"
            style={{
              height: "79px",
            }}
          >
            <Image
              src={theme === "dark" ? LOGO_DARK : LOGO_LIGHT}
              width={180}
              height={60}
              alt="Logo"
              style={{ maxWidth: "154px", height: "auto" }}
              priority
            />
          </div>
          <div className="flex w-full gap-2">
            <div className="text-blueGray-500 grid w-full items-center justify-end py-1 text-center text-sm font-semibold dark:text-white">
              {t("PROFILE_FOOTER")}
            </div>
            <ToggleTheme />
          </div>
        </div>
      </nav>
    </>
  );
};

export default PublicNavBar;
