import { LOGO_ISOTIPO } from "@constants/global";
import ImageLogoFontsDark from "@public/svg/logo/logo-fonts-dark.svg";
import ImageLogoFontsLight from "@public/svg/logo/logo-fonts-light.svg";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { useTheme } from "next-themes";
import Image from "next/image";
import { useRouter } from "next/router";
import { PRIVATE_ROUTES } from "routes/routes";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";

interface LogoGroowProps {
  readonly isImagotipo?: boolean;
  readonly render?: boolean;
}

export default function LogoGroow(props: LogoGroowProps) {
  const router = useRouter();
  const { theme } = useTheme();
  const { isImagotipo = true, render = true } = props;

  const { newUi } = useUserStoreContext((store) => ({
    ...store,
  }));
  const { isExpanded: isExPandedSidebar } = useDesktopSidebarStore();

  function handleRedirect(path: string) {
    router.push(path);
  }

  if (!render) return null;

  return (
    <div className="flex items-center">
      <picture
        style={{
          display: "grid",
          alignContent: "center",
          width: "auto",
          minWidth: isImagotipo ? "34px" : "40px",
          marginLeft: isImagotipo
            ? newUi && isExPandedSidebar
              ? "4px"
              : "24px"
            : newUi
              ? "24px"
              : "22px",
          transition: "margin-left 0.3s ease-in-out",
        }}
      >
        <Image
          className="cursor-pointer"
          src={LOGO_ISOTIPO}
          width={40}
          height={40}
          onClick={() => handleRedirect(PRIVATE_ROUTES.profile.path)}
          alt="logo"
        />
      </picture>
      {isImagotipo && (
        <picture
          style={{
            position: "relative",
            display: "grid",
            alignContent: "center",
            width: "100%",
            height: "40px",
            minWidth: "130px",
            marginLeft: "8px",
          }}
        >
          <Image
            className="absolute top-2 cursor-pointer"
            src={theme === "dark" ? ImageLogoFontsDark : ImageLogoFontsLight}
            width={
              theme === "dark"
                ? ImageLogoFontsDark.width
                : ImageLogoFontsLight.width
            }
            height={
              theme === "dark"
                ? ImageLogoFontsDark.height
                : ImageLogoFontsLight.height
            }
            onClick={() => handleRedirect(PRIVATE_ROUTES.profile.path)}
            alt="logo fonts"
          />
        </picture>
      )}
    </div>
  );
}
