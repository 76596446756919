import { gql } from "@apollo/client";
import {
  EFormDistributionType,
  EFormQuestionType,
  IForm,
  IFormAnswer,
  IFormQuestion,
  IFormQuestionOption,
  IFormResponseResponse,
  ISendFormResponse,
} from "types/Form.type";

export interface ICreateFormInput {
  id?: string;
  name: string;
  description?: string;
  distributionType?: EFormDistributionType;
  questions: IFormQuestion[];
  hidden?: boolean;
  enableSentimentAnalysis?: boolean;
  thumbnail?: string | undefined;
  isAnonymous?: boolean;
}

export interface ICreateFormRes {
  createForm: IForm;
}

const CREATE_FORM = gql`
  mutation CreateForm($createFormInput: CreateFormInput!) {
    createForm(createFormInput: $createFormInput) {
      id
    }
  }
`;

export interface IUpdateFormInput {
  id: string;
  name?: string;
  description?: string;
}

export interface IUpdateFormRes {
  updateForm: IForm;
}

const UPDATE_FORM = gql`
  mutation UpdateForm($editFormInput: EditFormInput!) {
    updateForm(editFormInput: $editFormInput) {
      id
      name
      description
      distributed
    }
  }
`;

const DELETE_FORM = gql`
  mutation DeleteForm($deleteFormId: String!) {
    deleteForm(id: $deleteFormId) {
      id
      name
      deleted
    }
  }
`;

const MARK_FORM_AS_DISTRIBUTED = gql`
  mutation MarkAsFinished($markAsFinishedId: String!) {
    markAsFinished(id: $markAsFinishedId) {
      id
      name
      description
      distributed
    }
  }
`;

export interface ICreateFormQuestionInput {
  form: string;
  name: string;
  type: EFormQuestionType;
  formQuestionOptions?: IFormQuestionOption[];
}

export interface ICreateFormQuestionRes {
  createFormQuestion: IFormQuestion;
}

const CREATE_FORM_QUESTION = gql`
  mutation CreateFormQuestion(
    $createFormQuestionInput: CreateFormQuestionInput!
  ) {
    createFormQuestion(createFormQuestionInput: $createFormQuestionInput) {
      id
      name
      formQuestionOptions {
        id
        name
        question
      }
    }
  }
`;

export interface IUpdateFormQuestionInput {
  id: string;
  name?: string;
  type?: EFormQuestionType;
  formQuestionOptions?: IFormQuestionOption[];
}

export interface IUpdateFormQuestionRes {
  updateFormQuestion: IFormQuestion;
}

const EDIT_FORM_QUESTION = gql`
  mutation UpdateFormQuestion($editFormQuestionInput: EditFormQuestionInput!) {
    updateFormQuestion(editFormQuestionInput: $editFormQuestionInput) {
      id
      name
      formQuestionOptions {
        id
        name
        question
      }
    }
  }
`;

export interface ICreateFormSendInput {
  users: string[];
  emails: string[];
  form: string;
}

export interface ICreateFormSendRes {
  createFormSend: ISendFormResponse;
}

const CREATE_FORM_SEND = gql`
  mutation CreateFormSend($createFormSendInput: CreateFormSendInput!) {
    createFormSend(createFormSendInput: $createFormSendInput)
  }
`;

export interface IUpdateFormResponseInput {
  id?: string;
  email?: string;
  form: string;
  user?: string;
  answers: IFormAnswer[];
}

export interface IUpdateFormResponseRes {
  updateFormResponse: IFormResponseResponse;
}

const UPDATE_FORM_RESPONSE = gql`
  mutation UpdateFormResponse(
    $updateFormResponseInput: UpdateFormResponseInput!
  ) {
    updateFormResponse(updateFormResponseInput: $updateFormResponseInput) {
      id
    }
  }
`;

export interface ICreateFormAIInput {
  description: string;
  questions?: string[];
  language?: string;
}

const CREATE_FORM_AI = gql`
  mutation CreateFormWithAi($createFormWithAiInput: CreateFormWithAIInput!) {
    createFormWithAi(createFormWithAIInput: $createFormWithAiInput) {
      id
    }
  }
`;

export interface ICreateFormQuestionWithAIInput {
  form: string;
  type: EFormQuestionType;
  // hidden?: boolean;
}

const CREATE_FORM_QUESTION_WITH_AI = gql`
  mutation CreateFormQuestionWithAI(
    $createFormQuestionWithAiInput: CreateFormQuestionWithAIInput!
  ) {
    createFormQuestionWithAI(
      createFormQuestionWithAIInput: $createFormQuestionWithAiInput
    ) {
      form
      id
      name
    }
  }
`;

export interface ICreateFormQuestionWithAIRes {
  id: string;
  name: string;
  form: string;
}

export interface ICreateFormSendByAreaInput {
  form: string;
  areaIds: string[];
}

const CREATE_FORM_SEND_AREA = gql`
  mutation SendByArea($sendByAreaInput: CreateFormSendByAreaInput!) {
    sendByArea(sendByAreaInput: $sendByAreaInput)
  }
`;

export interface ICreateFormSendByDivisionInput {
  form: string;
  divisionIds: string[];
}

const CREATE_FORM_SEND_DIVISION = gql`
  mutation SendFormByDivision(
    $createFormSendByDivisionInput: CreateFormSendByDivisionInput!
  ) {
    sendFormByDivision(
      createFormSendByDivisionInput: $createFormSendByDivisionInput
    )
  }
`;

export interface ICreateFormSendAllInput {
  form: string;
}

const CREATE_FORM_SEND_ALL = gql`
  mutation SendFormToAll($createFormSendAllInput: CreateFormSendAllInput!) {
    sendFormToAll(createFormSendAllInput: $createFormSendAllInput)
  }
`;

export interface ICreateFormSendByTeamsInput {
  form: string;
  teamIds: string[];
}

const CREATE_FORM_SEND_TEAMS = gql`
  mutation SendFormByTeams(
    $createFormSendByTeamsInput: CreateFormSendByTeamsInput!
  ) {
    sendFormByTeams(createFormSendByTeamsInput: $createFormSendByTeamsInput)
  }
`;

const CLONE_FORM = gql`
  mutation CloneForm($cloneFormId: String!) {
    cloneForm(id: $cloneFormId) {
      id
    }
  }
`;

const FRIENDLY_SURVEY_REMINDER = gql`
  mutation FriendlySurveyReminder($friendlySurveyReminderId: String!) {
    friendlySurveyReminder(id: $friendlySurveyReminderId) {
      id
    }
  }
`;

export default {
  CREATE_FORM,
  UPDATE_FORM,
  DELETE_FORM,
  MARK_FORM_AS_DISTRIBUTED,
  CREATE_FORM_QUESTION,
  EDIT_FORM_QUESTION,
  CREATE_FORM_SEND,
  UPDATE_FORM_RESPONSE,
  CREATE_FORM_AI,
  CREATE_FORM_QUESTION_WITH_AI,
  CREATE_FORM_SEND_AREA,
  CREATE_FORM_SEND_DIVISION,
  CREATE_FORM_SEND_ALL,
  CREATE_FORM_SEND_TEAMS,
  CLONE_FORM,
  FRIENDLY_SURVEY_REMINDER,
};
