import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
// import CommandPalette from "react-command-palette";
import { useLazyQuery } from "@apollo/client";
import { THEME, isBrowser } from "@constants/global";
import { ESystemRoles } from "@constants/SystemRoles";
import CommandPalette, {
  JsonStructure,
  filterItems,
  getItemIndex,
  useHandleOpenCommandPalette,
} from "@tmikeladze/react-cmdk";
import "@tmikeladze/react-cmdk/dist/cmdk.css";
import { APP_VERSION } from "components/Roadmap/ItemPathNode/constants";
import UserAvatar from "components/User/UserAvatar";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { useVendor } from "context/vendor/vendor.provider";
import { gqlUser } from "gql";
import { IVendorUserListResponse } from "gql/User/queries";
import { useTheme } from "next-themes";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";
import { PRIVATE_ROUTES } from "routes/routes";
import { Loader } from "rsuite";
import { IUser } from "types/User.types";
import useDebounce from "utils/hooks/useDebounce";

const CommandPallete = () => {
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));
  const { t } = useTranslation("common");
  const router = useRouter();
  const [page, setPage] = useState<"root" | "users">("root");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const { theme } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>();
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const { vendor } = useVendor();

  const [getActiveUsers] = useLazyQuery<IVendorUserListResponse>(
    gqlUser.queries.GET_VENDOR_USER_LIST_LIGHT,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ vendorUserList }) => {
        if (vendorUserList) {
          setLoading(false);
          setUsers(vendorUserList);
        } else {
          setUsers([]);
        }
      },
    }
  );

  const handleSearchTerm = (input: string) => {
    if (input.length > 1) {
      setLoading(true);
      setSearchTerm(input);
    } else {
      setLoading(false);
      if (input.length === 0) setUsers([]);
    }
  };

  const GenerateIcon = (icon: string) => (
    <Image src={`/svg/menu/${icon}.svg`} width={20} height={20} alt={icon} />
  );

  const handleSearch = (input: string) => {
    setSearch(input);

    if (page === "users") {
      handleSearchTerm(input);
    } else if (user && vendor) {
      const structure = [
        {
          heading: t("Shorcuts"),
          id: "manager",
          items: [
            {
              id: "search-users",
              children: t("Buscar usuarios"),
              icon: GenerateIcon("user-search"),
              closeOnSelect: false,
              onClick: () => {
                setPage("users");
              },
            },
            [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
              user?.systemRole
            ) && {
              id: "users",
              children: t("Ir a usuarios"),
              icon: GenerateIcon("users"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.userMaintainer.path);
              },
            },
            [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
              user?.systemRole
            ) && {
              id: "evaluations",
              children: t("Ir a evaluaciones"),
              icon: GenerateIcon("appreciation"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.appreciationList.path);
              },
            },
            [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
              user?.systemRole
            ) && {
              id: "areas",
              children: t("Ir a areas"),
              icon: GenerateIcon("areas"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.areaMaintainer.path);
              },
            },
            [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
              user?.systemRole
            ) && {
              id: "skills",
              children: t("Ir a habilidades"),
              icon: GenerateIcon("skills"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.dimensionMaintainer.path);
              },
            },
            [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
              user?.systemRole
            ) && {
              id: "roles",
              children: t("Ir a cargos"),
              icon: GenerateIcon("roles"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.roleMaintainer.path);
              },
            },
            [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
              user?.systemRole
            ) && {
              id: "teams",
              children: t("Ir a equipos"),
              icon: GenerateIcon("team"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.teamsLeader.path);
              },
            },
            user?.systemRole === ESystemRoles.ADMIN && {
              id: "radar",
              children: t("Ir a radar"),
              icon: GenerateIcon("radar"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.radar.path);
              },
            },
            vendor?.enableCoins && {
              id: "marketplace",
              children: t("Ir a marketplace"),
              icon: GenerateIcon("avatar-coin"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.myAppreciations.path);
              },
            },
            vendor?.enableActionPlans && {
              id: "action-plan",
              children: t("Ir a plan de acción"),
              icon: GenerateIcon("action-plan"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.actionPlans.path);
              },
            },
            {
              id: "my-evaluations",
              children: t("Ir a mis evaluaciones"),
              icon: GenerateIcon("test"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.myAppreciations.path);
              },
            },
            vendor?.enableActionPlans && {
              id: "my-action-plans",
              children: t("Ir a mis planes de acción"),
              icon: GenerateIcon("my-action-plan"),
              type: "button",
              onClick: () => {
                router.push(PRIVATE_ROUTES.userActionPlans.path);
              },
            },
            vendor?.enableJobyfine &&
              (user?.jobyfineAdmin ||
                user?.jobyfineRecruiter ||
                user?.jobyfineRequester) && {
                id: "jobyfine",
                children: t("Ir a Jobyfine"),
                icon: GenerateIcon("jobyfine"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.jobyfineApplicants.path);
                },
              },
          ],
        },
      ];

      setFilteredItems(
        // @ts-ignore: Ignorar error de tipo para esta línea
        filterItems(structure?.filter(Boolean) as JsonStructure, input)
      );
    }
  };

  useEffect(() => {
    // * check if the url is app.gwoocity.com or app.groowcity.com in order to
    // * avoid the command palette to be shown in the login page
    if (
      isBrowser &&
      window.location.hostname !== "app.gwoocity.com" &&
      window.location.hostname !== "app.groowcity.com"
    ) {
      if (user && vendor) {
        const structure = [
          {
            heading: t("Atajos"),
            id: "manager",
            items: [
              {
                id: "search-users",
                children: t("Buscar usuarios"),
                icon: GenerateIcon("user-search"),
                closeOnSelect: false,
                onClick: () => {
                  setPage("users");
                },
              },
              [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
                user?.systemRole
              ) && {
                id: "users",
                children: t("Ir a usuarios"),
                icon: GenerateIcon("users"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.userMaintainer.path);
                },
              },
              [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
                user?.systemRole
              ) && {
                id: "evaluations",
                children: t("Ir a evaluaciones"),
                icon: GenerateIcon("appreciation"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.appreciationList.path);
                },
              },
              [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
                user?.systemRole
              ) && {
                id: "teams",
                children: t("Ir a equipos"),
                icon: GenerateIcon("team"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.teamsLeader.path);
                },
              },
              user?.systemRole === ESystemRoles.ADMIN && {
                id: "radar",
                children: t("Ir a radar"),
                icon: GenerateIcon("radar"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.radar.path);
                },
              },
              vendor?.enableCoins && {
                id: "marketplace",
                children: t("Ir a marketplace"),
                icon: GenerateIcon("avatar-coin"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.myAppreciations.path);
                },
              },
              vendor?.enableActionPlans && {
                id: "action-plan",
                children: t("Ir a plan de acción"),
                icon: GenerateIcon("action-plan"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.actionPlans.path);
                },
              },
              {
                id: "my-evaluations",
                children: t("Ir a mis evaluaciones"),
                icon: GenerateIcon("test"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.myAppreciations.path);
                },
              },
              [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
                user?.systemRole
              ) && {
                id: "areas",
                children: t("Ir a areas"),
                icon: GenerateIcon("areas"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.areaMaintainer.path);
                },
              },
              [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
                user?.systemRole
              ) && {
                id: "skills",
                children: t("Ir a habilidades"),
                icon: GenerateIcon("skills"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.dimensionMaintainer.path);
                },
              },
              [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
                user?.systemRole
              ) && {
                id: "roles",
                children: t("Ir a cargos"),
                icon: GenerateIcon("roles"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.roleMaintainer.path);
                },
              },
              [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(
                user?.systemRole
              ) && {
                id: "certificates",
                children: t("Ir a certificados"),
                icon: GenerateIcon("skills"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.certificates.path);
                },
              },
              vendor?.enableActionPlans && {
                id: "my-action-plans",
                children: t("Ir a mis planes de acción"),
                icon: GenerateIcon("my-action-plan"),
                type: "button",
                onClick: () => {
                  router.push(PRIVATE_ROUTES.userActionPlans.path);
                },
              },
              vendor?.enableJobyfine &&
                (user?.jobyfineAdmin ||
                  user?.jobyfineRecruiter ||
                  user?.jobyfineRequester) && {
                  id: "jobyfine",
                  children: t("Ir a Jobyfine"),
                  icon: GenerateIcon("jobyfine"),
                  type: "button",
                  onClick: () => {
                    router.push(PRIVATE_ROUTES.jobyfineApplicants.path);
                  },
                },
            ],
          },
        ];

        setFilteredItems(
          // @ts-ignore: Ignorar error de tipo para esta línea
          filterItems(structure?.filter(Boolean) as JsonStructure, search)
        );
      }
    }
  }, [user, vendor]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      getActiveUsers({
        variables: {
          vendorUserListInput: {
            searchText: searchTerm,
          },
        },
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
      setPage("root");
    }
  }, [isOpen]);

  useHandleOpenCommandPalette(setIsOpen);

  // * PORTAL: Career
  if (!user) return null;

  return (
    <CommandPalette
      onChangeSearch={handleSearch}
      onChangeOpen={setIsOpen}
      search={search}
      isOpen={isOpen}
      page={page}
      commandPaletteContentClassName={theme === THEME.dark ? "dark" : undefined}
      footer={
        <div className="flex justify-between" style={{ paddingInline: "1rem" }}>
          <span className="text-xs">Groowcity Commands</span>
          <span className="text-xs">{APP_VERSION}</span>
        </div>
      }
    >
      <CommandPalette.Page id="root">
        {filteredItems.length ? (
          filteredItems.map((list) => (
            <CommandPalette.List key={list.id} heading={list.heading}>
              {list.items.map(({ id, onClick, children, type }) => (
                <CommandPalette.ListItem
                  key={id}
                  index={getItemIndex(filteredItems, id)}
                  onClick={onClick}
                  type={type}
                >
                  {children}
                </CommandPalette.ListItem>
              ))}
            </CommandPalette.List>
          ))
        ) : (
          <CommandPalette.FreeSearchAction />
        )}
      </CommandPalette.Page>
      <CommandPalette.Page
        searchPrefix={["Users"]}
        id="users"
        onEscape={() => {
          setPage("root");
        }}
      >
        <CommandPalette.List>
          {!loading &&
            users?.map((user, index) => (
              <CommandPalette.ListItem
                key={user.id}
                index={index}
                onClick={() => {
                  router.push(`/profile/${user?.id}?vendorId=${vendor?.id}`);
                }}
              >
                <div className="flex items-center">
                  <UserAvatar {...{ user, showName: false, size: 40 }} />
                  <div className="align-center ml-3 flex flex-col justify-start">
                    <span className="capitalize">
                      {user.name} {user.lastname}
                    </span>
                    <p className="block text-xs capitalize text-gray-800 dark:text-gray-300">
                      {user.role?.name || "-"}
                    </p>
                    <p className="block text-xs lowercase text-gray-600 dark:text-gray-200">
                      {user.email}
                    </p>
                  </div>
                </div>
              </CommandPalette.ListItem>
            ))}

          {loading && (
            <Loader
              className="py-4"
              content={t("Buscando entre los colaboradores")}
            />
          )}

          {!loading && users?.length === 0 && (
            <div className="flex items-center py-4">
              <span className="text-xs">
                {t("No se encontraron resultados")}
              </span>
            </div>
          )}
        </CommandPalette.List>
      </CommandPalette.Page>
    </CommandPalette>
  );
};

export default CommandPallete;
