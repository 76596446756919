import { gql } from "@apollo/client";
import { EEndorsementTypeEnum, IEndorsement } from "types/Endorsement.type";

export interface ICreateEndorsementInput {
  skillId: string;
  skilledUserId: string;
  comment?: string;
  type?: EEndorsementTypeEnum;
}

export interface ICreateEndorsementResponse {
  addNewEndorsement: IEndorsement;
}

const ADD_NEW_ENDORSEMENT = gql`
  mutation addNewEndorsement($createEndorsementInput: CreateEndorsementInput!) {
    addNewEndorsement(createEndorsementInput: $createEndorsementInput) {
      id
    }
  }
`;

export interface IDeleteEndorsementInput {
  endorsementId: string;
}

const DELETE_ENDORSEMENT = gql`
  mutation deleteEndorsement($deleteEndorsementInput: DeleteEndorsementInput!) {
    deleteEndorsement(deleteEndorsementInput: $deleteEndorsementInput) {
      id
    }
  }
`;

export default { ADD_NEW_ENDORSEMENT, DELETE_ENDORSEMENT };
