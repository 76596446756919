import { create } from "zustand";

interface IAppStore {
  isInitialized: boolean | null;
  initialize: () => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const useAppStore = create<IAppStore>((set) => ({
  isLoading: false,
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  isInitialized: false,
  initialize: () => set({ isInitialized: true }),
}));

export default useAppStore;
