import { gql } from "@apollo/client";
import { ICertificate } from "types/ICertificate";
import { IUser } from "types/User.types";

export interface IGetUserCertificatesByCertificateIdResponse {
  getUserCertificatesByCertificateId: ICertificateAssignation[];
}

export interface ICertificateAssignation {
  user: IUser;
  issueDate: string;
  deleted: boolean;
  id: string;
}

export const GET_ASSIGNMENTS = gql`
  query GetUserCertificatesByCertificateId($certificateId: String!) {
    getUserCertificatesByCertificateId(certificateId: $certificateId) {
      user {
        name
        lastname
        profileImage
        email
      }
      id
      issueDate
      deleted
    }
  }
`;

export interface IGetAllUserCertificate {
  getAllUserCertificate: ICertificateByUser[];
}

export interface ICertificateByUser {
  issueDate: string;
  id: string;
  certificateHeader: ICertificate;
}

export const MY_CERTIFICATES = gql`
  query GetAllUserCertificate {
    getAllUserCertificate {
      issueDate
      id
      certificateHeader {
        certificateLabel
        customImage
        deleted
        learningOutcomes
        name
        subTitle
        startDate
        allowViewCourse
        footerTextTitle
        durationText
        id
      }
    }
  }
`;

export interface IPublicUserCertificateDetail {
  allowViewCourse: boolean;
  badgeImage: string;
  certificateLabel: string;
  certificateName: string;
  certificateSubtitle: string;
  durationText: string;
  footerTitle: string;
  issueDate: Date;
  learningOutcomes: string[];
  profileImage: string;
  startDate: string;
  userLastName: string;
  userName: string;
  vendorProfileImage: string;
  courseUrl: string;
  certificateUserId: string;
}

export interface IPublicUserCertificateResponse {
  publicUserCertificate: IPublicUserCertificateDetail;
}

export const PUBLIC_USER_CERTIFICATE = gql`
  query PublicUserCertificate($userCertificateId: String!) {
    publicUserCertificate(userCertificateId: $userCertificateId) {
      allowViewCourse
      badgeImage
      certificateLabel
      certificateName
      certificateSubtitle
      durationText
      footerTitle
      issueDate
      learningOutcomes
      profileImage
      startDate
      userLastName
      userName
      vendorProfileImage
      courseUrl
      certificateUserId
    }
  }
`;

export default {
  GET_ASSIGNMENTS,
  MY_CERTIFICATES,
  PUBLIC_USER_CERTIFICATE,
};
