import { gql } from "@apollo/client";

export enum AnonymousCommentCategoryEnum {
  CLAIM = "Reclamo",
  COMMENTARY = "Comentario",
  CONGRATULATION = "Felicitación",
  COUNSELOR = "Clever Counselor",
}

export interface ICreateAnonymousCommentInput {
  category: string;
  comment: string;
  isAnonymous?: boolean;
}

const CREATE_ANONYMOUS_COMMENT = gql`
  mutation createAnonymousComment(
    $createAnonymousCommentInput: CreateAnonymousCommentInput!
  ) {
    createAnonymousComment(
      createAnonymousCommentInput: $createAnonymousCommentInput
    ) {
      id
      tracing
    }
  }
`;

export interface ICreateAnonymousAnswerInput {
  commentId: string;
  answer: string;
  isAdmin: boolean;
}

const CREATE_ANONYMOUS_ANSWER = gql`
  mutation createAnonymousAnswer(
    $createAnonymousAnswerInput: CreateAnonymousAnswerInput!
  ) {
    createAnonymousAnswer(
      createAnonymousAnswerInput: $createAnonymousAnswerInput
    ) {
      id
    }
  }
`;

const DELETE_ANONYMOUS_COMMENT = gql`
  mutation DeleteAnonymousComment($deleteAnonymousCommentId: String!) {
    deleteAnonymousComment(id: $deleteAnonymousCommentId) {
      id
    }
  }
`;

const REMOVE_ANONYMOUS = gql`
  mutation removeAnonymous($tracingId: String!) {
    removeAnonymous(tracingId: $tracingId) {
      id
    }
  }
`;

export default {
  CREATE_ANONYMOUS_COMMENT,
  CREATE_ANONYMOUS_ANSWER,
  DELETE_ANONYMOUS_COMMENT,
  REMOVE_ANONYMOUS,
};
