import { USER_LANG } from "@constants/global";
import setLanguage from "next-translate/setLanguage";
import useTranslation from "next-translate/useTranslation";
import { SelectPicker } from "rsuite";

export enum EAvailableLanguages {
  es = "es",
  en = "en",
}
interface IAvailableLenguages {
  value: EAvailableLanguages;
  label: string;
}

interface IToggleLangProps {
  className?: string;
  onToggle?: (newLang: EAvailableLanguages) => void;
}

const ToggleLang = (props: IToggleLangProps) => {
  const { onToggle } = props;
  const { lang, t } = useTranslation("common");

  const currentLang = localStorage.getItem(USER_LANG);

  if (currentLang !== lang) {
    localStorage.setItem(USER_LANG, lang);
  }

  const changeLanguage = (lang: EAvailableLanguages) => {
    if (onToggle) {
      onToggle(lang);
    } else {
      localStorage.setItem(USER_LANG, lang);
      setLanguage(lang, false);
    }
  };

  const availableLanguages: IAvailableLenguages[] = [
    { value: EAvailableLanguages.es, label: t("Español") },
    { value: EAvailableLanguages.en, label: t("Inglés") },
  ];

  return (
    <>
      {lang && (
        <SelectPicker
          data={availableLanguages}
          defaultValue={lang}
          onChange={changeLanguage}
          cleanable={false}
          searchable={false}
          placement="bottomEnd"
          {...props}
        />
      )}
    </>
  );
};

export default ToggleLang;
