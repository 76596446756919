import { gql } from "@apollo/client";

export interface ICreateChallengeInput {
  name: string;
  image: string;
  description: string;
  duration: number;
  dimension: string;
  tags: string[];
  vendor?: string;
}

const CREATE_CHALLENGE = gql`
  mutation createChallenge($createChallengeInput: CreateChallengeInput!) {
    createChallenge(createChallengeInput: $createChallengeInput) {
      id
    }
  }
`;

export interface IStartVendorChallengeInput {
  challenge: string;
  dateIni: Date;
}

const START_VENDOR_CHALLENGE = gql`
  mutation startVendorChallenge(
    $startVendorChallengeInput: StartVendorChallengeInput!
  ) {
    startVendorChallenge(
      startVendorChallengeInput: $startVendorChallengeInput
    ) {
      id
    }
  }
`;

export interface IUserStartChallengeInput {
  startedChallenge: string;
}

const USER_START_CHALLENGE = gql`
  mutation userStartChallenge(
    $userStartChallengeInput: UserStartChallengeInput!
  ) {
    userStartChallenge(userStartChallengeInput: $userStartChallengeInput) {
      id
    }
  }
`;

export interface ICancelStartedChallengeInput {
  startedChallenge: string;
}

const CANCEL_STARTED_CHALLENGE = gql`
  mutation CancelStartedChallenge(
    $cancelStartedChallengeInput: CancelStartedChallengeInput!
  ) {
    cancelStartedChallenge(
      cancelStartedChallengeInput: $cancelStartedChallengeInput
    )
  }
`;

export default {
  CREATE_CHALLENGE,
  START_VENDOR_CHALLENGE,
  USER_START_CHALLENGE,
  CANCEL_STARTED_CHALLENGE,
};
