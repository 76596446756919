import SVG from "components/SVG";
import { ReactElement } from "react";
import { PRIVATE_ROUTES } from "../../routes/routes";

export enum EPrivateRouteType {
  ITEM = "SINGLE",
  DROPDOWN = "DROPDOWN",
}
export interface IPrivateRoute {
  name: string;
  path?: any;
  icon: ReactElement;
  type: EPrivateRouteType;
  children?: Omit<IPrivateRoute, "children">[];
  hash?: string;
}

export const PRIVATE_ROUTE: IPrivateRoute[] = [
  {
    name: "Mi perfil",
    path: PRIVATE_ROUTES.profile,
    icon: <SVG src="/svg/my-space.svg" width={30} height={30} />,
    type: EPrivateRouteType.ITEM,
    hash: "RESUME",
  },
  {
    name: "Mis equipos",
    path: PRIVATE_ROUTES.teamsLeader,
    icon: <SVG src="/svg/team.svg" width={30} height={30} />,
    type: EPrivateRouteType.ITEM,
  },
  {
    name: "Mis evaluaciones",
    path: PRIVATE_ROUTES.myAppreciations,
    icon: <SVG src="/svg/test.svg" width={30} height={30} />,
    type: EPrivateRouteType.ITEM,
  },
  {
    name: "Configuración",
    icon: <SVG src="/svg/config.svg" width={30} height={30} />,
    type: EPrivateRouteType.DROPDOWN,
    children: [
      {
        name: "Appreciations",
        path: PRIVATE_ROUTES.appreciationList,
        icon: <SVG src="/svg/appreciation.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Appreciations Preselección",
        path: PRIVATE_ROUTES.publicAppreciation,
        icon: <SVG src="/svg/appreciation.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Roles",
        path: PRIVATE_ROUTES.roleMaintainer,
        icon: <SVG src="/svg/identity.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Dimensiones",
        path: PRIVATE_ROUTES.dimensionMaintainer,
        icon: <SVG src="/svg/skills.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Usuarios",
        path: PRIVATE_ROUTES.userMaintainer,
        icon: <SVG src="/svg/users.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Equipos",
        path: PRIVATE_ROUTES.teamMaintainer,
        icon: <SVG src="/svg/people.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Material multimedia",
        path: PRIVATE_ROUTES.multimediaMaintainer,
        icon: <SVG src="/svg/multimedia.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Carreras",
        path: PRIVATE_ROUTES.coursesMaintainer,
        icon: <SVG src="/svg/education.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Elementos de carrera",
        path: PRIVATE_ROUTES.itemPathMantainers,
        icon: <SVG src="/svg/list.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Planes de acción",
        path: PRIVATE_ROUTES.actionPlans,
        icon: <SVG src="/svg/admin-action-plans.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Actividades",
        path: PRIVATE_ROUTES.activities,
        icon: <SVG src="/svg/activity.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Preguntas",
        path: PRIVATE_ROUTES.questionMantainers,
        icon: <SVG src="/svg/question.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
      {
        name: "Sitio",
        path: PRIVATE_ROUTES.configMantainer,
        icon: <SVG src="/svg/config.svg" width={30} height={30} />,
        type: EPrivateRouteType.ITEM,
      },
    ],
  },
];
