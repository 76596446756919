import { gql } from "@apollo/client";
import { ISkill } from "types/Skills.type";

export interface ISkillResponse {
  skills: ISkill[];
}

const SKILLS = gql`
  query skills {
    skills {
      id
      name
      category
    }
  }
`;

const SKILLS_WITH_CATEGORY = gql`
  query skillsWithCategory {
    skillsWithCategory {
      id
      name
      category
    }
  }
`;

export interface IGetUsersByVendorAndQuadrantInput {
  skillsIds?: string[];
  isUserAssigned?: boolean;
  isUserAssignable?: boolean;
}

const USERS_BY_VENDOR_AND_QUADRANT = gql`
  query UsersByVendoAndQuadrant(
    $usersByVendorAndQuadrantInput: UsersByVendorAndQuadrantInput!
  ) {
    usersByVendoAndQuadrant(
      usersByVendorAndQuadrantInput: $usersByVendorAndQuadrantInput
    ) {
      user {
        id
        profileImage
        email
        name
        lastname
        role {
          name
        }
      }
      description
      ring
      quadrant
    }
  }
`;

export default {
  SKILLS,
  USERS_BY_VENDOR_AND_QUADRANT,
  SKILLS_WITH_CATEGORY,
};
