import { useWindowSize } from "@react-hook/window-size";
import Icon from "components/Icon";
import { ISidebarMenu } from "./Sidebar";

interface ISubMenuDynamic extends ISidebarMenu {
  isMenuOpen: boolean;
  setIsSubMenuOpen: (isOpen: boolean) => void;
}

const SubMenuDynamic = (props: ISubMenuDynamic) => {
  const { isMenuOpen, setIsSubMenuOpen } = props;

  const [windowX] = useWindowSize();
  const isMobile = windowX < 768;

  return (
    <div
      className="flex h-full w-full flex-col bg-gray-100 dark:bg-gray-800"
      style={{ pointerEvents: "auto" }}
    >
      <div className="flex-1" />

      {!isMobile && (
        <div className="flex justify-end p-5">
          <Icon
            icon={isMenuOpen ? "arrow-left" : "arrow-right"}
            onClick={() => setIsSubMenuOpen(!isMenuOpen)}
            className="cursor-pointer"
          />
        </div>
      )}
    </div>
  );
};

export default SubMenuDynamic;
