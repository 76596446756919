import { gql } from "@apollo/client";
import { IGoal } from "types/Goal.type";

export interface IGetGoalsResponse {
  goals: IGoal[];
}

export const GET_GOALS = gql`
  query goals {
    goals {
      id
      name
      completed
      createdAt
      endDate
      responsible
      startDate
      updatedAt
      vendor
      description
    }
  }
`;

export interface IGetGoalInput {
  id: string;
}

const GET_GOAL = gql`
  query goal($getGoalInput: GetGoalInput!) {
    goal(getGoalInput: $getGoalInput) {
      id
      name
      description
      completed
      createdAt
      endDate
      responsible
      startDate
      updatedAt
      vendor
      tasks {
        id
        name
        type
        subType
        completedPercent
        createdAt
        updatedAt
        completed
        currentValue
        description
        currency
        value
        minValue
        complianceType
        refIds {
          id
          name
          type
        }
        links
        goal
        user
      }
    }
  }
`;

export default { GET_GOALS, GET_GOAL };
