import { gql } from "@apollo/client";
import { IApplicant, IOffers, IProcess, IRequest } from "types/Jobyfine.type";

export interface IGetApplicantsInput {
  page: number;
  pageSize: number;
  textInput: string;
  tags?: string[];
  profiles?: string[];
}

export interface IGetApplicantsResponse {
  getApplicantsPagination: {
    data: IApplicant[];
    total: number;
    totalPages: number;
  };
}

const GET_APPLICANTS_PAGINATED = gql`
  query GetApplicantsPagination($getApplicantsInput: GetApplicantsInput!) {
    getApplicantsPagination(getApplicantsInput: $getApplicantsInput) {
      data {
        id
        name
        lastname
        email
        cvName
        cvUrl
        incomeExpectation
        incomeExpectationCurrency
        locationId
        postulations
        country
        birthday
        phone
        rut
        region
        tags
        profiles
        englishLvl
        roles {
          role
          vendorId
        }
        notes {
          id
          note
          date
          createdBy
        }
        processes {
          id
          offer {
            id
            name
          }
        }
        aiCVInfo {
          ofuscatedName
          education {
            dateEnd
            dateStart
            description
            title
          }
          extraEducation {
            dateEnd
            dateStart
            description
            title
          }
          languages {
            level
            name
          }
          tagLine
          techSkills
          workExperience {
            date
            description
            title
            company
          }
          extraWorkExperience {
            date
            description
            title
            company
          }
          certifications {
            name
            date
          }
        }
      }
      total
      totalPages
    }
  }
`;

export interface IGetAllOfferInput {
  searchText: string;
  state?: string;
}

export interface IGetAllOfferRes {
  getOffers: IOffers[];
}

const PUBLIC_OFFERS = gql`
  query GetOffersByHostname {
    getOffersByHostname {
      id
      name
      vancancy
      contractType
      country
      publishedAt
      area {
        id
        name
      }
    }
  }
`;

const PUBLIC_VENDOR = gql`
  query VendorByHostname {
    vendorByHostname {
      id
      jobyfinePortalDescription
      jobyfinePortalSlogan
      jobyfinePortalTitle
      logo
      name
      address {
        address
      }
    }
  }
`;

const GET_OFFERS = gql`
  query getOffers($getAllOffersInput: GetAllOffersInput!) {
    getOffers(getAllOffersInput: $getAllOffersInput) {
      id
      name
      description
      vancancy
      fake
      contractType
      country
      client {
        id
        name
      }
      area {
        id
        name
      }
      createdAt
      image
      state
      stateHistory {
        oldState
        newState
        date
      }
      typeOffer
      publicEnd
      processOffer {
        id
        label
        state
      }
      requests {
        id
        name
      }
      maxSalary
      currency
      definition
      tags
      profiles
      recruiter
      requester {
        id
        name
      }
    }
  }
`;

const GET_OFFERS_LIGHT = gql`
  query getOffers($getAllOffersInput: GetAllOffersInput!) {
    getOffers(getAllOffersInput: $getAllOffersInput) {
      id
      name
      state
      client {
        id
        name
      }
    }
  }
`;

const GET_JOBYFINE_REQUESTER = gql`
  query JobyfineRequesters {
    jobyfineRequesters {
      id
      name
      lastname
      profileImage
      email
    }
  }
`;

const GET_JOBYFINE_RECRUITER = gql`
  query JobyfineRecruiters {
    jobyfineRecruiters {
      id
      name
      lastname
      profileImage
      email
    }
  }
`;

export interface IGetAllRequest {
  getAllRequest: IRequest[];
}

const GET_ALL_REQUESTS = gql`
  query GetAllRequest {
    getAllRequest {
      id
      name
      createdAt
      contractType
      type
      crmId
      opportunityMargin
      deadline
      files {
        name
        blob64
        size
      }
      description
      maxRent
      currency
      area {
        id
        name
      }
      creator {
        id
        name
        email
        lastname
        profileImage
      }
      assignedRecruiter {
        id
        name
        email
        lastname
        profileImage
      }
      offers {
        id
        name
        description
        vancancy
        fake
        client {
          id
          name
        }
        area {
          id
          name
        }
        createdAt
        image
        state
        stateHistory {
          oldState
          newState
          date
        }
        typeOffer
        publicEnd
        processOffer {
          id
          label
          state
        }
        requests {
          id
          name
        }
        maxSalary
        currency
        definition
        requester {
          id
          name
        }
        recruiter
      }
    }
  }
`;

const GET_OFFERS_BY_ID = gql`
  query getOfferById($id: String!) {
    getOfferById(id: $id) {
      id
      name
      description
      vancancy
      country
      client {
        id
        name
      }
      area {
        id
        name
      }
      createdAt
      image
      state
      typeOffer
      publicEnd
      processOffer {
        id
        label
        state
      }
      maxSalary
      currency
      tags
      recruiter
      requester {
        id
        name
      }
      vendor {
        id
        jobyfinePortalTitle
      }
    }
  }
`;

export interface IGetAllProcessByIdOfferInputRes {
  getAllProcessByIdOffer: IProcess[];
}
export interface IGetAllProcessByIdOfferInput {
  getAllProcessByIdOfferInput: {
    offerId: string;
  };
}

const GET_ALL_PROCESS = gql`
  query getAllProcessByIdOffer(
    $getAllProcessByIdOfferInput: GetAllProcessByIdOfferInput!
  ) {
    getAllProcessByIdOffer(
      getAllProcessByIdOfferInput: $getAllProcessByIdOfferInput
    ) {
      id
      offerId
      status
      offer {
        id
        name
      }
      user {
        id
        name
        lastname
        rut
        country
        email
        cvName
        cvUrl
        incomeExpectation
        incomeExpectationCurrency
        locationId
        region
        tags
        postulations
        phone
        englishLvl

        processes {
          userId
          offerId
          fitByCV {
            justifications
            score
          }
        }

        aiCVInfo {
          ofuscatedName
          tagLine
        }

        notes {
          id
          note
          date
          createdBy
        }
        processes {
          id
          offer {
            id
            name
          }
        }
      }
    }
  }
`;

const GET_FULL_APPLICANT = gql`
  query GetApplicant {
    getApplicant {
      id
      name
      password
      email
      active
      networkType
      lastname
      profileImage
      address
      locationId
      remoteWork
      incomeExpectation
      rut
      phone
      birthday
      createdAt
      updatedAt
      cvUrl
      cvName
      postulations
      cvUploaded
      preferencesCheck
      country
      incomeExpectationCurrency
      tags
      profiles
    }
  }
`;

const GET_APPLICANT_BY_ID = gql`
  query GetApplicantById($getApplicantByIdId: String!) {
    getApplicantById(id: $getApplicantByIdId) {
      id
      name
      lastname
      email
      cvName
      cvUrl
      incomeExpectation
      incomeExpectationCurrency
      locationId
      postulations
      country
      birthday
      phone
      rut
      region
      tags
      profiles
      englishLvl
      roles {
        role
        vendorId
      }
      notes {
        id
        note
        date
        createdBy
      }
      processes {
        id
        offer {
          id
          name
        }
      }
      aiCVInfo {
        ofuscatedName
        education {
          dateEnd
          dateStart
          description
          title
        }
        extraEducation {
          dateEnd
          dateStart
          description
          title
        }
        languages {
          level
          name
        }
        tagLine
        techSkills
        workExperience {
          date
          description
          title
          company
        }
        extraWorkExperience {
          date
          description
          title
          company
        }
        certifications {
          name
          date
        }
      }
    }
  }
`;

const GET_OFFERS_PAGINATED = gql`
  query GetOffersPagination(
    $getOfferPaginationInput: GetOfferPaginationInput!
  ) {
    getOffersPagination(getOfferPaginationInput: $getOfferPaginationInput) {
      total
      totalPages
      data {
        id
        name
        description
        vancancy
        fake
        contractType
        country
        client {
          id
          name
        }
        area {
          id
          name
        }
        createdAt
        image
        state
        stateHistory {
          oldState
          newState
          date
        }
        typeOffer
        publicEnd
        processOffer {
          id
          label
          state
        }
        requests {
          id
          name
        }
        maxSalary
        currency
        definition
        tags
        profiles
        recruiter
        requester {
          id
          name
        }
      }
    }
  }
`;

const FIT_APPLICANT_TO_OFFER = gql`
  query OfferFitWithApplicant($offerId: String!, $applicantId: String!) {
    offerFitWithApplicant(offerId: $offerId, applicantId: $applicantId) {
      justifications
      score
    }
  }
`;

export default {
  GET_OFFERS,
  GET_APPLICANTS_PAGINATED,
  GET_JOBYFINE_RECRUITER,
  GET_JOBYFINE_REQUESTER,
  GET_ALL_REQUESTS,
  GET_OFFERS_BY_ID,
  GET_ALL_PROCESS,
  PUBLIC_VENDOR,
  PUBLIC_OFFERS,
  GET_FULL_APPLICANT,
  GET_APPLICANT_BY_ID,
  GET_OFFERS_LIGHT,
  GET_OFFERS_PAGINATED,
  FIT_APPLICANT_TO_OFFER,
};
