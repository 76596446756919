import { gql } from "@apollo/client";
import { IActionPlan, IActionPlanMessage } from "types/ActionPlan.type";

export interface IGetAllActionResponse {
  actionPlans: IActionPlan[];
}

export interface IGetAllActionPlanInput {
  userId?: string;
  appreciation?: string;
  appreciationDistribute?: string;
  tags?: string[];
}

export interface IUserMention {
  id: string;
  display: string;
}

export interface IGetActionPlanPaginatedInput {
  members?: string[];
  dimension?: string;
  tags?: string[];
  activity: string;
  limit: number;
  offset: number;
}

export interface IGetActionPlanPaginatedResponse {
  getActionPlanPaginated: {
    actionPlan: IActionPlan[];
    total: number;
    totalPages: number;
  };
}

const GET_ALL_ACTION_PLANS = gql`
  query actionPlans($getAllActionPlanInput: GetAllActionPlanInput!) {
    actionPlans(getAllActionPlanInput: $getAllActionPlanInput) {
      id
      activity
      startDate
      endDate
      status
      numberOfComments
      members {
        id
        name
        lastname
        email
      }
      responsible {
        id
        name
        lastname
        email
        profileImage
      }
      dimension {
        id
        name
      }
      tags {
        id
        name
      }
    }
  }
`;

export interface IGetAllActionPlanMessageInput {
  actionPlanId?: string;
}

export interface IGetAllActionPlanMessageResponse {
  actionPlanMessages: IActionPlanMessage[];
}

const GET_ALL_ACTION_PLAN_MESSAGES = gql`
  query actionPlanMessages(
    $getAllActionPlanMessageInput: GetAllActionPlanMessageInput!
  ) {
    actionPlanMessages(
      getAllActionPlanMessageInput: $getAllActionPlanMessageInput
    ) {
      id
      message
      deleted
      createdAt
      user {
        id
        name
        lastname
        profileImage
      }
    }
  }
`;

const GET_ACTION_PLANS_STATS = gql`
  query ActionPlanStats($getActionPlanStatsInput: GetActionPlanStatsInput!) {
    actionPlanStats(getActionPlanStatsInput: $getActionPlanStatsInput) {
      achieve
      unAchieve
      onTheWay
    }
  }
`;

const GET_ACTION_PLANS_PAGINATED = gql`
  query GetActionPlanPaginated(
    $getActionPlanPaginatedInput: GetActionPlanPaginatedInput!
  ) {
    getActionPlanPaginated(
      getActionPlanPaginatedInput: $getActionPlanPaginatedInput
    ) {
      actionPlan {
        id
        activity
        dimension {
          id
          name
        }
        endDate
        members {
          email
          id
          lastname
          name
        }
        numberOfComments
        responsible {
          email
          id
          lastname
          name
          profileImage
        }
        startDate
        status
        tags {
          id
          name
        }
        deleted
      }
      total
      totalPages
    }
  }
`;

export default {
  GET_ALL_ACTION_PLANS,
  GET_ALL_ACTION_PLAN_MESSAGES,
  GET_ACTION_PLANS_STATS,
  GET_ACTION_PLANS_PAGINATED,
};
