import Avatar from "boring-avatars";
import Image from "next/image";
import LegacyImage from "next/legacy/image";

import { AVATAR_COLORS, DEFAULT_AVATAR } from "@constants/global";
import { Tooltip, Whisper } from "rsuite";
import { IUserParticipation } from "types/Question/DailyQuestionStats";
import { IUser } from "types/User.types";

export interface IUserAvatarProps {
  user?: Partial<IUser> | IUserParticipation;
  showName?: boolean;
  showVendor?: boolean;
  size?: number;
  boringVariant?: "marble" | "beam" | "pixel" | "sunset" | "ring" | "bauhaus";
  tooltip?: boolean;
  nameVariant?: "default" | "row";
  date?: string;
}

const UserAvatar = (props: IUserAvatarProps) => {
  const {
    user,
    showName = false,
    showVendor = false,
    size = 50,
    boringVariant = "beam",
    tooltip = false,
    nameVariant = "default",
    date,
  }: IUserAvatarProps = props;

  if (!tooltip)
    return (
      <>
        {user && nameVariant === "default" && (
          <div className="relative flex items-center gap-1 rounded-full border dark:border-gray-600">
            {user?.profileImage &&
            (user?.profileImage !== DEFAULT_AVATAR || !user?.profileImage) ? (
              <>
                <div
                  className="rounded-full border border-gray-50 bg-cover bg-center dark:border-white"
                  style={{ width: size, height: size }}
                >
                  <LegacyImage
                    src={user?.profileImage}
                    quality={70}
                    width={size}
                    height={size}
                    className="rounded-full"
                    alt="User Avatar"
                  />
                </div>
                {showName && (
                  <div className="flex flex-col">
                    <span className="block capitalize">
                      {user?.name?.toLocaleLowerCase()}
                    </span>
                    <span className="block capitalize">
                      {user?.lastname?.toLocaleLowerCase()}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="relative" style={{ width: size, height: size }}>
                  <div
                    className="user-avatar-boring absolute inset-0 z-0 mr-2 rounded-full border bg-white bg-cover bg-center text-black dark:bg-gray-900 dark:text-white"
                    style={{
                      width: size,
                      height: size,
                      borderRadius: "100%",
                    }}
                  >
                    <Avatar
                      size={size}
                      name={`${user?.name} ${user?.lastname}`}
                      variant="beam"
                      colors={AVATAR_COLORS}
                    />
                  </div>
                </div>
                {showName && (
                  <div className="flex flex-col pr-2">
                    <span className="block capitalize">
                      {user?.name?.toLocaleLowerCase()}{" "}
                      {user?.lastname?.toLocaleLowerCase()}
                    </span>
                    {showVendor && (
                      <span className="text-xs text-gray-400">
                        {user?.selectedVendor?.name}
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {user && nameVariant === "row" && (
          <>
            {user?.profileImage &&
            (user?.profileImage !== DEFAULT_AVATAR || !user?.profileImage) ? (
              <div className="flex gap-2">
                <div
                  className="rounded-full border border-gray-50 bg-cover bg-center dark:border-white"
                  style={{ width: `${size}px`, height: `${size}px` }}
                >
                  <picture
                    className={"relative overflow-x-hidden rounded-full"}
                    style={{
                      display: "grid",
                      width: `${size}px`,
                      height: `${size}px`,
                    }}
                  >
                    <Image
                      src={user?.profileImage}
                      alt="avatar"
                      sizes={`${size}px`}
                      fill
                      style={{ objectFit: "cover" }}
                    />
                  </picture>
                </div>
                {showName && (
                  <div className="flex flex-col justify-center">
                    <span className="block font-semibold capitalize">
                      {user?.name} {user?.lastname}
                    </span>
                    {date !== "Fecha inválida" && (
                      <span className="text-xs">{date}</span>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="flex gap-2">
                <div className="relative" style={{ width: size, height: size }}>
                  <div
                    className="user-avatar-boring absolute inset-0 z-0 mr-2 rounded-full border bg-white bg-cover bg-center text-black dark:bg-gray-900 dark:text-white"
                    style={{
                      width: size,
                      height: size,
                      borderRadius: "100%",
                    }}
                  >
                    <Avatar
                      size={size}
                      name={`${user?.name} ${user?.lastname}`}
                      variant="beam"
                      colors={AVATAR_COLORS}
                    />
                  </div>
                </div>
                {showName && (
                  <div className="flex flex-col justify-center">
                    <span className="block font-semibold capitalize">
                      {user?.name} {user?.lastname}
                    </span>
                    <span className="text-xs">{date}</span>
                    {showVendor && (
                      <span className="text-xs text-gray-400">
                        {user?.selectedVendor?.name}
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </>
    );

  return (
    <Whisper
      disabled={!tooltip}
      speaker={
        <Tooltip className="text-gray-800 dark:text-white">{`${user?.name} ${user?.lastname}`}</Tooltip>
      }
      placement="auto"
    >
      {user ? (
        <div className="relative flex items-center gap-1">
          {user?.profileImage &&
          (user?.profileImage !== DEFAULT_AVATAR || !user?.profileImage) ? (
            <>
              <div
                className="rounded-full border border-gray-50 bg-gray-900 bg-cover bg-center dark:border-gray-900 dark:bg-white"
                style={{ width: size, height: size }}
              >
                <LegacyImage
                  src={user?.profileImage}
                  quality={100}
                  width={size}
                  height={size}
                  className="rounded-full"
                  alt={`${user?.name} ${user?.lastname}`}
                />
              </div>
              {showName && (
                <div className="flex flex-col">
                  <span className="block capitalize">{user?.name}</span>
                  <span className="block capitalize">{user?.lastname}</span>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="relative" style={{ width: size, height: size }}>
                <div
                  className="absolute inset-0 z-0 mr-2 rounded-full border border-gray-500 bg-current-600 bg-white bg-cover bg-center text-black dark:bg-gray-900 dark:text-white"
                  style={{ width: size, height: size }}
                >
                  <Avatar
                    size={size}
                    name={`${user?.name?.charAt(0)?.toUpperCase() || ""}${
                      user?.lastname?.charAt(0)?.toUpperCase() || ""
                    }`}
                    colors={AVATAR_COLORS}
                    variant={boringVariant}
                  />
                </div>
              </div>
              {showName && (
                <div className="flex flex-col">
                  <span className="block capitalize">
                    {user?.name} {user?.lastname}
                  </span>
                  {showVendor && (
                    <span className="text-xs text-gray-400">
                      {user?.selectedVendor?.name}
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="relative flex items-center gap-1">
          <div
            className="rounded-full border border-gray-50 bg-gray-900 bg-cover bg-center dark:border-gray-900 dark:bg-white"
            style={{ width: size, height: size }}
          >
            <span>Unknown</span>
          </div>
        </div>
      )}
    </Whisper>
  );
};

export default UserAvatar;
