import { gql } from "@apollo/client";

const NOTIFICATION_ADDED = gql`
  subscription notificationCreated($user: String!) {
    notificationCreated(user: $user) {
      id
      read
      user
    }
  }
`;

export default { NOTIFICATION_ADDED };
