import { gql } from "@apollo/client";

const ANONYMOUSE_COMMENTS = gql`
  query AnonymousComments {
    anonymousComments {
      id
      category
      comment
      email
      tracing
      createdAt
      state
      userIdAssigned {
        id
        name
        lastname
        profileImage
        email
      }
      answers {
        id
        answer
        email
        userName
        userId
        createdAt
        isAdmin
      }
    }
  }
`;

const ANONYMOUSE_COMMENTS_BY_ID = gql`
  query anonymousCommentsById($anonymousCommentsByIdId: String!) {
    anonymousCommentsById(id: $anonymousCommentsByIdId) {
      id
      category
      comment
      email
      tracing
      createdAt
      answers {
        id
        answer
        email
        userName
        userId
        createdAt
        isAdmin
      }
    }
  }
`;

const ANONYMOUSE_COMMENTS_BY_TRACING = gql`
  query anonymousCommentsByTracing($tracing: String!) {
    anonymousCommentsByTracing(tracing: $tracing) {
      id
      category
      comment
      email
      tracing
      createdAt
      answers {
        id
        answer
        email
        userName
        userId
        createdAt
        isAdmin
      }
    }
  }
`;

export default {
  ANONYMOUSE_COMMENTS,
  ANONYMOUSE_COMMENTS_BY_ID,
  ANONYMOUSE_COMMENTS_BY_TRACING,
};
