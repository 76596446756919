import { gql } from "@apollo/client";
import { IFeedBack } from "../../types/Feedback.type";

export interface IGetFeedback {
  id: string;
}

export interface IFeedback {
  feedback: IFeedBack;
}

const GET_FEEDBACK = gql`
  query feedback($id: String!) {
    feedback(id: $id) {
      id
      userId
      comment
      url
    }
  }
`;

const GENERATE_SIGNED_URL = gql`
  query Query($key: String!) {
    getSignedUrl(key: $key)
  }
`;

export interface IFeedbacks {
  feedbacks: IFeedBack[];
}

const GET_FEEDBACKS = gql`
  query feedbacks {
    feedbacks {
      id
      user {
        id
        email
        name
        lastname
        profileImage
      }
      type
      screenshot
      comment
      pathname
      vendor {
        name
        id
      }
    }
  }
`;

export default {
  GET_FEEDBACK,
  GET_FEEDBACKS,
  GENERATE_SIGNED_URL,
};
