import { gql } from "@apollo/client";
import {
  EActionPlan,
  IActionPlan,
  IActionPlanMessage,
} from "types/ActionPlan.type";

export interface ICreateActionPlanInput {
  activity: string;
  responsible?: string;
  dimension?: string;
  startDate: Date;
  endDate: Date;
  members?: string[];
  appreciation?: string;
  appreciationDistribute?: string;
  tags: string[];
}

export interface ICreateActionPlanResponse {
  createActionPlan: IActionPlan;
}

const CREATE_ACTION_PLAN = gql`
  mutation createActionPlan($createActionPlanInput: CreateActionPlanInput!) {
    createActionPlan(createActionPlanInput: $createActionPlanInput) {
      id
      activity
      status
    }
  }
`;

export interface IUpdateActionPlanInput {
  id: string;
  activity: string;
  responsible?: string;
  dimension?: string;
  startDate: Date;
  endDate: Date;
  members?: string[];
  status?: EActionPlan;
  tags?: string[];
}

export interface IUpdateActionPlanResponse {
  updateActionPlan: IActionPlan;
}

const UPDATE_ACTION_PLAN = gql`
  mutation updateActionPlan($updateActionPlanInput: UpdateActionPlanInput!) {
    updateActionPlan(updateActionPlanInput: $updateActionPlanInput) {
      id
      status
    }
  }
`;

export interface IDeleteActionPlanInput {
  id: string;
}

export interface IDeleteActionPlanResponse {
  deleteActionPlan: IActionPlan;
}

const DELETE_ACTION_PLAN = gql`
  mutation deleteActionPlan($deleteActionPlanInput: DeleteActionPlanInput!) {
    deleteActionPlan(deleteActionPlanInput: $deleteActionPlanInput) {
      id
      status
      deleted
    }
  }
`;

export interface IUpdateActionPlanStatusInput {
  id: string;
  status: EActionPlan;
}

export interface IUpdateActionPlanStatusResponse {
  updateActionPlanStatus: IActionPlan;
}

const UPDATE_ACTION_PLAN_STATUS = gql`
  mutation updateActionPlanStatus(
    $updateActionPlanStatusInput: UpdateActionPlanStatusInput!
  ) {
    updateActionPlanStatus(
      updateActionPlanStatusInput: $updateActionPlanStatusInput
    ) {
      id
      status
      startDate
      endDate
    }
  }
`;

export interface IAddMessageToActionPlanInput {
  actionPlanId: string;
  message: string;
  mentions?: string[];
}

export interface IAddMessageToActionPlanResponse {
  addMessageToActionPlan: IActionPlanMessage;
}

const ADD_MESSAGE_TO_ACTION_PLAN = gql`
  mutation addMessageToActionPlan(
    $addMessageToActionPlanInput: AddMessageToActionPlanInput!
  ) {
    addMessageToActionPlan(
      addMessageToActionPlanInput: $addMessageToActionPlanInput
    ) {
      id
      message
      deleted
      createdAt
      user {
        id
        name
        lastname
        profileImage
      }
    }
  }
`;

export interface IDeleteMessageInActionPlanInput {
  actionPlanId: string;
  messageId: string;
}

export interface IDeleteMessageInActionPlanResponse {
  deleteMessageInActionPlan: IActionPlanMessage;
}

const DELETE_MESSAGE_IN_ACTION_PLAN = gql`
  mutation deleteMessageInActionPlan(
    $deleteMessageInActionPlanInput: DeleteMessageInActionPlanInput!
  ) {
    deleteMessageInActionPlan(
      deleteMessageInActionPlanInput: $deleteMessageInActionPlanInput
    ) {
      id
      message
    }
  }
`;

export interface IUpdateMessageInActionPlanResponse {
  updateMessageInActionPlan: IActionPlanMessage;
}

const UPDATE_MESSAGE_IN_ACTION_PLAN = gql`
  mutation updateMessageInActionPlan(
    $updateMessageInActionPlanInput: UpdateMessageInActionPlanInput!
  ) {
    updateMessageInActionPlan(
      updateMessageInActionPlanInput: $updateMessageInActionPlanInput
    ) {
      id
      message
      deleted
      createdAt
      user {
        id
        name
        lastname
        profileImage
      }
    }
  }
`;

export interface IUpdateMessageInActionPlanInput {
  actionPlanId: string;
  messageId: string;
  message: string;
  mentions?: string[];
}

export default {
  CREATE_ACTION_PLAN,
  UPDATE_ACTION_PLAN,
  DELETE_ACTION_PLAN,
  UPDATE_ACTION_PLAN_STATUS,
  ADD_MESSAGE_TO_ACTION_PLAN,
  DELETE_MESSAGE_IN_ACTION_PLAN,
  UPDATE_MESSAGE_IN_ACTION_PLAN,
};
