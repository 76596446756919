import {
  CSSProperties,
  cloneElement,
  useCallback,
  useEffect,
  useState,
} from "react";

type TStatus = "opened" | "closed" | "moving";
type TMotion = "opening" | "closing" | "static";

export interface ISidebarMenu {
  status?: TStatus;
  motion?: TMotion;
}

interface ISiderbar {
  staticMenu: JSX.Element;
  dynamicMenu: JSX.Element;
  isOpen?: boolean;
  style?: CSSProperties;
  staticMenuStyle?: CSSProperties;
  dynamicMenuStyle?: CSSProperties;
  className?: string;
  id?: string;
  autoClose?: boolean;
  autoOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onOpenStart?: () => void;
  onCloseStart?: () => void;
}

const Sidebar = (props: ISiderbar) => {
  const {
    staticMenu,
    dynamicMenu,
    isOpen: isOpenProp,
    style,
    staticMenuStyle,
    dynamicMenuStyle,
    className,
    id,
    autoOpen = true,
    autoClose = true,
    onOpen,
    onClose,
    onOpenStart,
    onCloseStart,
  } = props;

  const [toggle, setToggle] = useState<boolean>();
  const [status, setStatus] = useState<TStatus>("closed");
  const [motion, setMotion] = useState<TMotion>("static");

  useEffect(() => {
    setToggle(isOpenProp);

    if (toggle === undefined) {
      setStatus(isOpenProp ? "opened" : "closed");
    } else if (isOpenProp !== toggle) {
      setStatus("moving");
      setMotion(isOpenProp ? "opening" : "closing");
    }
  }, [isOpenProp]);

  const onMouseEnter = useCallback(() => {
    if (!autoOpen) return;
    if (toggle === true) return;

    setToggle(true);
    setStatus("moving");
    setMotion("opening");

    if (onOpenStart) onOpenStart();
  }, [toggle, onOpenStart]);

  const onMouseLeave = useCallback(() => {
    if (!autoClose) return;
    if (toggle === false) return;

    setToggle(false);
    setStatus("moving");
    setMotion("closing");

    if (onCloseStart) onCloseStart();
  }, [toggle, onCloseStart]);

  const transitionListener = useCallback(() => {
    const newStatus = toggle ? "opened" : "closed";

    setStatus(newStatus);
    setMotion("static");

    if (toggle) {
      if (onOpen) onOpen();
    } else if (onClose) onClose();
  }, [toggle, onOpen, onClose]);

  return (
    <div
      id={id}
      style={{
        width: "100%",
        pointerEvents: "none",
        ...style,
      }}
      className={`h-full overflow-hidden ${className}`}
    >
      <div
        role="menu"
        className="absolute left-0 z-7 h-full"
        style={{ width: "20%", pointerEvents: "none", ...staticMenuStyle }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        tabIndex={0}
      >
        {cloneElement(staticMenu, { status, motion })}
      </div>

      <div
        className="h-full transition-transform"
        style={{
          width: "100%",
          pointerEvents: "none",
          transform: `translateX(${toggle ? "0" : "-80%"})`,
          ...dynamicMenuStyle,
        }}
        onTransitionEnd={transitionListener}
      >
        {cloneElement(dynamicMenu, { status, motion })}
      </div>
    </div>
  );
};

export default Sidebar;
