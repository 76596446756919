import { gql } from "@apollo/client";
import { IMessage } from "types/Message.type";

const GET_MAGAZINES = gql`
  query getMagazines {
    getMagazines {
      id
      edited
      isVoted
      thumbnail
      createdAt
      updatedAt
      type
      text
      question
      questionOptions
      createdBy {
        id
        name
        lastname
        profileImage
        birthday
        activeSince
      }
      answer {
        id
        text
        votes
      }
      comments {
        id
        edited
        text
        createdAt
        updatedAt
        message
        createdBy {
          id
          name
          lastname
          profileImage
        }
        reactions {
          emoji
          user {
            name
            lastname
            id
            profileImage
            role {
              name
            }
          }
        }
      }
      reactions {
        emoji
        user {
          name
          lastname
          id
        }
      }
    }
  }
`;

const GET_CAPSULES = gql`
  query getMagazines {
    getCapsules {
      id
      edited
      isVoted
      createdAt
      updatedAt
      type
      text
      question
      questionOptions
      createdBy {
        id
        name
        lastname
        profileImage
        birthday
        activeSince
      }
      answer {
        id
        text
        votes
      }
      comments {
        id
        edited
        text
        createdAt
        updatedAt
        message
        createdBy {
          id
          name
          lastname
          profileImage
        }
        reactions {
          emoji
          user {
            name
            lastname
            id
            profileImage
            role {
              name
            }
          }
        }
      }
      reactions {
        emoji
        user {
          name
          lastname
          id
        }
      }
    }
  }
`;

export interface IGetMessageInput {
  id: string;
}

const GET_MESSAGE = gql`
  query message($getMessageInput: GetMessageInput!) {
    message(getMessageInput: $getMessageInput) {
      id
      edited
      isVoted
      createdAt
      updatedAt
      type
      text
      question
      questionOptions
      userWhoGivesCorporateValue {
        id
        name
        lastname
        profileImage
        email
        role {
          id
          name
        }
      }
      corporateValue {
        id
        title
        avatar
      }
      createdBy {
        id
        name
        lastname
        profileImage
        birthday
        activeSince
        role {
          id
          name
        }
      }
      answer {
        id
        text
        votes
      }
      comments {
        id
        edited
        text
        createdAt
        updatedAt
        message
        createdBy {
          id
          name
          lastname
          profileImage
        }
        reactions {
          emoji
          user {
            name
            lastname
            id
            profileImage
            role {
              name
            }
          }
        }
      }
      reactions {
        emoji
        user {
          name
          lastname
          id
        }
      }
    }
  }
`;

const GET_MESSAGE_AND_MARK_AS_SEEN = gql`
  query GetMessageAndMarkAsSeen($getMessageInput: GetMessageInput!) {
    getMessageAndMarkAsSeen(getMessageInput: $getMessageInput) {
      id
      edited
      isVoted
      createdAt
      updatedAt
      type
      text
      question
      questionOptions
      tags
      timesSeen

      corporateValue {
        id
        title
        avatar
      }
      certificate {
        id
        name
      }

      userWhoGivesCorporateValue {
        id
        name
        lastname
        profileImage
        email
        role {
          id
          name
        }
      }
      createdBy {
        id
        name
        lastname
        profileImage
        email
        birthday
        activeSince
        role {
          id
          name
        }
      }
      answer {
        id
        text
        votes
      }
      comments {
        id
        edited
        text
        createdAt
        updatedAt
        message
        createdBy {
          id
          name
          lastname
          profileImage
        }
        reactions {
          emoji
          reactionUser {
            name
            lastname
            id
            email
            profileImage
            role {
              name
            }
          }
        }
      }
      reactions {
        emoji
        reactionUser {
          name
          lastname
          id
          email
          profileImage
          role {
            name
          }
        }
      }

      seeingBy {
        id
        name
        lastname
        profileImage
        email
      }
      newUsers {
        id
        name
        lastname
        profileImage
        email
        activeSince
        email
        adExternalJobTitle
        role {
          id
          name
        }
      }
    }
  }
`;

const GET_MESSAGE_SSR = gql`
  query Message($getMessageInput: GetMessageInput!) {
    message(getMessageInput: $getMessageInput) {
      id
      createdAt
      type
      text
      tags
      timesSeen
      createdBy {
        id
        name
        lastname
        profileImage
        activeSince
        selectedVendor {
          name
          logo
        }
      }
      newUsers {
        id
        name
        lastname
        profileImage
        adExternalJobTitle
        activeSince
        role {
          id
          name
        }
      }
      userWhoGivesCorporateValue {
        id
        name
        lastname
      }
    }
  }
`;

export interface IGetMessagesInput {
  skip: number;
  type: string;
  tags?: string[];
}

const GET_MESSAGES = gql`
  query messages($getMessagesInput: GetMessagesInput!) {
    messagesPaginated(getMessagesInput: $getMessagesInput) {
      messages {
        id
        edited
        isVoted
        createdAt
        updatedAt
        thumbnail
        type
        text
        question
        questionOptions
        tags
        timesSeen
        userWhoGivesCorporateValue {
          id
          name
          lastname
          profileImage
          email
          role {
            id
            name
          }
        }
        corporateValue {
          id
          title
          avatar
        }
        certificate {
          id
          name
          startDate
          subTitle
        }
        skill {
          id
          name
        }
        seeingBy {
          id
          name
          lastname
          profileImage
          email
        }
        createdBy {
          id
          name
          lastname
          profileImage
          birthday
          activeSince
          email
          role {
            id
            name
          }
        }
        answer {
          id
          text
          votes
        }
        comments {
          id
          edited
          text
          createdAt
          updatedAt
          message
          createdBy {
            id
            name
            lastname
            profileImage
          }
          reactions {
            emoji
            user
          }
        }
        reactions {
          emoji
          user
        }
        newUsers {
          id
          name
          email
          lastname
          profileImage
          activeSince
          adExternalJobTitle
          role {
            id
            name
          }
        }
        usersInMessage {
          id
          name
          lastname
          profileImage
          email
        }
      }
      total
    }
  }
`;

const GET_TAGS = gql`
  query getTags {
    getTags
  }
`;

export interface IMessagesPaginated {
  messages: IMessage[];
  total: number;
}

const GET_ENDORSEMENTS = gql`
  query GetEndorsements {
    getEndorsements {
      id
      edited
      isVoted
      createdAt
      updatedAt
      thumbnail
      type
      text
      question
      questionOptions
      skill {
        id
        name
      }
      seeingBy {
        id
        name
        lastname
        profileImage
        email
      }
      createdBy {
        id
        name
        lastname
        profileImage
        birthday
        activeSince
      }
      answer {
        id
        text
        votes
      }
      comments {
        id
        edited
        text
        createdAt
        updatedAt
        message
        createdBy {
          id
          name
          lastname
          profileImage
        }
        reactions {
          emoji
          user
          reactionUser {
            name
            lastname
            id
            email
            profileImage
          }
        }
      }
      reactions {
        emoji
        user
        reactionUser {
          name
          lastname
          id
          email
          profileImage
        }
      }
    }
  }
`;

const GET_MESSAGE_VIEWS = gql`
  query Message($getMessageInput: GetMessageInput!) {
    message(getMessageInput: $getMessageInput) {
      usersHasSeenMessage {
        user {
          id
          name
          lastname
          profileImage
          email
        }
        seenAt
      }
    }
  }
`;

const GET_MESSAGE_REACTION = gql`
  query GetMessageReactions($message: String!, $type: String!) {
    getMessageReactions(message: $message, type: $type) {
      reactionUser {
        name
        lastname
        profileImage
        id
        email
      }
      emoji
    }
  }
`;

export enum EPromptType {
  ImproveWriting = "ImproveWriting",
  Shorten = "Shorten",
  Lengthen = "Lengthen",
  FixSpellingAndGrammar = "FixSpellingAndGrammar",
  CreateEvaluationDescription = "CreateEvaluationDescription",
}

export interface IPromptInput {
  promptText: string;
  promptType: EPromptType;
  maxChars?: number;
}

export interface IPromptResponse {
  aiResponse: string;
}

const OPEN_AI_TEXT_ENHANCEMENT = gql`
  query PromptText($promptInput: PromptInput!) {
    promptText(promptInput: $promptInput) {
      aiResponse
    }
  }
`;

export default {
  GET_MAGAZINES,
  GET_MESSAGES,
  GET_MESSAGE,
  GET_MESSAGE_AND_MARK_AS_SEEN,
  GET_CAPSULES,
  GET_MESSAGE_SSR,
  GET_ENDORSEMENTS,
  GET_TAGS,
  GET_MESSAGE_VIEWS,
  GET_MESSAGE_REACTION,
  OPEN_AI_TEXT_ENHANCEMENT,
};
