import Lottie from "react-lottie";
import lottieSiteLoader from "utils/lottie/site-loader.json";

const loaderLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: lottieSiteLoader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface IGroowLoaderProps {
  size: number;
  height?: number;
}

const GroowLoader = (props: IGroowLoaderProps) => {
  const { size, height } = props;
  // * queries
  // * mutations
  // * handles
  // * useEffects
  return (
    <div className="pt-10">
      <Lottie
        isClickToPauseDisabled
        options={loaderLottieOptions}
        height={height || size}
        width={size}
      />
    </div>
  );
};

export default GroowLoader;
