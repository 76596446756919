import { create } from "zustand";

interface MobileSidebarState {
  isOpenBurger: boolean;
  toggleBurgerSidebar: () => void;
}

export const useMobileSidebarStore = create<MobileSidebarState>((set) => ({
  isOpenBurger: false,
  toggleBurgerSidebar: () =>
    set((state) => ({ isOpenBurger: !state.isOpenBurger })),
}));
