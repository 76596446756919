import { gql } from "@apollo/client";
import { ITag } from "types/Tag.type";

export interface IGetTagInput {
  name: string;
}
export interface IGetTagResponse {
  tag: ITag;
}

const GET_TAG = gql`
  query tag($tagInput: TagInput!) {
    tag(tagInput: $tagInput) {
      name
      id
    }
  }
`;

export interface IGetTagsResponse {
  tags: ITag[];
}

const GET_TAGS = gql`
  query Tags {
    tags {
      name
      id
    }
  }
`;

export interface IGetTagsByDimensionsInput {
  tagsByDimensionsInput: {
    dimensions: string[];
  };
}

export interface IGetTagsByDimensionsRes {
  tagsByDimensions: ITag[];
}

const GET_TAGS_BY_DIMENSIONS = gql`
  query TagsByDimensions($tagsByDimensionsInput: TagsByDimensionsInput!) {
    tagsByDimensions(tagsByDimensionsInput: $tagsByDimensionsInput) {
      id
      name
    }
  }
`;

export default { GET_TAG, GET_TAGS, GET_TAGS_BY_DIMENSIONS };
