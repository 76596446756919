import { gql } from "@apollo/client";
import {
  EActivityPublicType,
  EActivityType,
  EUserDecision,
  IActivity,
  IActivityAddress,
} from "types/Activitites.type";

export interface ICreateActivityInput {
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  tags?: string[];
  eventUrl?: string;
  isPublic: EActivityPublicType;
  invitedUsers: string[];
  location?: string;
  type: EActivityType;
  activityAddress?: IActivityAddress;
  images?: string[];
  coins?: number;
}

export interface ICreateActivityResponse {
  createActivity: IActivity;
}

const CREATE_ACTIVITY = gql`
  mutation createActivity($createActivityInput: CreateActivityInput!) {
    createActivity(createActivityInput: $createActivityInput) {
      id
      name
      description
      createdAt
      startDate
      endDate
      tags
      eventUrl
      coins
      isPublic
      invitedUsers {
        name
        email
      }
    }
  }
`;

export interface IUpdateActivityInput {
  id: string;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  tags?: string[];
  eventUrl?: string;
  isPublic: EActivityPublicType;
  invitedUsers: string[];
  location?: string;
  type: EActivityType;
  activityAddress?: IActivityAddress;
  images?: string[];
  coins?: number;
}

export interface IUpdateActivityResponse {
  updateActivity: IActivity;
}

const UPDATE_ACTIVITY = gql`
  mutation updateActivity($updateActivityInput: UpdateActivityInput!) {
    updateActivity(updateActivityInput: $updateActivityInput) {
      id
      name
      description
      createdAt
      startDate
      endDate
      tags
      eventUrl
      isPublic
      images
      type
    }
  }
`;

export interface IDeleteActivityInput {
  id: string;
}

export interface IDeleteActivityResponse {
  deleteActivity: IActivity;
}

const DELETE_ACTIVITY = gql`
  mutation deleteActivity($deleteActivityInput: DeleteActivityInput!) {
    deleteActivity(deleteActivityInput: $deleteActivityInput) {
      id
      name
      description
      createdAt
      startDate
      endDate
      tags
      eventUrl
      isPublic
      images
      type
      iMayGo {
        email
        reason
      }
      iCantGo {
        email
        reason
      }
      iWillGo {
        email
        reason
      }
      invitedUsers {
        name
        email
      }
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
    }
  }
`;

export interface IUserActivityRegistrationInput {
  email: string;
  activityId: string;
  decision: EUserDecision;
  reason?: string;
  selectedVendorId?: string;
}

export interface IUserActivityRegistrationResponse {
  userActivityRegistration: IActivity;
}

const USER_ACTIVITY_REGISTRATION = gql`
  mutation activityRegistration(
    $userRegistrationInput: UserRegistrationInput!
  ) {
    activityRegistration(userRegistrationInput: $userRegistrationInput) {
      id
      name
      iCantGo {
        email
        lastname
        name
      }
      iMayGo {
        email
        lastname
        name
      }
      iWillGo {
        email
        lastname
        name
      }
    }
  }
`;

export interface ISendVerificationCodeInput {
  email: string;
  name: string;
  vendorId: string;
  sessionId: string;
  activityId;
}

const SEND_VERIFICATION_CODE = gql`
  mutation sendVerificationCode(
    $sendVerificationCodeInput: SendVerificationCodeInput!
  ) {
    sendVerificationCode(sendVerificationCodeInput: $sendVerificationCodeInput)
  }
`;

const CREATE_REDEEM_CODE = gql`
  mutation CreateRedeemCode($activityId: String!) {
    createRedeemCode(activityId: $activityId) {
      id
      redeemCode
    }
  }
`;

const REDEEM_ACTIVITY = gql`
  mutation RedeemActivity($reedemActivityInput: RedeemActivityInput!) {
    redeemActivity(reedemActivityInput: $reedemActivityInput) {
      id
      coins
    }
  }
`;

const ADD_COLLABORATOR_TO_ACTIVITY = gql`
  mutation AddMembersToActivity(
    $addMembersToActivityInput: AddMembersToActivityInput!
  ) {
    addMembersToActivity(
      addMembersToActivityInput: $addMembersToActivityInput
    ) {
      id
    }
  }
`;

export default {
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY,
  USER_ACTIVITY_REGISTRATION,
  SEND_VERIFICATION_CODE,
  CREATE_REDEEM_CODE,
  REDEEM_ACTIVITY,
  ADD_COLLABORATOR_TO_ACTIVITY,
};
