import { gql } from "@apollo/client";

export interface IGetDashboardDataInput {
  startDate: Date;
  endDate: Date;
  roles?: string[];
  teams?: string[];
}

const GET_DASHBOARD_DATA = gql`
  query dashboardData($getDashboardDataInput: GetDashboardDataInput!) {
    dashboardData(getDashboardDataInput: $getDashboardDataInput) {
      goalAchievedOnTime
      goalAchievedOutOfTime
      goalNotAchieved
      abandonAppreciation
      actionPlanAcomplishmentPercent
      actionPlanCompletedOnTime
      activities
      activityEffectivePercent
      activityEffectiveUsers
      activityEngagementPercent
      activityInvitedUsers
      answeredOnTimeAppreciations
      appreciationPerformance
      assignedActionPlans
      assignedCareers
      careerAcomplishmentPercent
      careerPathAcomplishmentData {
        careerPathAcomplishmentPercent
        roleName
      }
      completedTaskPerMonth {
        completedTask
        uncompletedTask
        completedTaskPercent
        month
        monthNumber
        year
      }
      careerPathAcomplishmentDataPerMonth {
        roleName
        data {
          month
          monthNumber
          year
          careerPathAcomplishmentPercent
        }
      }
      lastAppreciationData {
        maturityLevel
        roleName
        roleAvatar
        totalPersonInRole
      }
      multimediaAvailable
      multimediasReviews
      totalRoles
      totalTeams
      unasweredAppreciations
    }
  }
`;

export interface ITeamRankLeaders {
  name: string;
  lastname?: string;
  avatar: string;
}

export interface ITeamRankUsers {
  name: string;
  lastname?: string;
  avatar: string;
  maturityLevel: string;
  roleName: string;
  isLeader: boolean;
}
export interface ITeamRank {
  team: string;
  teamAvatar: string;
  teamName: string;
  teamOverallPerformance: number;
  last7DaysPercent: number;
  last30DaysPercent: number;
  last12MonthsPercent: number;
  users: ITeamRankUsers[];
  leaders: ITeamRankLeaders[];
}

const GET_ORGANIZATIONAL_RANKING = gql`
  query organizationalRanking {
    organizationalRanking {
      teamRank {
        team
        teamAvatar
        teamName
        teamOverallPerformance
        last7DaysPercent
        last30DaysPercent
        last12MonthsPercent
        users {
          name
          lastname
          avatar
          maturityLevel
          roleName
          isLeader
        }
        leaders {
          name
          lastname
          avatar
        }
      }
    }
  }
`;

export interface IGetTeamPerformanceInput {
  team: string;
}

export interface ITeamPerformance {
  month: number;
  monthName: string;
  score: number;
  team: string;
  year: number;
}

const GET_TEAM_PERFORMANCE = gql`
  query teamPerformance($getTeamPerformanceInput: GetTeamPerformanceInput!) {
    teamPerformance(getTeamPerformanceInput: $getTeamPerformanceInput) {
      month
      monthName
      score
      team
      year
    }
  }
`;

export interface IOrganizationalDashboardData {
  activePeople: number;
  teams: number;
  roles: number;
  groowcityAdherence: number;
  goalAchievedOnTime: number;
  goalAchievedOutOfTime: number;
  goalNotAchieved: number;
  goalPerformance: number;
  totalGoals: number;
  appreciationAchievedOnTime: number;
  appreciationAchievedOutOfTime: number;
  appreciationNotAchieved: number;
  appreciationPerformance: number;
  appreciationAbandoned: number;
  careersCompleted: number;
  actionPlansCompleted: number;
  actionPlanPerformance: number;
  totalCareers: number;
  totalActionPlans: number;
  totalAppreciations: number;
}

const GET_ORGANIZATIONA_DASHBOARD_DATA = gql`
  query OrganizationalDashboardData {
    organizationalDashboardData {
      activePeople
      teams
      roles
      groowcityAdherence
      goalAchievedOnTime
      goalAchievedOutOfTime
      goalNotAchieved
      goalPerformance
      totalGoals
      appreciationAchievedOnTime
      appreciationAchievedOutOfTime
      appreciationNotAchieved
      appreciationPerformance
      appreciationAbandoned
      careersCompleted
      actionPlansCompleted
      actionPlanPerformance
      totalCareers
      totalActionPlans
      totalAppreciations
    }
  }
`;
export default {
  GET_DASHBOARD_DATA,
  GET_ORGANIZATIONAL_RANKING,
  GET_TEAM_PERFORMANCE,
  GET_ORGANIZATIONA_DASHBOARD_DATA,
};
