import { gql } from "@apollo/client";
import { ITeam } from "../../types/Team.type";

export interface ICreateTeamInput {
  name: string;
  description: string;
  leader: string[];
  avatar?: string;
}

const CREATE_TEAM = gql`
  mutation createTeam($createTeamInput: CreateTeamInput!) {
    createTeam(createTeamInput: $createTeamInput) {
      id
    }
  }
`;

export interface IUpdateTeamInput extends ICreateTeamInput {
  id: string;
}

const UPDATE_TEAM = gql`
  mutation updateTeam($updateTeamInput: UpdateTeamInput!) {
    updateTeam(updateTeamInput: $updateTeamInput) {
      id
    }
  }
`;

export interface IAddTeamMemberInput {
  userId: string[];
  teamId: string;
}
const ADD_TEAM_MEMBER = gql`
  mutation addTeamMember($addTeamMemberInput: AddTeamMemberInput!) {
    addTeamMember(addTeamMemberInput: $addTeamMemberInput) {
      id
    }
  }
`;

export interface IDeleteTeamMemberInput {
  userId: string;
  teamId: string;
}
const DELETE_TEAM_MEMBER = gql`
  mutation deleteTeamMember($deleteTeamMemberInput: DeleteTeamMemberInput!) {
    deleteTeamMember(deleteTeamMemberInput: $deleteTeamMemberInput) {
      id
    }
  }
`;

const DISABLE_TEAM_BY_ID = gql`
  mutation disableTeam($id: String!) {
    disableTeam(id: $id)
  }
`;

export interface ICreateTeamOnboardingInput {
  createTeamOnboardingInput: {
    avatar: string;
    name: string;
  };
}
export interface ICreateTeamOnboardingRes {
  createTeamOnboarding: ITeam;
}
const CREATE_TEAM_ON_BOARDING = gql`
  mutation CreateTeamOnboarding(
    $createTeamOnboardingInput: CreateTeamOnboardingInput!
  ) {
    createTeamOnboarding(
      createTeamOnboardingInput: $createTeamOnboardingInput
    ) {
      id
      name
      avatar
      invitationLink {
        url
      }
    }
  }
`;

export interface IUpdateTeamOnboardingInput {
  updateTeamOnboardingInput: {
    id: string;
    avatar?: string;
    emails?: string[];
    name?: string;
  };
}
export interface IUpdateTeamOnboardingRes {
  updateTeamOnboarding: ITeam;
}
const UPDATE_TEAM_ONBOARDING = gql`
  mutation UpdateTeamOnboarding(
    $updateTeamOnboardingInput: UpdateTeamOnboardingInput!
  ) {
    updateTeamOnboarding(
      updateTeamOnboardingInput: $updateTeamOnboardingInput
    ) {
      id
      name
      avatar
      invitationLink {
        url
      }
    }
  }
`;

export interface IAddUserInvitationLinkInput {
  addUserFromShareLinkInput: {
    shortGuid: string;
  };
}
export interface IAddUserInvitationLinkRes {
  addUserShareLink: ITeam;
}
const ADD_USER_INVITATION_LINK = gql`
  mutation AddUserShareLink(
    $addUserFromShareLinkInput: AddUserFromShareLinkInput!
  ) {
    addUserShareLink(addUserFromShareLinkInput: $addUserFromShareLinkInput) {
      id
      name
      avatar
    }
  }
`;

export default {
  CREATE_TEAM,
  CREATE_TEAM_ON_BOARDING,
  UPDATE_TEAM_ONBOARDING,
  ADD_USER_INVITATION_LINK,
  ADD_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  UPDATE_TEAM,
  DISABLE_TEAM_BY_ID,
};
