import { gql } from "@apollo/client";
import { EReactionModule, EReactionType, IReaction } from "types/Reaction";

export interface IHandleReactionInput {
  reaction: EReactionType;
  comment?: string;
  type: EReactionModule;
  foreignId: string;
}

export interface IHandleReactionResponse {
  handleReaction: IReaction;
}

const HANDLE_REACTION = gql`
  mutation createReaction($handleReactionInput: HandleReactionInput!) {
    handleReaction(handleReactionInput: $handleReactionInput) {
      id
      type
    }
  }
`;

export default { HANDLE_REACTION };
