import { IUser } from "./User.types";
import { IVendor } from "./Vendor.types";

export interface IFeedBack {
  id: string;
  updatedAt: string;
  createdAt: string;
  deletedAt: string;
  comment: string;
  url: string;
  user: IUser;
  pathname: string;
  vendor: IVendor;
  type: EFeedbackType;
  screenshot: string;
}

export enum EFeedbackType {
  BUG = "BUG",
  IDEA = "IDEA",
  OTHER = "OTHER",
}
