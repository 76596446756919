import { gql } from "@apollo/client";

export const UPDATE_SKILL_CATEGORY = gql`
  mutation UpdateSkill($updateSkillInput: UpdateSkillInput!) {
    updateSkill(updateSkillInput: $updateSkillInput) {
      category
      name
      id
    }
  }
`;

export const CREATE_SKILL_WITH_CATEGORY = gql`
  mutation CreateSkill($createSkillInput: CreateSkillInput!) {
    createSkill(createSkillInput: $createSkillInput) {
      id
    }
  }
`;

export const DELETE_SKILL = gql`
  mutation DeleteSkill($deleteSkillId: String!) {
    deleteSkill(id: $deleteSkillId) {
      id
    }
  }
`;

export default {
  UPDATE_SKILL_CATEGORY,
  CREATE_SKILL_WITH_CATEGORY,
  DELETE_SKILL,
};
