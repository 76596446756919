import { gql } from "@apollo/client";
import { ITag } from "types/Tag.type";

export interface ICreateTagInput {
  name: string;
}
export interface ICreateTagResponse {
  createTag: ITag;
}
const CREATE_TAG = gql`
  mutation createTag($createTagInput: CreateTagInput!) {
    createTag(createTagInput: $createTagInput) {
      id
      name
    }
  }
`;

export interface IUpdateTagInput {
  id: string;
  name: string;
}
export interface IUpdateTagResponse {
  updateTag: ITag;
}
const UPDATE_TAG = gql`
  mutation UpdateTagMutation($updateTagInput: UpdateTagInput!) {
    updateTag(updateTagInput: $updateTagInput) {
      id
      name
    }
  }
`;

export default { CREATE_TAG, UPDATE_TAG };
