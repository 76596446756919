import { gql } from "@apollo/client";
import { ITeam } from "../../types/Team.type";

export interface IGetTeamsInput {
  name?: string;
  leader?: string[];
  limit: number;
  offset: number;
}
export interface IGetTeam {
  id: string;
}

export interface ITTeam {
  team: ITeam;
}

const GET_TEAM = gql`
  query team($id: String!) {
    team(id: $id) {
      id
      name
      description
      avatar
      user {
        id
        createdAt
        name
        country
        lastname
        bukExternalJobName
        profileImage
        activeSince
        email
        dailyQuestionTasks
        englishLevel
        adExternalId
        adExternalJobTitle
        adCleverId
        billability
        lastUsedIp
        trainer
        englishLevelUpdates {
          newEnglishLevel
          oldEnglishLevel
          updatedAt
          userId
        }
        role {
          id
          name
          avatar
        }
        area {
          id
          name
          avatar
        }
        systemRole
        active
        activeCareer {
          id
          name
          completionPercentage
        }
        goal {
          id
          startDate
          endDate
        }
        seniority
        seniorityLog {
          seniority
          date
        }
      }
      leader {
        id
        name
        lastname
        email
      }
      invitationLink {
        url
      }
    }
  }
`;

export interface ITeams {
  teams: ITeam[];
}

const GET_TEAMS = gql`
  query teams {
    teams {
      id
      name
      description
      avatar
      leader {
        id
      }
      user {
        id
        name
        lastname
      }
    }
  }
`;

const GET_TEAMS_LEADER = gql`
  query teamsLeader {
    teamsLeader {
      id
      avatar
      name
      tags
      description
      leader {
        id
        name
        lastname
        email
        profileImage
      }
      user {
        id
        name
        lastname
      }
    }
  }
`;

export interface IGetTeamMembersInput {
  id: string;
}
const GET_TEAMS_MEMBERS = gql`
  query teamMembers($getTeamMembersInput: GetTeamMembersInput!) {
    teamMembers(getTeamMembersInput: $getTeamMembersInput) {
      id
      name
      trainer
      lastname
      englishLevel
      email
      birthday
      tags
      profileImage
      dailyQuestionTasks
      activeSince
      assigned
      assignable
      lastActivityDate
      bukExternalJobName
      adExternalId
      adExternalJobTitle
      adCleverId
      billability
      lastUsedIp
      nationality
      country
      countryCode
      certifications {
        id
        name
        url
        certificationUrl
      }
      userRank
      userLevel {
        experience
        nextLevelExp
        level
      }
      systemRole
      role {
        id
        name
        avatar
      }
      activeCareer {
        id
        completionPercentage
        name
      }
      goal {
        id
        startDate
        endDate
      }
      area {
        id
        name
        avatar
      }
      seniority
      seniorityLog {
        seniority
        date
      }
    }
  }
`;

export interface IGetTeamPaginatedResponse {
  team: ITeam[];
  totalPages: number;
  total: number;
}

const GET_TEAMS_PAGINATED = gql`
  query GetTeamMembersPaginatedPaginated(
    $getTeamMembersPaginatedInput: GetTeamMembersPaginatedInput!
  ) {
    getTeamMembersPaginated(
      getTeamMembersPaginatedInput: $getTeamMembersPaginatedInput
    ) {
      team {
        id
        name
        avatar
        createdAt
        description
        active
        leader {
          id
          name
          lastname
          profileImage
          role {
            id
            name
          }
        }
        user {
          id
          name
          lastname
        }
      }
      total
      totalPages
    }
  }
`;

const GET_LEADERS = gql`
  query LeadersTeam {
    leadersTeam {
      id
      name
      lastname
    }
  }
`;

export default {
  GET_TEAM,
  GET_TEAMS,
  GET_TEAMS_LEADER,
  GET_TEAMS_MEMBERS,
  GET_TEAMS_PAGINATED,
  GET_LEADERS,
};
