import { gql } from "@apollo/client";
import { IItemPath, IItemPathQuestion, IUserItemPath } from "types/Career.type";

export interface IGetCareerInput {
  id: string;
}

const GET_CAREER_INFO = gql`
  query career($getCareerInput: GetCareerInput!) {
    career(getCareerInput: $getCareerInput) {
      id
      name
      description
      roles {
        id
        name
      }
    }
  }
`;

const GET_CAREER = gql`
  query career($getCareerInput: GetCareerInput!) {
    career(getCareerInput: $getCareerInput) {
      id
      name
      description
      roles {
        id
        name
      }
      items {
        itemPathId
        level
        itemPath {
          id
          name
          description
          color
          multimedias {
            id
          }
          maturity {
            role {
              id
              name
            }
            maturity {
              id
              name
            }
          }
          dimensions {
            name
          }
        }
        childrens {
          itemPathId
          level
          itemPath {
            id
            name
            description
            color
            multimedias {
              id
            }
            maturity {
              role {
                id
                name
              }
              maturity {
                id
                name
              }
            }
            dimensions {
              name
            }
          }
          childrens {
            itemPathId
            level
            itemPath {
              id
              name
              description
              color
              multimedias {
                id
              }
              maturity {
                role {
                  id
                  name
                }
                maturity {
                  id
                  name
                }
              }
              dimensions {
                name
              }
            }
            childrens {
              itemPathId
              level
              itemPath {
                id
                name
                description
                color
                multimedias {
                  id
                }
                maturity {
                  role {
                    id
                    name
                  }
                  maturity {
                    id
                    name
                  }
                }
                dimensions {
                  name
                }
              }
              childrens {
                itemPathId
                level
                itemPath {
                  id
                  name
                  description
                  color
                  multimedias {
                    id
                  }
                  maturity {
                    role {
                      id
                      name
                    }
                    maturity {
                      id
                      name
                    }
                  }
                  dimensions {
                    name
                  }
                }
                childrens {
                  itemPathId
                  level
                  itemPath {
                    id
                    name
                    description
                    color
                    multimedias {
                      id
                    }
                    maturity {
                      role {
                        id
                        name
                      }
                      maturity {
                        id
                        name
                      }
                    }
                    dimensions {
                      name
                    }
                  }
                  childrens {
                    itemPathId
                    level
                    itemPath {
                      id
                      name
                      description
                      color
                      multimedias {
                        id
                      }
                      maturity {
                        role {
                          id
                          name
                        }
                        maturity {
                          id
                          name
                        }
                      }
                      dimensions {
                        name
                      }
                    }
                    childrens {
                      itemPathId
                      level
                      itemPath {
                        id
                        name
                        description
                        color
                        multimedias {
                          id
                        }
                        maturity {
                          role {
                            id
                            name
                          }
                          maturity {
                            id
                            name
                          }
                        }
                        dimensions {
                          name
                        }
                      }
                      childrens {
                        itemPathId
                        level
                        itemPath {
                          id
                          name
                          description
                          color
                          multimedias {
                            id
                          }
                          maturity {
                            role {
                              id
                              name
                            }
                            maturity {
                              id
                              name
                            }
                          }
                          dimensions {
                            name
                          }
                        }
                        childrens {
                          itemPathId
                          level
                          itemPath {
                            id
                            name
                            description
                            color
                            multimedias {
                              id
                            }
                            maturity {
                              role {
                                id
                                name
                              }
                              maturity {
                                id
                                name
                              }
                            }
                            dimensions {
                              name
                            }
                          }
                          childrens {
                            itemPathId
                            level
                            itemPath {
                              id
                              name
                              description
                              color
                              multimedias {
                                id
                              }
                              maturity {
                                role {
                                  id
                                  name
                                }
                                maturity {
                                  id
                                  name
                                }
                              }
                              dimensions {
                                name
                              }
                            }
                            childrens {
                              itemPathId
                              level
                              itemPath {
                                id
                                name
                                description
                                color
                                multimedias {
                                  id
                                }
                                maturity {
                                  role {
                                    id
                                    name
                                  }
                                  maturity {
                                    id
                                    name
                                  }
                                }
                                dimensions {
                                  name
                                }
                              }
                              childrens {
                                itemPathId
                                level
                                itemPath {
                                  id
                                  name
                                  description
                                  color
                                  multimedias {
                                    id
                                  }
                                  maturity {
                                    role {
                                      id
                                      name
                                    }
                                    maturity {
                                      id
                                      name
                                    }
                                  }
                                  dimensions {
                                    name
                                  }
                                }
                                childrens {
                                  itemPathId
                                  level
                                  itemPath {
                                    id
                                    name
                                    description
                                    color
                                    multimedias {
                                      id
                                    }
                                    maturity {
                                      role {
                                        id
                                        name
                                      }
                                      maturity {
                                        id
                                        name
                                      }
                                    }
                                    dimensions {
                                      name
                                    }
                                  }
                                  childrens {
                                    itemPathId
                                    level
                                    itemPath {
                                      id
                                      name
                                      description
                                      color
                                      multimedias {
                                        id
                                      }
                                      maturity {
                                        role {
                                          id
                                          name
                                        }
                                        maturity {
                                          id
                                          name
                                        }
                                      }
                                      dimensions {
                                        name
                                      }
                                    }
                                    childrens {
                                      itemPathId
                                      level
                                      itemPath {
                                        id
                                        name
                                        description
                                        color
                                        multimedias {
                                          id
                                        }
                                        maturity {
                                          role {
                                            id
                                            name
                                          }
                                          maturity {
                                            id
                                            name
                                          }
                                        }
                                        dimensions {
                                          name
                                        }
                                      }
                                      childrens {
                                        itemPathId
                                        level
                                        itemPath {
                                          id
                                          name
                                          description
                                          color
                                          multimedias {
                                            id
                                          }
                                          maturity {
                                            role {
                                              id
                                              name
                                            }
                                            maturity {
                                              id
                                              name
                                            }
                                          }
                                          dimensions {
                                            name
                                          }
                                        }
                                        childrens {
                                          itemPathId
                                          level
                                          itemPath {
                                            id
                                            name
                                            description
                                            color
                                            multimedias {
                                              id
                                            }
                                            maturity {
                                              role {
                                                id
                                                name
                                              }
                                              maturity {
                                                id
                                                name
                                              }
                                            }
                                            dimensions {
                                              name
                                            }
                                          }
                                          childrens {
                                            itemPathId
                                            level
                                            itemPath {
                                              id
                                              name
                                              description
                                              color
                                              multimedias {
                                                id
                                              }
                                              maturity {
                                                role {
                                                  id
                                                  name
                                                }
                                                maturity {
                                                  id
                                                  name
                                                }
                                              }
                                              dimensions {
                                                name
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface IGetAllCareersInput {
  getAllCareerInput: {
    name?: string;
    allowEmptyItems?: boolean;
    userId?: string;
  };
}

const GET_CAREERS = gql`
  query careers($getAllCareerInput: GetAllCareerInput!) {
    careers(getAllCareerInput: $getAllCareerInput) {
      id
      isQuickie
      name
      version
      roles {
        id
        name
      }
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
    }
  }
`;

export interface IGetAllItemPathInput {
  name?: string;
  roles?: string[];
}

export interface IGetAllItemPathsResponse {
  itemPaths: IItemPath[];
}

const GET_ITEM_PATHS = gql`
  query itemPaths($getAllItemPathInput: GetAllItemPathInput!) {
    itemPaths(getAllItemPathInput: $getAllItemPathInput) {
      id
      isQuickie
      name
      description
      createdBy {
        id
        name
        email
        lastname
        profileImage
        linkedin
        facebook
        twitter
        instagram
        github
        coverImage
        shortBio
      }
      vendorId
      deleted
      createdAt
      updatedAt
      associatedCareers
      compliancePercent
      color
      shortDescription
      dimensions {
        name
        id
      }
    }
  }
`;

export interface IGetItemPathInput {
  id: string;
}

export interface IGetItemPathResponse {
  itemPath: IItemPath;
}

const GET_ITEM_PATH = gql`
  query itemPath($getItemPathInput: GetItemPathInput!) {
    itemPath(getItemPathInput: $getItemPathInput) {
      id
      name
      description
      createdBy {
        id
        name
        lastname
      }
      vendorId
      deleted
      createdAt
      updatedAt
      color
      compliancePercent
      shortDescription
      dimensions {
        id
        name
        associatedRoles {
          id
          name
        }
        roles {
          id
          name
          maturities {
            id
            name
          }
        }
      }
      multimedias {
        id
        fileUrl
        thumbnailUrl
        name
        mimeType
        multimediaType
        description
        tags
      }
      maturity {
        role {
          id
          name
        }
        maturity {
          id
          name
        }
      }
      appreciation
    }
  }
`;

export interface IGetUserItemPathInput {
  id: string;
}

export interface IGetUserItemPathResponse {
  userItemPath: IUserItemPath;
}

const GET_USER_ITEM_PATH = gql`
  query userItemPath($getUserItemPathInput: GetUserItemPathInput!) {
    userItemPath(getUserItemPathInput: $getUserItemPathInput) {
      id
      name
      description
      vendorId
      deleted
      createdAt
      updatedAt
      color
      enableTest
      multimedias {
        id
        fileUrl
        thumbnailUrl
        name
        description
        mimeType
        multimediaType
        userRate
      }
      appreciation
    }
  }
`;

export interface IItemPathQuestionOptions {
  itemPathId: string | string[];
}

export interface IItemPathQuestionOptionsResponse {
  itemPathQuestions: IItemPathQuestion[];
}

const GET_ITEM_PATH_QUESTIONS_OPTIONS = gql`
  query itemPathQuestions($itemPathId: String!) {
    itemPathQuestions(itemPathId: $itemPathId) {
      id
      name
      visibleOptions
      correctOptions
      options {
        id
        name
        isCorrect
      }
    }
  }
`;

export interface IGetUserItemPathTestInput {
  userItemPathId: string;
}

const GET_USER_ITEM_PATH_TEST = gql`
  query userItemPathTest($getUserItemPathTestInput: GetUserItemPathTestInput!) {
    userItemPathTest(getUserItemPathTestInput: $getUserItemPathTestInput) {
      questions {
        id
        originalId
        name
        options {
          originalId
          name
        }
      }
    }
  }
`;

const GET_ITEMS_PATH_PAGINATED = gql`
  query ItemPathsPaginated($getAllItemPathInput: GetAllItemPathInput!) {
    itemPathsPaginated(getAllItemPathInput: $getAllItemPathInput) {
      itemPaths {
        id
        name
        description
        createdBy {
          id
          name
          lastname
          profileImage
          email
        }
        vendorId
        deleted
        createdAt
        updatedAt
        color
        compliancePercent
        shortDescription
        dimensions {
          id
          name
          associatedRoles {
            id
            name
          }
          roles {
            id
            name
            maturities {
              id
              name
            }
          }
        }
        multimedias {
          id
          fileUrl
          thumbnailUrl
          name
          mimeType
          multimediaType
          description
          tags
        }
        maturity {
          role {
            id
            name
          }
          maturity {
            id
            name
          }
        }
      }
      total
    }
  }
`;

export default {
  GET_CAREERS,
  GET_ITEM_PATHS,
  GET_CAREER,
  GET_ITEM_PATH,
  GET_ITEM_PATH_QUESTIONS_OPTIONS,
  GET_USER_ITEM_PATH,
  GET_USER_ITEM_PATH_TEST,
  GET_CAREER_INFO,
  GET_ITEMS_PATH_PAGINATED,
};
