import { gql } from "@apollo/client";
import { INotification } from "types/Notifcation.type";

export interface IMarkAsReadResponse {
  markAsRead: INotification;
}

const MARK_AS_READ = gql`
  mutation MarkAsRead($notificationId: String!) {
    markAsRead(notificationId: $notificationId) {
      id
      type
      entityId
    }
  }
`;

const MARK_ALL_AS_READ = gql`
  mutation MarkAllAsRead {
    markAllAsRead {
      id
    }
  }
`;

export default { MARK_AS_READ, MARK_ALL_AS_READ };
