"use client";

import { WIDTH_MD } from "@constants/global";
import { useWindowWidth } from "@react-hook/window-size";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { Building2 } from "lucide-react";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import { useMobileSidebarStore } from "stores/sidebar/useMobileSidebarStore";

export default function TagVendor() {
  const { isExpanded } = useDesktopSidebarStore();
  const { isOpenBurger } = useMobileSidebarStore();

  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));
  const { t } = useTranslation("common");

  const wWidth = useWindowWidth();
  const isMd = wWidth >= WIDTH_MD;
  const [renderText, setRenderText] = useState<boolean>(false);

  useEffect(() => {
    if (isExpanded || isOpenBurger) {
      // SetRenderText to true if isExpanded or isOpenBurger with timeout
      setTimeout(() => {
        setRenderText(true);
      }, 200);
    } else {
      setRenderText(false);
    }
  }, [isExpanded, isOpenBurger, isMd]);

  const handleCopyId = () => {
    if (!user?.isi) return;
    navigator.clipboard.writeText(user?.selectedVendorId || "");
    toast.success(t("ID Copiado"));
  };

  return (
    <div
      className="mt-2 flex w-auto cursor-pointer items-center justify-center gap-1 overflow-auto rounded-md border border-gray-400 py-1 dark:border-gray-600"
      onClick={handleCopyId}
    >
      <Building2 size={18} color="gray" />
      {renderText && <span>{user?.selectedVendor?.name}</span>}
    </div>
  );
}
