import Icon from "components/Icon";
import { useEffect } from "react";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";

interface ToggleCompresSidebarProps {
  readonly isDesktopLogo: boolean;
  readonly setIsDesktopLogo: React.Dispatch<React.SetStateAction<boolean>>;
  readonly version?: string;
}

export default function ToggleCompresSidebar(props: ToggleCompresSidebarProps) {
  const { isExpanded, toggleSidebar } = useDesktopSidebarStore();
  const { isDesktopLogo, setIsDesktopLogo } = props;

  useEffect(() => {
    if (!isExpanded) {
      setTimeout(() => {
        setIsDesktopLogo(false);
      }, 200);
    }
    if (isExpanded) {
      setIsDesktopLogo(true);
    }
  }, [isExpanded]);
  return (
    <div
      className={`absolute right-0 z-9 ${isDesktopLogo ? "" : "translate-x-4"}`}
    >
      <button
        onClick={toggleSidebar}
        className={`grid h-8 w-8 place-items-center justify-center rounded-full hover:text-current-500 dark:bg-gray-800 dark:hover:bg-gray-800 ${!isExpanded ? "bg-gray-200" : ""}`}
      >
        <Icon
          icon={
            !isDesktopLogo ? ["far", "chevron-right"] : ["far", "chevron-left"]
          }
          style={{
            fontSize: 16,
          }}
        />
      </button>
    </div>
  );
}
