import { gql } from "@apollo/client";

export interface ISendEmailToUsersRequiredForProjectInput {
  emails: string[];
  subject: string;
}

export interface ISendEmailToUsersRequiredForProjectResponse {
  id: string;
}

export const SEND_EMAIL_WITH_USER_REQUIRED_BY_SKILLS = gql`
  mutation SendEmailToUsersRequiredForProject(
    $searchUsersRequiredForProjectInput: SearchUsersRequiredForProjectInput!
    $sendEmailToUsersRequiredForProjectInput: SendEmailToUsersRequiredForProjectInput!
  ) {
    sendEmailToUsersRequiredForProject(
      searchUsersRequiredForProjectInput: $searchUsersRequiredForProjectInput
      sendEmailToUsersRequiredForProjectInput: $sendEmailToUsersRequiredForProjectInput
    ) {
      id
      email
    }
  }
`;

export default { SEND_EMAIL_WITH_USER_REQUIRED_BY_SKILLS };
