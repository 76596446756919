import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import React, { useEffect, useRef } from "react";
import { Spinner } from "./custom/spinner/Spinner";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-blue-100",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-red-600 text-destructive-foreground hover:bg-red-600/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        nav: "hover:bg-button-nav-hover hover:text-accent-foreground ",
        link: "text-primary underline-offset-4 hover:underline",
        groowCardFooter:
          "flex w-full flex-row justify-center gap-1 px-2 border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        groowCardOptions:
          "hover:bg-accent hover:text-accent-foreground justify-start gap-2 font-light ",
        planActive: "bg-[#1A164F] color-white text-white  ",
        planInactive:
          "bg-primary text-primary-foreground hover:bg-primary/90 dark:text-white",
        groowNav:
          "bg-white w-full dark:bg-gray-900 hover:bg-primary/90 min-h-[32px] rounded-lg border-gray-200 text-center !px-2 text-xs font-semibold hover:border-gray-200 hover:bg-blue-300 focus:bg-blue-200 dark:border-gray-800 dark:hover:bg-gray-700",
        groowMessage:
          "flex w-full flex-row justify-center gap-1 px-2 border border-input hover:bg-background bg-accent text-accent-foreground",
      },
      size: {
        default: "h-10 px-4 py-2",
        nav: "h-10 w-10 p-4 py-3",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        xl: "rounded-md px-4 w-full max-w-[286px] py-3 min-h-[52px]",
        icon: "h-10 w-10",
      },
      disabled: {
        true: "pointer-events-none opacity-30",
      },
      active: {
        true: "bg-blue-200 text-blue-800 dark:bg-blue-800 dark:text-white",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  accessKeyChar?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      active = false, // Se asume que active será un booleano por defecto falso
      asChild = false,
      loading = false,
      accessKeyChar,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const buttonRef = useRef<HTMLButtonElement>(null);

    // Helper para asignar el ref correctamente
    const setRef = (instance: HTMLButtonElement | null) => {
      if (typeof ref === "function") {
        ref(instance);
      } else if (ref && "current" in ref) {
        ref.current = instance;
      }
      (buttonRef as React.MutableRefObject<HTMLButtonElement | null>).current =
        instance;
    };

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (!event.code) return; // Salir si event.code no está definido
        const pressedKey = event.code.replace("Key", "").toLowerCase();
        const accessKey = accessKeyChar?.toLowerCase();

        if (event.altKey && pressedKey === accessKey) {
          event.preventDefault();
          buttonRef.current?.focus();
          buttonRef.current?.click();
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [accessKeyChar]);

    const renderButtonText = (text: React.ReactNode) => {
      if (React.isValidElement(text)) {
        return text; // Retorna el elemento tal cual
      }

      if (typeof text === "string" && accessKeyChar) {
        // Busca el índice de la primera letra que coincide
        const index = text.toLowerCase().indexOf(accessKeyChar.toLowerCase());
        if (index === -1) return text; // Si no se encuentra, retorna el texto original
        return (
          <>
            {text.slice(0, index)}
            <span className="underline">{text[index]}</span>
            {text.slice(index + 1)}
          </>
        );
      }

      return text; // Retorna el texto original si no se puede procesar
    };

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            active, // Pasamos `active` a buttonVariants
            className,
            disabled: props.disabled || loading, // Desactivar si está en loading
          })
        )}
        ref={setRef} // Usar la función setRef para manejar ambos tipos de ref
        onClick={props.onClick} // Asegúrate de que esto esté aquí
        {...props}
      >
        {loading ? <Spinner /> : renderButtonText(props.children)}
      </Comp>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
