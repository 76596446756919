import { gql } from "@apollo/client";

export interface ICreateCertificateInput {
  id?: string;
  name: string;
  subTitle: string;
  durationText?: string;
  startDate?: string;
  footerTextTitle?: string;
  learningOutcomes?: string[] | any[];
  allowViewCourse?: boolean;
  customImage?: string;
  totalCertificatedUsers?: number;
  certificateLabel?: string;
  courseUrl?: string;
}

export const CREATE_CERTIFICATE = gql`
  mutation CreateCertificate($createCertificateInput: CreateCertificateInput!) {
    createCertificate(createCertificateInput: $createCertificateInput) {
      name
      subTitle
      durationText
      startDate
      footerTextTitle
      learningOutcomes
      allowViewCourse
      customImage
      totalCertificatedUsers
      certificateLabel
      courseUrl
    }
  }
`;

export const UPDATE_CERTIFICATE = gql`
  mutation UpdateCertificate($updateCertificateInput: UpdateCertificateInput!) {
    updateCertificate(updateCertificateInput: $updateCertificateInput) {
      name
      subTitle
      durationText
      startDate
      footerTextTitle
      learningOutcomes
      allowViewCourse
      customImage
      totalCertificatedUsers
      certificateLabel
      courseUrl
    }
  }
`;

export const DELETE_CERTIFICATE = gql`
  mutation DeleteCertificate($deleteCertificateId: String!) {
    deleteCertificate(id: $deleteCertificateId) {
      name
    }
  }
`;

export default {
  CREATE_CERTIFICATE,
  UPDATE_CERTIFICATE,
  DELETE_CERTIFICATE,
};
