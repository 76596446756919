import { gql } from "@apollo/client";
import { IArea } from "types/Area.type";

export interface ICreateAreaRes {
  createArea: IArea;
}
export interface ICreateAreaInput {
  name: string;
  description: string;
  avatar: string;
  roles?: string[];
  leader?: string;
  users?: string[];
}

const CREATE_AREA = gql`
  mutation createArea($createAreaInput: CreateAreaInput!) {
    createArea(createAreaInput: $createAreaInput) {
      id
      name
      description
    }
  }
`;

export interface IUpdateAreaInput extends ICreateAreaInput {}

const UPDATE_AREA = gql`
  mutation updateArea($updateAreaInput: UpdateAreaInput!) {
    updateArea(updateAreaInput: $updateAreaInput) {
      id
      name
      description
    }
  }
`;

const DELETE_AREA_BY_ID = gql`
  mutation deleteArea($id: String!) {
    deleteArea(id: $id) {
      id
      name
      description
    }
  }
`;

export default { CREATE_AREA, UPDATE_AREA, DELETE_AREA_BY_ID };
