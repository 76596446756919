import { WIDTH_MD } from "@constants/global";
import { useWindowWidth } from "@react-hook/window-size";
import { APP_VERSION } from "components/Roadmap/ItemPathNode/constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import { useMobileSidebarStore } from "stores/sidebar/useMobileSidebarStore";

export default function FooterVersion() {
  const { isExpanded } = useDesktopSidebarStore();
  const { isOpenBurger } = useMobileSidebarStore();

  const wWidth = useWindowWidth();
  const isMd = wWidth >= WIDTH_MD;
  const [renderText, setRenderText] = useState<boolean>(false);

  useEffect(() => {
    if (isExpanded || isOpenBurger) {
      // SetRenderText to true if isExpanded or isOpenBurger with timeout
      setTimeout(() => {
        setRenderText(true);
      }, 200);
    } else {
      setRenderText(false);
    }
  }, [isExpanded, isOpenBurger, isMd]);

  return (
    <>
      <div className="mb-2 flex flex-col items-center justify-center border-gray-400 dark:border-gray-700">
        {renderText && <span>Groowcity ® {moment().format("YYYY")}</span>}
        <span className="text-xs text-primary dark:text-green-200">
          {APP_VERSION}
        </span>
      </div>
    </>
  );
}
