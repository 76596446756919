import { gql } from "@apollo/client";
import { IEndorsement, IUserEndorsementResult } from "types/Endorsement.type";

export interface IGetUserEndorsementResultsInput {
  userId: string;
}

export interface IGetUserEndorsementsResponse {
  userEndorsements: IUserEndorsementResult[];
}

const GET_USER_ENDORSEMENTS_RESULTS = gql`
  query userEndorsements(
    $getUserEndorsementResultsInput: GetUserEndorsementResultsInput!
  ) {
    userEndorsements(
      getUserEndorsementResultsInput: $getUserEndorsementResultsInput
    ) {
      times
      skill {
        name
        id
        average
        primaryOrSecondary
      }
      userEndorsements {
        comment
        endorserUser {
          id
          name
          lastname
          profileImage
        }
      }
    }
  }
`;

export interface IGetEndorsementInput {
  skillId?: string;
  userId?: string;
}

export interface IGetEndorsementResponse {
  endorsements: IEndorsement[];
}

const GET_ENDORSEMENTS = gql`
  query endorsements($getEndorsementInput: GetEndorsementInput!) {
    endorsements(getEndorsementInput: $getEndorsementInput) {
      id
      comment
      createdAt
      endorserUser {
        id
        name
        lastname
        profileImage
        email
        role {
          name
        }
      }
      skill {
        name
        id
      }
    }
  }
`;

export default { GET_USER_ENDORSEMENTS_RESULTS, GET_ENDORSEMENTS };
