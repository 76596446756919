import { gql } from "@apollo/client";
import { ETaskSubType } from "types/Task.type";

export interface IGetTaskDataInput {
  type: ETaskSubType;
  userId: string;
}

const GET_TASK_DATA = gql`
  query taskData($getTaskDataInput: GetTaskDataInput!) {
    taskData(getTaskDataInput: $getTaskDataInput) {
      id
      name
    }
  }
`;

export default { GET_TASK_DATA };
