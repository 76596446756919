import { IMenuList } from "components/Layout/ManagerProLayout/utils";
import { EPrivateRouteType } from "components/SideMenu/side-menu-routes";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { useVendor } from "context/vendor/vendor.provider";
import useTranslation from "next-translate/useTranslation";
import { PRIVATE_ROUTES } from "routes/routes";
import { ICONS_MODULES_PATHS } from "./iconModulesPath";

/**
 *
 * @returns IMenuList[]
 * @description This function returns the main menu list for the sidebar
 * @example
 * ```tsx
 * import MainMenuList from "components/Layout/Sidebar/components/body/content/utils/menuList";
 *
 * const Menu: React.FC = () => {
 *  const menuItems = MainMenuList();
 *
 *  return (
 *    <ul>
 *      {menuItems.map((item, index) => {
 *        if (item.type === EPrivateRouteType.DROPDOWN) {
 *          return <MenuDropdown key={index} item={item} />;
 *        } else {
 *          return <MenuItem key={index} item={item} />;
 *        }
 *      })}
 *    </ul>
 *  );
 *};
 *
 *export default Menu;
 *```
 *
 */
const MainMenuList = (): IMenuList[] => {
  const { t } = useTranslation("common");
  const { vendor } = useVendor();
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));

  let MAIN_MENU_LISTS: IMenuList[] = [
    {
      name: t("Aprendizaje"),
      urlIcon: ICONS_MODULES_PATHS.LEARNING.EVALUATIONS_MODULE,

      type: EPrivateRouteType.DROPDOWN,
      children: [
        {
          name: t("Evaluaciones"),
          urlIcon: ICONS_MODULES_PATHS.LEARNING.APPRECIATION,
          route: PRIVATE_ROUTES.appreciationList,
          type: EPrivateRouteType.ITEM,
        },
        {
          name: t("Mis evaluaciones"),
          urlIcon: ICONS_MODULES_PATHS.LEARNING.MY_EVALUATIONS,
          route: PRIVATE_ROUTES.myAppreciations,
          type: EPrivateRouteType.ITEM,
        },
        {
          name: t("Mis recursos"),
          urlIcon: ICONS_MODULES_PATHS.LEARNING.RESOURCES,
          route: PRIVATE_ROUTES.userMultimedias,
          type: EPrivateRouteType.ITEM,
        },

        !vendor?.enableJobyfine
          ? {
              name: t("Preselección"),
              urlIcon: ICONS_MODULES_PATHS.JOBYFINE.JOBYFINE_PRESELECTION,
              route: PRIVATE_ROUTES.publicAppreciation,
              type: EPrivateRouteType.ITEM,
            }
          : [],
        vendor?.enableCertificates
          ? {
              name: t("Mis certificados"),
              urlIcon: ICONS_MODULES_PATHS.CONFIG.CERTIFICATE,
              route: PRIVATE_ROUTES.userCertificates,
              type: EPrivateRouteType.ITEM,
            }
          : [],
      ].filter(Boolean) as IMenuList[],
    },
    {
      name: t("Radar empresarial"),
      urlIcon: ICONS_MODULES_PATHS.RADAR.RADAR_MODULE,
      route: PRIVATE_ROUTES.radar,
      type: EPrivateRouteType.ITEM,
    },
    {
      name: t("Equipos"),
      route: PRIVATE_ROUTES.teamsLeader,
      urlIcon: ICONS_MODULES_PATHS.RADAR.TEAMS,
      type: EPrivateRouteType.ITEM,
    },
  ];

  if (vendor?.enableExplore) {
    MAIN_MENU_LISTS = [
      {
        name: t("Inicio"),
        urlIcon: ICONS_MODULES_PATHS.EXPLORE.EXPLORE_MODULE,
        route: PRIVATE_ROUTES.explore,
        type: EPrivateRouteType.ITEM,
      },
      ...MAIN_MENU_LISTS,
    ];
  }

  if (vendor?.enableChallenges) {
    MAIN_MENU_LISTS = [
      ...MAIN_MENU_LISTS,
      {
        name: t("Challenges"),
        urlIcon: ICONS_MODULES_PATHS.CHALLENGES.CHALLENGE_GROUP,
        type: EPrivateRouteType.DROPDOWN,
        children: [
          {
            name: t("Mis desafios"),
            urlIcon: ICONS_MODULES_PATHS.CHALLENGES.USER_CHALLENGES,
            route: PRIVATE_ROUTES.questionsIndex,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Crear desafios"),
            urlIcon: ICONS_MODULES_PATHS.CHALLENGES.CREATE_CHALLENGES,
            route: PRIVATE_ROUTES.challenge,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Ranking"),
            urlIcon: ICONS_MODULES_PATHS.CHALLENGES.RANKING,
            route: PRIVATE_ROUTES.dailyQuestions,
            type: EPrivateRouteType.ITEM,
          },
        ],
      },
    ];
  }

  if (vendor?.enableActionPlans) {
    MAIN_MENU_LISTS = [
      ...MAIN_MENU_LISTS,
      {
        name: t("Planes de acción"),
        urlIcon: ICONS_MODULES_PATHS.ACTION_PLANS.ACTION_PLAN_GROUP,
        type: EPrivateRouteType.DROPDOWN,
        children: [
          {
            name: t("Mis planes de acción"),
            urlIcon: ICONS_MODULES_PATHS.ACTION_PLANS.MY_ACTION_PLAN,
            route: PRIVATE_ROUTES.userActionPlans,
            type: EPrivateRouteType.ITEM,
          },

          {
            name: t("Crear plan de acción"),
            urlIcon: ICONS_MODULES_PATHS.ACTION_PLANS.CREATE_ACTION_PLAN,
            route: PRIVATE_ROUTES.actionPlans,
            type: EPrivateRouteType.ITEM,
          },
        ],
      },
    ];
  }

  if (vendor?.enableForms) {
    MAIN_MENU_LISTS = [
      ...MAIN_MENU_LISTS,
      {
        name: t("Encuestas"),
        urlIcon: ICONS_MODULES_PATHS.SURVEYS.SURVEY_GROUP,
        type: EPrivateRouteType.DROPDOWN,
        children: [
          {
            name: t("Encuestas"),
            urlIcon: ICONS_MODULES_PATHS.SURVEYS.SURVEY,
            route: PRIVATE_ROUTES.forms,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Mis encuestas"),
            urlIcon: ICONS_MODULES_PATHS.SURVEYS.MY_SURVEY,
            route: PRIVATE_ROUTES.MyForms,
            type: EPrivateRouteType.ITEM,
          },
        ],
      },
    ];
  }

  if (vendor?.enableCoins) {
    // * add marketplace to menu
    MAIN_MENU_LISTS = [
      ...MAIN_MENU_LISTS,
      {
        name: t("Marketplace"),
        urlIcon: ICONS_MODULES_PATHS.MARKETPLACE.MARKETPLACE_GROUP,
        route: PRIVATE_ROUTES.coinMarketplace,
        type: EPrivateRouteType.ITEM,
      },
    ];
  }

  if (user?.coinsAdmin && vendor?.enableCoins) {
    const COINS_ADMIN_OPTIONS = [
      {
        name: "Coins",
        urlIcon: ICONS_MODULES_PATHS.MARKETPLACE.COINS_GROUP,
        type: EPrivateRouteType.DROPDOWN,
        children: [
          {
            name: t("Productos"),
            urlIcon: ICONS_MODULES_PATHS.MARKETPLACE.PRODUCTS,
            route: PRIVATE_ROUTES.coinsProduct,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Categorias de productos"),
            urlIcon: ICONS_MODULES_PATHS.MARKETPLACE.PRODUCTS_CATEGORY,
            route: PRIVATE_ROUTES.coinsProductCategory,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Concepto de monedas"),
            urlIcon: ICONS_MODULES_PATHS.MARKETPLACE.COINS_CONCEPT,
            route: PRIVATE_ROUTES.coinsCategory,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Control de Canjes"),
            urlIcon: ICONS_MODULES_PATHS.MARKETPLACE.EXCHANGE_CONTROL,
            route: PRIVATE_ROUTES.coinsController,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Actividades"),
            urlIcon: ICONS_MODULES_PATHS.MARKETPLACE.ACTIVITIES,
            route: PRIVATE_ROUTES.activities,
            type: EPrivateRouteType.ITEM,
          },
        ],
      },
    ];

    MAIN_MENU_LISTS = [...MAIN_MENU_LISTS, ...COINS_ADMIN_OPTIONS];
  }

  if (vendor?.enableCareerPlan) {
    MAIN_MENU_LISTS = [
      ...MAIN_MENU_LISTS,
      {
        name: t("Ruta Profesional"),
        urlIcon: ICONS_MODULES_PATHS.CAREER_PLAN.CAREER_GROUP,
        type: EPrivateRouteType.DROPDOWN,
        children: [
          {
            name: t("Mi plan de desarrollo"),
            urlIcon: ICONS_MODULES_PATHS.CAREER_PLAN.MY_DEVELOPMENT_PLAN,
            route: PRIVATE_ROUTES.myCareer,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Fases del desarrollo"),
            urlIcon: ICONS_MODULES_PATHS.CAREER_PLAN.DEVELOPMENT_PHASES,
            route: PRIVATE_ROUTES.itemPathMantainers,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Planes de desarrollo"),
            urlIcon: ICONS_MODULES_PATHS.CAREER_PLAN.DEVELOPMENT_PLANS,
            route: PRIVATE_ROUTES.coursesMaintainer,
            type: EPrivateRouteType.ITEM,
          },
        ],
      },
    ];
  }

  if (vendor?.enableJobyfine) {
    const JOBYFINE_ADMIN_OPTIONS = [
      {
        name: "Jobyfine",
        urlIcon: ICONS_MODULES_PATHS.JOBYFINE.JOBYFINE_GROUP,
        type: EPrivateRouteType.DROPDOWN,
        children: [
          {
            name: t("Solicitudes"),
            urlIcon: ICONS_MODULES_PATHS.JOBYFINE.REQUESTS,
            route: PRIVATE_ROUTES.jobyfineRequest,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Aplicantes"),
            urlIcon: ICONS_MODULES_PATHS.JOBYFINE.JOB_APPLICANTS,
            route: PRIVATE_ROUTES.jobyfineApplicants,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Ofertas"),
            urlIcon: ICONS_MODULES_PATHS.JOBYFINE.OFFERS,
            route: PRIVATE_ROUTES.jobyfineOffers,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Proceso"),
            urlIcon: ICONS_MODULES_PATHS.JOBYFINE.PROCESS,
            route: PRIVATE_ROUTES.jobyfineProcess,
            type: EPrivateRouteType.ITEM,
          },

          {
            name: t("Preselección"),
            urlIcon: ICONS_MODULES_PATHS.JOBYFINE.JOBYFINE_PRESELECTION,
            route: PRIVATE_ROUTES.publicAppreciation,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Finder"),
            urlIcon: ICONS_MODULES_PATHS.JOBYFINE.FINDER,
            route: PRIVATE_ROUTES.skillsRequirements,
            type: EPrivateRouteType.ITEM,
          },
        ],
      },
    ];

    MAIN_MENU_LISTS = [...MAIN_MENU_LISTS, ...JOBYFINE_ADMIN_OPTIONS];
  }

  const MANTAINERS_AND_CONFIG = [
    {
      name: t("Configuration"),
      urlIcon: ICONS_MODULES_PATHS.CONFIG.CONFIG_GROUP,
      type: EPrivateRouteType.DROPDOWN,
      children: [
        {
          name: t("Cargos"),
          urlIcon: ICONS_MODULES_PATHS.CONFIG.POSITION,
          route: PRIVATE_ROUTES.roleMaintainer,
          type: EPrivateRouteType.ITEM,
        },
        {
          name: t("Divisiones"),
          urlIcon: ICONS_MODULES_PATHS.CONFIG.DIVISION,
          route: PRIVATE_ROUTES.divisionMaintainer,
          type: EPrivateRouteType.ITEM,
        },
        {
          name: t("Areas"),
          urlIcon: ICONS_MODULES_PATHS.CONFIG.AREA,
          route: PRIVATE_ROUTES.areaMaintainer,
          type: EPrivateRouteType.ITEM,
        },
        {
          name: t("Habilidades"),
          urlIcon: ICONS_MODULES_PATHS.CONFIG.SKILLS,
          route: PRIVATE_ROUTES.dimensionMaintainer,
          type: EPrivateRouteType.ITEM,
        },

        {
          name: t("Certificaciones"),
          urlIcon: ICONS_MODULES_PATHS.CONFIG.CERTIFICATIONS,
          route: PRIVATE_ROUTES.dashboard,
          type: EPrivateRouteType.ITEM,
        },
        {
          name: t("Usuarios"),
          urlIcon: ICONS_MODULES_PATHS.CONFIG.USERS,
          route: PRIVATE_ROUTES.userMaintainer,
          type: EPrivateRouteType.ITEM,
        },
        {
          name: t("Preguntas"),
          urlIcon: ICONS_MODULES_PATHS.CONFIG.QUESTIONS,
          route: PRIVATE_ROUTES.questionMantainers,
          type: EPrivateRouteType.ITEM,
        },
        {
          name: t("Recursos"),
          urlIcon: ICONS_MODULES_PATHS.CONFIG.RESOURCES,
          route: PRIVATE_ROUTES.multimediaMaintainer,
          type: EPrivateRouteType.ITEM,
        },
        {
          name: t("Ajustes"),
          urlIcon: ICONS_MODULES_PATHS.CONFIG.APP_SETTING,
          route: PRIVATE_ROUTES.configMantainer,
          type: EPrivateRouteType.ITEM,
        },
      ],
    },
  ];

  if (vendor?.enableCorporateValue) {
    MANTAINERS_AND_CONFIG[0].children.splice(3, 0, {
      name: t("Valores corporativos"),
      urlIcon: ICONS_MODULES_PATHS.CONFIG.CORPORATE_VALUES,
      route: PRIVATE_ROUTES.corporateValuesMaintainer,
      type: EPrivateRouteType.ITEM,
    });
  }

  if (vendor?.enableCertificates) {
    MANTAINERS_AND_CONFIG[0].children.splice(
      vendor.enableCorporateValue ? 5 : 4,
      0,
      {
        name: t("Certificados"),
        urlIcon: ICONS_MODULES_PATHS.CONFIG.CERTIFICATE,
        route: PRIVATE_ROUTES.certificates,
        type: EPrivateRouteType.ITEM,
      }
    );
  }

  // * vacations mantainers
  if (vendor?.enableVacations) {
    // * add at -1
    MANTAINERS_AND_CONFIG[0].children.splice(
      MANTAINERS_AND_CONFIG[0].children.length - 1,
      0,
      {
        name: t("Vacaciones"),
        urlIcon: ICONS_MODULES_PATHS.CONFIG.VACATIONS,
        route: PRIVATE_ROUTES.vacationManagement,
        type: EPrivateRouteType.ITEM,
      }
    );
  }

  if (vendor?.enableAnonymousBox && user?.anonymousBoxAdmin) {
    // * add at -1
    MANTAINERS_AND_CONFIG[0].children.splice(
      MANTAINERS_AND_CONFIG[0].children.length - 1,
      0,
      {
        name: t("AnonymousBox"),
        urlIcon: ICONS_MODULES_PATHS.CONFIG.ANONYMOUS_MAIL,
        route: PRIVATE_ROUTES.anonybox,
        type: EPrivateRouteType.ITEM,
      }
    );
  }

  // * add config to menu as last item
  MAIN_MENU_LISTS = [...MAIN_MENU_LISTS, ...MANTAINERS_AND_CONFIG];

  if (user?.isi) {
    const ISI_OPTIONS = [
      {
        name: t("Management"),
        urlIcon: ICONS_MODULES_PATHS.SUPER_ADMIN.SUPER_ADMIN_GROUP,
        iconSize: 50,
        route: PRIVATE_ROUTES.activities,
        type: EPrivateRouteType.DROPDOWN,
        children: [
          {
            name: t("Feedback"),
            urlIcon: ICONS_MODULES_PATHS.SUPER_ADMIN.FEEDBACK,
            route: PRIVATE_ROUTES.isiFeedback,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Public Challenge"),
            urlIcon: ICONS_MODULES_PATHS.SUPER_ADMIN.PUBLIC_CHALLENGE,
            route: PRIVATE_ROUTES.publicChallenge,
            type: EPrivateRouteType.ITEM,
          },
          {
            name: t("Skills"),
            urlIcon: ICONS_MODULES_PATHS.CONFIG.SKILLS,
            route: PRIVATE_ROUTES.isiSkills,
            type: EPrivateRouteType.ITEM,
          },
        ],
      },
    ];

    MAIN_MENU_LISTS = [...MAIN_MENU_LISTS, ...ISI_OPTIONS];
  }

  return MAIN_MENU_LISTS;
};

export default MainMenuList;
